import {Store} from "@ngxs/store";
import {Observable, Subject} from "rxjs";
import {UseCase} from "../core/abstractions";
import {UseCasesActions} from "../core/state/use-cases";
import {Localizable} from "../core/utils";
import {UseCaseActivityDisplayMode} from "../core/utils";
import {UseCaseActionName, UseCaseStateName} from "../core/utils";
import {MessageActivityLevel} from "../core/utils/message-activity-level.enum";

export abstract class UseCaseAppBase<TParams> implements UseCase<TParams> {

  protected destroy$: Subject<boolean> = new Subject<boolean>();

  protected constructor(private store: Store) {
  }

  public execute(action: UseCaseActionName, params?: TParams): void {
    switch (action) {
      case UseCaseActionName.userEdit: {
        this.onExecuteUserEdit();
      }
        break;
      case UseCaseActionName.userSave: {
        this.onExecuteUserSave(params);
      }
        break;
      case UseCaseActionName.userDiscard: {
        this.onExecuteUserDiscard();
      }
        break;
      case UseCaseActionName.run: {
        this.onExecuteRun(params);
      }
        break;
    }
  }

  protected onExecuteUserEdit() {
  }

  protected onExecuteUserSave(_params?: TParams) {
  }

  protected onExecuteUserDiscard() {
  }

  // TODO: check if it is better to convert the onExecute to abstract methods
  protected onExecuteRun(_params?: TParams) {
  }

  public abstract getName(): string ;

  public abstract init(): void ;

  public dispose(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.onDispose();
  }

  protected onDispose(): void {
  }

  protected dispatchInitWithMessage(localizationKey: string): void {
    this.dispatch(
      new UseCasesActions.Init(this.getName(), {localizationKey})
    );
  }

  protected dispatchSetStateWithToast(stateName: UseCaseStateName,
                                      localizationKey: string,
                                      quantity?: string,
                                      params?: any,
                                      level?: MessageActivityLevel): void {
    this.dispatch(
      new UseCasesActions.SetState(this.getName(), stateName,
        {localizationKey, params, quantity}, undefined, undefined, undefined, level));

  }

  protected dispatchSetStateWithNone(stateName: UseCaseStateName,
                                     localizationKey: string,
                                     quantity?: string,
                                     params?: any): void {
    this.dispatch(
      new UseCasesActions.SetState(this.getName(), stateName,
        {
          localizationKey,
          params,
          quantity
        },
        undefined,
        undefined,
        UseCaseActivityDisplayMode.none));
  }

  protected dispatchSetFailureWithAlert(message: Localizable,
                                        header?: Localizable,
                                        subHeader?: Localizable): void {
    this.dispatch(
      new UseCasesActions.SetState(this.getName(),
        UseCaseStateName.failure,
        message,
        header,
        subHeader,
        UseCaseActivityDisplayMode.alert,
        MessageActivityLevel.error));
  }

  protected dispatchSetStateWithNotification(stateName: UseCaseStateName,
                                             message: Localizable,
                                             header?: Localizable,
                                             subHeader?: Localizable,
                                             displayMode?: UseCaseActivityDisplayMode,
                                             activityLevel?: MessageActivityLevel): void {
    this.dispatch(
      new UseCasesActions.SetState(
        this.getName(),
        stateName,
        message,
        header,
        subHeader,
        displayMode,
        activityLevel));
  }

  protected dispatch(actionOrActions: any): Observable<any> {
    return this.store.dispatch(actionOrActions);
  }

  protected selectSnapshot(selector: any): any {
    return this.store.selectSnapshot(selector);
  }
}
