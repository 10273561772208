import {MenuItemChild} from "../../../presentation/ui-kit/layout/models";
import {AppIconNames} from "../../../app-icon-names";

export class SetShowMobileMenu {
  static readonly type = '[Main Menu] Set Show mobile menu';

  constructor(public showMobileMenu: boolean) {
  }
}

export class SetToggleMenu {
  static readonly type = '[Main Menu] Set toggle menu';

  constructor(public menu: MenuItemChild) {
  }
}


export class SetMenuExpand {
  static readonly type = '[Main Menu] Set expand menu';

  constructor(public items: Array<any>) {
  }
}

export class UpdateActiveMenu {
  static readonly type = '[Main Menu] Update active menu';

  constructor() {
  }
}

export class SetUokMenuItem {
  static readonly type = '[Main Menu] Set menu uok item';

  constructor(public item: LastMenuItem) {
  }
}

export class RemoveUokMenuItem {
  static readonly type = '[Main Menu] Clear menu uok item';
}

export class SetDraftMenuItem {
  static readonly type = '[Main Menu] Set menu draft item';

  constructor(public item: LastMenuItem) {
  }
}

export class RemoveDraftMenuItem {
  static readonly type = '[Main Menu] Clear menu draft item';
}

export class SetLinkEditorMenuItem {
  static readonly type = '[Main Menu] Set menu Edit Links item';

  constructor(public item: LastMenuItem) {
  }
}

export class RemoveLinkEditorMenuItem {
  static readonly type = '[Main Menu] Clear menu Edit Links item';
}

export interface LastMenuItem {
  label: string;
  path: string;
  icon?: AppIconNames;
}
