import {AccountChangePasswordUseCase} from '../../core/use-cases/account';
import {UseCaseActionName} from '../../core/utils';

export class AccountChangePasswordUseCaseMock implements AccountChangePasswordUseCase {
  dispose(): void {
  }

  execute(action: UseCaseActionName, params?: {
    oldPassword: string;
    password: string;
    confirmPassword: string
  }): void {
  }

  getName(): string {
    return '';
  }

  init(): void {
  }
}
