import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import { IDtoDraft, IdtoUokDraftPublish } from '../dtos/uok';

@Injectable({
  providedIn: 'root'
})
export class DraftApiService {
  constructor(private httpClient: HttpClient) {
  }

  public getDraft(draftId: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/draft/load/${draftId}`);
  }

  public saveDraft(dto: IDtoDraft, lang: string) {
    return this.httpClient.post(`${environment.webApiV1}${lang}/uok/draft/save`, JSON.stringify(dto));
  }

  public createDraftFromUok(uokId: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/draft/create-from-uok/${uokId}`);
  }

  // public deleteDraft(draftId: number, lang: string) {
  //   return this.httpClient.get(`${environment.webApiV1}/${lang}/uok/draft/delete/${draftId}`);
  // }

  public getDraftsByState(stateAsNumber: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/draft/list/${stateAsNumber}`);
  }

  public getTags(lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/uok/draft/gettags/`);
  }


  public submit(draftId: number) {
    return this.httpClient.get(`${environment.webApiV1}uok/version/submit/${draftId}`);
  }

  public publish(model: IdtoUokDraftPublish) {
    return this.httpClient.post(`${environment.webApiV1}uok/version/publish/`, JSON.stringify(model));
  }

  public reject(draftId: number, message: string) {
    return this.httpClient.get(`${environment.webApiV1}uok/version/reject/${draftId}?message=${message}`);
  }

  public delete(draftId: number) {
    return this.httpClient.get(`${environment.webApiV1}uok/version/delete/${draftId}`);
  }
}
