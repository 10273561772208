import {Pipe, PipeTransform} from '@angular/core';
import {RepositoryResponse} from '../abstractions/repositories/repository-response.interface';
import {AccountStateModel} from '../state/account/account-state-model.interface';

@Pipe({name: 'displayMessages'})
export class DisplayMessagesPipe implements PipeTransform {
  transform(response: RepositoryResponse<AccountStateModel>|null|undefined): string {
    if(response === null || response === undefined){
      return '---';
    }

    if(response.info === null || response.info === undefined){
      return '-+-';
    }

    let msg = '';
    response.info.messages.forEach(m => msg += m);

    return msg
  }
}
