import { Store } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import {
  UserGetAccessKeysUseCase,
  UserGetUseCase,
  UserLoadCashInfoUseCase,
  UserSetCashToProcessingUseCase,
  UserUpdateUseCase,
} from '../../core/use-cases/user';
import { ServiceLocator } from '../../core/utils';
import { UserGetAccessKeysUseCaseApp, UserGetAccessKeysUseCaseMock } from './access-key';
import {
  UserLoadCashInfoUseCaseApp,
  UserLoadCashInfoUseCaseMock,
  UserSetCashToProcessingUseCaseApp,
  UserSetCashToProcessingUseCaseMock,
} from './cash';
import { UserGetUseCaseApp } from './user-get-use-case-app.class';
import { UserGetUseCaseMock } from './user-get-use-case-mock.class';
import { UserUpdateUseCaseApp } from './user-update-use-case-app.class';
import { UserUpdateUseCaseMock } from './user-update-use-case-mock.class';
import { TranslateConfig } from '../../core/services/translate-config.class';

export class UserUseCaseFactory {
  private static readonly App = 'app';

  public static GetUserInfo(): UserGetUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      const translateConfig = ServiceLocator.injector.get(TranslateConfig);
      return new UserGetUseCaseApp(store, translateConfig);
    }
    return new UserGetUseCaseMock();
  }

  public static GetAccessKeys(): UserGetAccessKeysUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new UserGetAccessKeysUseCaseApp(store);
    }
    return new UserGetAccessKeysUseCaseMock();
  }

  public static UserUpdate(): UserUpdateUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new UserUpdateUseCaseApp(store);
    }
    return new UserUpdateUseCaseMock();
  }

  public static UserCashInfo(): UserLoadCashInfoUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new UserLoadCashInfoUseCaseApp(store);
    }
    return new UserLoadCashInfoUseCaseMock();
  }

  public static UserCashSetToProcessing(): UserSetCashToProcessingUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new UserSetCashToProcessingUseCaseApp(store);
    }
    return new UserSetCashToProcessingUseCaseMock();
  }
}
