import {UserSetCashToProcessingUseCase} from '../../../core/use-cases/user';
import {UseCaseActionName} from '../../../core/utils';

export class UserSetCashToProcessingUseCaseMock implements UserSetCashToProcessingUseCase {
  public static readonly Name: string = 'UserSetCashToProcessing';

  dispose(): void {
  }

  getName(): string {
    return "";
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }

}
