import {IDtoUokDraftInfo} from "../../api/dtos/uok";
import {Mapper} from "../../core/abstractions/repositories";
import {DraftInfoModel} from "../../core/state/draft";
import {UtilsDto} from "../../core/utils";

export class DraftInfoMapper implements Mapper<IDtoUokDraftInfo, DraftInfoModel> {
  mapFrom(dto: IDtoUokDraftInfo): DraftInfoModel {
    return {
      cultureName: dto.CultureName,
      itemIcon: UtilsDto.GetRouteLinkIcon(dto.SystemName),
      dbId: dto.DbId,
      operatorId: dto.OperatorId,
      state: dto.State,
      systemName: dto.SystemName,
      uokDescription: dto.UokDescription,
      uokId: dto.UokId,
      uokVersion: dto.UokVersion,
      userEmail: dto.UserEmail,
      userNick: dto.UserNick,
      userRole: dto.UserRole
    };
  }

  mapTo(param: DraftInfoModel): IDtoUokDraftInfo {
    throw new Error("mapTo(param: DraftInfo): IDtoUokDraftInfo > Not Implemented")
  }

}
