import {IDtoUokLink} from "../../api/dtos/uok-link/idto-uok-link.interface";
import {UokLinkRepository} from "../../core/repositories/uok-link-repository.class";

export class UokLinkRepositoryMock extends UokLinkRepository{
  loadKnowledgeTree(id: number, lang: string): void {
  }

  loadUokLinks(id: number, lang: string): void {
  }

  loadUokLinksDefinition(dtoUokName: string, lang: string): void {
  }

  loadUokLinksReferred(id: number, lang: string): void {
  }

  saveUokLinks(links: IDtoUokLink[], lang: string): void {
  }
}
