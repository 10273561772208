import {UserStripeInfoModel} from "../models/user-stripe-info-model.interface";

export namespace UserStripeInfoActions {
  export class Succeded {
    static readonly type = '[User Stripe Info] Get Model Succeded';

    constructor(public model: UserStripeInfoModel) {
    }
  }
}
