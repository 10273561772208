import {UokDetailLockInfoDto} from "../../api/dtos/uok-detail/uok-detail-lock-info-dto.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {UokDetailLockInfoModel} from "../../core/state/detail/models/uok-detail-lock-info-model.interface";

export class UokDetailLockInfoMapper extends Mapper<UokDetailLockInfoDto, UokDetailLockInfoModel> {
  mapFrom(param: UokDetailLockInfoDto): UokDetailLockInfoModel {
    return {
      hasUserActiveLocks: param.HasUserActiveLocks,
      minutesBeforeLockExpires: param.MinutesBeforeLockExpires,
      uokId: param.UokId
    };
  }

  mapTo(param: UokDetailLockInfoModel): UokDetailLockInfoDto {
    return {
      HasUserActiveLocks: param.hasUserActiveLocks,
      MinutesBeforeLockExpires: param.minutesBeforeLockExpires,
      UokId: param.uokId
    };
  }

}
