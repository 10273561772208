export enum LinkStates {
    /// <summary>
    /// The link is created by someone
    /// </summary>
    Created,
    /// <summary>
    /// The link is waiting for approval
    /// </summary>
    Submitted,
    /// <summary>
    /// The publisher approved the link
    /// </summary>
    Approved,
    /// <summary>
    /// The publisher rejected the link
    /// </summary>
    Rejected,
    /// <summary>
    /// The link is deleted by the operator that creates it
    /// </summary>
    Dismissed,
}

export enum LinkActions {
    None,
    Create,
    Update,
    Submit,
    Approve,
    Reject,
    Dismiss,
}
