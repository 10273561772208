import { StripeElements } from "@stripe/stripe-js";
import { UseCaseActionName } from "src/app/core/utils";
import {StripePaymentIntentConfirmUseCase} from "../../core/use-cases/stripe";

export class StripePaymentIntentConfirmUseCaseMock implements StripePaymentIntentConfirmUseCase {
    init(): void {

    }
    execute(action: UseCaseActionName, params?: { stripeElements: StripeElements; returnUrl: string; } | undefined): void {

    }
    getName(): string {
        return "StripeConfirmPaymentMock"
    }
    dispose(): void {

    }

}
