export class UtilsVimeoEmbed {
  public static getIFrame(videoUrl: string) {
    if (!videoUrl) {
      return '';
    }

    const playerUrl = videoUrl.replace('https://vimeo.com/', 'https://player.vimeo.com/video/');
    // playerUrl += `?title=0&byline=0`;
    return `<iframe
                    src="${playerUrl}"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen>
            </iframe>`;
  }
}
