import {BehaviorSubject, Observable, Subject} from 'rxjs';

export abstract class BaseRepository {

  protected isUpdatingBehaviorSubject = new BehaviorSubject<boolean>(false);
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  //#region isUpdating
  public isUpdating$(): Observable<boolean> {
    return this.isUpdatingBehaviorSubject.asObservable();
  }

  public isUpdating(): boolean {
    return this.isUpdatingBehaviorSubject.getValue();
  }

  protected setIsUpdating(isUpdating: boolean): void {
    this.isUpdatingBehaviorSubject.next(isUpdating);
  }

  //#endregion

  public dispose(){
    this.destroy$.next(true);
    this.destroy$.complete();
  };
}
