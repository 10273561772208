import {takeUntil} from "rxjs/operators";
import {IUserLoginResponseDto} from '../../api/dtos/security';
import {AuthApiService} from '../../api/services';
import {RepositoryResponse} from '../../core/abstractions/repositories';
import {AccountRepository} from '../../core/repositories';
import {HttpUtils} from "../../core/utils";

export class AuthRepositoryApi extends AccountRepository {
  constructor(private authApi: AuthApiService) {
    super();
  }

  public login(username: string, password: string, captchaToken: string): void {
    this.setIsUpdating(true);
    //let tmp: RepositoryResponse<AuthStateModel>;
    this.authApi
      .login({username, password, captchaToken})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: data => {
            const body = data as IUserLoginResponseDto;
            const tmp: RepositoryResponse<IUserLoginResponseDto> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'UserLoginResponseDto is null' : 'UserLoginResponseDto received',
                ]
              }
            }
            this.setIsUpdating(false);
            this.setLoginAuthStateResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IUserLoginResponseDto> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setLoginAuthStateResponse(tmp);
          }
        }
      );
  }

  public logout(): void {
    this.authApi.logout().subscribe();
  }

  public changePassword(payload: { oldPassword: string; newPassword: string; confirmPassword: string }): void {
    this.setIsUpdating(true)
    this.authApi.changePassword({
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
      confirmPassword: payload.confirmPassword
    }).subscribe({
      next: body => {
        this.setIsUpdating(false);
        this.setChangePasswordStateResponse({
          info: {
            level: 0,
            messages: [
              'Password changed successfully'
            ]
          }
        })
      },
      error: err => {
        this.setIsUpdating(false);
        this.setChangePasswordStateResponse({
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        })
      }
    });
  }
}
