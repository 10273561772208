import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canAccessAppGuard, canAccessLoginGuard } from './guards/auth-guards';
import { AppRoutes } from './app-routes';

const routes: Routes = [
  {
    path: 'signin',
    title: 'Sign In',
    loadChildren: () => import('./presentation/pages/signin/signin.module')
      .then(m => m.SigninModule),
    canActivate: [canAccessLoginGuard()],
  },
  {
    path: 'forgot-password',
    title: 'Forgot Password',
    loadChildren: () => import('./presentation/pages/reset-password/reset-password.module')
      .then(m => m.ResetPasswordModule),
    canActivate: [canAccessLoginGuard()],
  },
  {
    path: '',
    redirectTo: AppRoutes.dashboard,
    pathMatch: 'full',
  },
  {
    path: 'main',
    loadChildren: () => import('./presentation/pages/main/main.module')
      .then(m => m.MainModule),
    canActivate: [canAccessAppGuard()],
  },
  {
    path: 'register/:friendCode',
    redirectTo: 'signup/:friendCode',
  },
  {
    path: 'register',
    redirectTo: 'signup',
  },
  {
    path: 'knowledge-my',
    redirectTo: 'main/my-drafts',
  },
  {
    path: 'signup',
    title: 'Sign Up',
    loadChildren: () => import('./presentation/pages/signup/signup.module')
      .then(m => m.SignupModule),
    canActivate: [canAccessLoginGuard()],
  },
  { path: 'uok-detail/:id', redirectTo: 'main/uok-detail/:id', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
