import { Injectable, SecurityContext } from '@angular/core';
import { FilesApiService } from '../api/services';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FilesLoaderService {
  constructor(private filesApi: FilesApiService, private sanitizer: DomSanitizer) {
  }

  loadFile(imageName: string): Observable<{ progress: number, image: string | null, completed: boolean }> {
    return new Observable(observer => {
      observer.next({ progress: 0, image: null, completed: false });

      this.filesApi.getFileByName(imageName).subscribe({
        next: event => {
          if (event.type === HttpEventType.DownloadProgress) {
            const progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            observer.next({ progress, image: null, completed: false });
          } else if (event instanceof HttpResponse) {
            const blob = event.body as Blob;
            const objectURL = URL.createObjectURL(blob);
            const safeUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            observer.next({
              progress: 100,
              image: this.sanitizer.sanitize(SecurityContext.URL, safeUrl),
              completed: true,
            });
            observer.complete();
          }
        },
        error: err => {
          observer.error(err);
        },
      });
    });
  }

  downloadFile(fileName: string, downloadedFileName: string | undefined): Observable<{
    progress: number,
    completed: boolean
  }> {
    let name = fileName;
    if (downloadedFileName) {
      // remove special characters by searching non-alphanumeric ones
      name = downloadedFileName.replace(/[^a-zA-Z0-9]/g, '');
    }

    return new Observable(observer => {
      observer.next({ progress: 0, completed: false });

      this.filesApi.getFileByName(fileName).subscribe({
        next: event => {
          if (event.type === HttpEventType.DownloadProgress) {
            const progress = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            observer.next({ progress, completed: false });
          } else if (event instanceof HttpResponse) {
            const blob = event.body as Blob;

            const doc = document.createElement('a');
            doc.download = name;
            doc.href = window.URL.createObjectURL(blob);
            doc.click();
            observer.next({ progress: 100, completed: true });
            observer.complete();
          }
        },
        error: err => {
          observer.error(err);
        },
      });
    });
  }
}
