import {SearchRepository} from "../../core/repositories/search-repository";

export class SearchRepositoryMock extends SearchRepository {
  getByTags(lang: string, queryText: string, withinDto: string[], maxItems: number): void {
  }

  getBookmarksByStatus(status: string): void {
  }

  getByName(lang: string, queryText: string, withinDto: string[], maxItems: number): void {
  }

  getByNameStartsWith(lang: string, queryText: string, withinDto: string[], maxItems: number): void {
  }

  getByTagIds(lang: string, includeIds: number[], maxItems: number): void {
  }

  getContributed(lang: string): void {
  }

  getContributedByUokAuthor(lang: string, uokAuthorId: number): void {
  }

  getDisabled(lang: string): void {
  }

  getLatest(lang: string): void {
  }

  getOperatorsByText(queryText: string): void {
  }

  getPublished(lang: string): void {
  }

  getSignaled(lang: string): void {
  }
}
