import {Store} from '@ngxs/store';
import {environment} from '../../../environments/environment';
import {TranslateConfig} from '../../core/services/translate-config.class';
import {AccountChangePasswordUseCase, AccountSignInUseCase, AccountSignOutUseCase} from '../../core/use-cases/account';
import {ServiceLocator} from '../../core/utils';
import {AccountChangePasswordUseCaseApp} from './account-change-password-use-case-app.class';
import {AccountChangePasswordUseCaseMock} from './account-change-password-use-case-mock.class';
import {AccountSignInUseCaseApp} from './account-sign-in-use-case-app.class';
import {AccountSignInUseCaseMock} from './account-sign-in-use-case-mock.class';
import {AccountSignOutUseCaseApp} from './account-sign-out-use-case-app.class';
import {AccountSignOutUseCaseMock} from './account-sign-out-use-case-mock.class';
import {Router} from "@angular/router";
import {DraftStateIndexeddb} from '../../core/state/draft-state/draft-state-indexeddb';
import {MatomoTracker} from "ngx-matomo-client";

export class AccountUseCaseFactory {
  private static readonly App = 'app';

  public static GetAccountSignIn(): AccountSignInUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      const translateConfig = ServiceLocator.injector.get(TranslateConfig);
      const matomoTracker = ServiceLocator.injector.get(MatomoTracker);
      return new AccountSignInUseCaseApp(store, translateConfig, matomoTracker);
    }
    return new AccountSignInUseCaseMock();
  }

  public static GetAccountSignOut(): AccountSignOutUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      const router = ServiceLocator.injector.get(Router);
      const draftState = ServiceLocator.injector.get(DraftStateIndexeddb);
      const matomoTracker = ServiceLocator.injector.get(MatomoTracker);
      return new AccountSignOutUseCaseApp(store, router, draftState, matomoTracker);
    }
    return new AccountSignOutUseCaseMock();
  }

  public static ChangePassword(): AccountChangePasswordUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new AccountChangePasswordUseCaseApp(store);
    }
    return new AccountChangePasswordUseCaseMock();
  }
}
