import {DraftRepository} from "../../core/repositories";
import {IdtoUokDraftPublish} from "../../api/dtos/uok";
import {IDtoDraft} from '../../api/dtos/uok/dto-draft.interface';

export class DraftRepositoryMock extends DraftRepository {
  getDraftsInfoByState(lang: string, stateAsNumber: number): void {
  }

  getDraft(lang: string, draftId: number): void {
  }

  saveDraft(lang: string, dto: IDtoDraft): void {
  }

  getTags(lang: string): void {
  }

  delete(draftId: number): void {
  }

  publish(model: IdtoUokDraftPublish): void {
  }

  reject(draftId: number, message: string): void {
  }

  submit(draftId: number): void {
  }

  createDraftFromUok(lang: string, uokId: number): void {
  }

}
