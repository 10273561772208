import {UseCaseActionName} from '../../core/utils';
import { StripeResubscribeUseCase } from '../../core/use-cases/stripe';

export class StripeResubscribeUseCaseMock implements StripeResubscribeUseCase {
  dispose(): void {
  }

  getName(): string {
    return "";
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }
}
