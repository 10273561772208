import { environment } from '../../environments/environment';
import { StripeService } from 'ngx-stripe';
import { StripeRepositoryApi, StripeRepositoryMock } from './stripe-repository';
import { StripeApiService } from '../api/services/stripe-api.service';
import {
  AccessKeyRepository,
  AccountRepository, DraftRepository,
  ProductRepository, SearchRepository, StripeRepository,
  SurveyRepository, UokDetailRepository, UokLinkRepository,
  UserRepository,
} from '../core/repositories';
import {
  AccessKeyApiService,
  AuthApiService, DraftApiService,
  ProductApiService, SearchApiService,
  SurveyApiService, UokDetailApiService, UokLinkApiService,
  UserApiService,
} from '../api/services';
import { ServiceLocator } from '../core/utils';
import { AuthRepositoryApi, AuthRepositoryMock } from './auth-repository';
import { UserRepositoryApi, UserRepositoryMock } from './user-repository';
import { AccessKeyRepositoryApi, AccessKeyRepositoryMock } from './access-key-repository';
import { ProductRepositoryApi, ProductRepositoryMock } from './product-repository';
import { SurveyRepositoryApi, SurveyRepositoryMock } from './survey-repository';
import { SearchRepositoryApi, SearchRepositoryMock } from './search-repository';
import { UokDetailRepositoryApi, UokDetailRepositoryMock } from './uok-detail-repository';
import { UokLinkRepositoryApi, UokLinkRepositoryMock } from './uok-link-repository';
import { DraftRepositoryApi, DraftRepositoryMock } from './draft-repository';

export class RepositoryFactory {
  //private static readonly Mock = 'mock';
  private static readonly Api = 'api';

  public static GetSecurityRepository(): AccountRepository {
    if (environment.services.authRepository === this.Api) {
      const api = ServiceLocator.injector.get(AuthApiService);
      return new AuthRepositoryApi(api);
    }
    return new AuthRepositoryMock();
  }

  public static GetUserRepository(): UserRepository {
    if (environment.services.userRepository === this.Api) {
      const api = ServiceLocator.injector.get(UserApiService);
      return new UserRepositoryApi(api);
    }
    return new UserRepositoryMock();
  }

  public static GetAccessKeyRepository(): AccessKeyRepository {
    if (environment.services.accessKeyRepository === this.Api) {
      const api = ServiceLocator.injector.get(AccessKeyApiService);
      return new AccessKeyRepositoryApi(api);
    }
    return new AccessKeyRepositoryMock();
  }

  public static GetProductRepository(): ProductRepository {
    if (environment.services.productRepository === this.Api) {
      const api = ServiceLocator.injector.get(ProductApiService);
      return new ProductRepositoryApi(api);
    }
    return new ProductRepositoryMock();
  }

  public static GetSurveyRepository(): SurveyRepository {
    if (environment.services.surveyRepository === this.Api) {
      const api = ServiceLocator.injector.get(SurveyApiService);
      return new SurveyRepositoryApi(api);
    }
    return new SurveyRepositoryMock();
  }

  public static GetSearchEngineRepository(): SearchRepository {
    if (environment.services.searchEngineRepository === this.Api) {
      const api = ServiceLocator.injector.get(SearchApiService);
      return new SearchRepositoryApi(api);
    }
    return new SearchRepositoryMock();
  }

  public static GetUokDetailRepository(): UokDetailRepository {
    if (environment.services.uokDetailRepository === this.Api) {
      const api = ServiceLocator.injector.get(UokDetailApiService);
      return new UokDetailRepositoryApi(api);
    }
    return new UokDetailRepositoryMock();
  }

  public static GetUokLinkRepository(): UokLinkRepository {
    if (environment.services.uokLinkRepository === this.Api) {
      const api = ServiceLocator.injector.get(UokLinkApiService);
      return new UokLinkRepositoryApi(api);
    }
    return new UokLinkRepositoryMock();
  }

  public static GetDraftRepository(): DraftRepository {
    if (environment.services.draftRepository === this.Api) {
      const api = ServiceLocator.injector.get(DraftApiService);
      return new DraftRepositoryApi(api);
    }
    return new DraftRepositoryMock();
  }

  public static GetStripeRepository(): StripeRepository {
    if (environment.services.draftRepository === this.Api) {
      const api = ServiceLocator.injector.get(StripeApiService);
      const service = ServiceLocator.injector.get(StripeService);
      return new StripeRepositoryApi(api, service);
    }
    return new StripeRepositoryMock();
  }
}
