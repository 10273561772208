import {Observable, ReplaySubject} from "rxjs";
import {IProductDto} from "../../api/dtos/products/product-dto.interface";
import {BaseRepository} from "../abstractions/repositories/base-repository.class";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../abstractions/repositories/repository-response.interface";

export abstract class ProductRepository extends BaseRepository {
  //#region Load Products
  public abstract loadProducts(lang: string, loadOnlyActive: boolean): void

  protected loadProductsResponseSubject = new ReplaySubject<RepositoryResponse<IProductDto[]>>();

  public loadProductsResponse$(): Observable<RepositoryResponse<IProductDto[]>> {
    return this.loadProductsResponseSubject.asObservable();
  }

  public setLoadProductsResponse(tmp: RepositoryResponse<IProductDto[]>) {
    this.loadProductsResponseSubject.next(tmp);
  }

  //#endregion

  //#region Activate Product
  public abstract activateProduct(productId: number, userEmail: string, userName: string, userSurname: string): void

  protected activateProductResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public activateProductResponse$(): Observable<RepositoryResponseGeneric> {
    return this.activateProductResponseSubject.asObservable();
  }

  public setActivateProductResponse(tmp: RepositoryResponseGeneric) {
    this.activateProductResponseSubject.next(tmp);
  }

  //#endregion
}
