import {
  IDtoUokDetailBodiesItemsBodyItemBool
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-bool.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemDocument
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-document.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemEnum
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-enum.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemHeader
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-header.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemImage
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-image.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemInt
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-int.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemKnowledgeTree
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-knowledge-tree.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemLinkDefinition
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-link-definition.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemReferredBy
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-referred-by-interface";
import {
  IDtoUokDetailBodiesItemsBodyItemReferredBySpecific
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-referred-by-specific.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemString
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-string.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemTextHtml
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-text-html.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemTextPlain
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-text-plain.interface";
import {
  IDtoUokDetailBodiesItemsBodyItemVideo
} from "../../api/dtos/uok-detail/bodies/items/idto-uok-detail-bodies-items-body-item-video.interface";
import {UokDetailBodiesBodyItemDto} from "../../api/dtos/uok-detail/bodies/uok-detail-bodies-body-item-dto.interface";
import {
  UokDetailBodiesDisplayInfoDto
} from "../../api/dtos/uok-detail/bodies/uok-detail-bodies-display-info-dto.interface";
import {
  UokDetailBodiesBodyItemHeaderDto
} from "../../api/dtos/uok-detail/bodies/uok-detail-bodies-item-header-dto.interface";
import {UokDetailContentInfoDto} from "../../api/dtos/uok-detail/uok-detail-content-info-dto.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {
  UokDetailContentBodyDisplayInfoModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-display-info-model.interface";
import {
  UokDetailContentBodyHeaderModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-header-model.interface";
import {
  UokDetailContentBodyItemBoolModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-bool-model.interface";
import {
  UokDetailContentBodyItemDocumentModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-document-model.interface";
import {
  UokDetailContentBodyItemEnumModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-enum-model.interface";
import {
  UokDetailContentBodyItemHeaderModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-header-model.interface";
import {
  UokDetailContentBodyItemImageModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-image-model.interface";
import {
  UokDetailContentBodyItemIntModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-int-model.interface";
import {
  UokDetailContentBodyItemKnowledgeTreeModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-knowledge-tree-model.interface";
import {
  UokDetailContentBodyItemLinkDefinitionModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-link-definition-model.interface";
import {
  UokDetailContentBodyItemReferredByModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-referred-by-model.interface";
import {
  UokDetailContentBodyItemReferredBySpecificModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-referred-by-specific-model.interface";
import {
  UokDetailContentBodyItemStringModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-string-model.interface";
import {
  UokDetailContentBodyItemTextHtmlModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-text-html-model.interface";
import {
  UokDetailContentBodyItemTextPlainModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-text-plain-model.interface";
import {
  UokDetailContentBodyItemVideoModel
} from "../../core/state/detail/models/contents/uok-detail-content-body-item-video-model.interface";
import {
  UokDetailContentBodyItemModel
} from "../../core/state/detail/models/uok-detail-content-body-item-model.interface";
import {UokDetailContentInfoModel} from "../../core/state/detail/models/uok-detail-content-info-model.interface";

export class UokDetailContentInfoMapper extends Mapper<UokDetailContentInfoDto, UokDetailContentInfoModel> {
  mapFrom(param: UokDetailContentInfoDto): UokDetailContentInfoModel {
    const res: UokDetailContentInfoModel = {
      uokId: param.UokId,
      cloneUokId: param.CloneUokId,
      bodyItems: [],
      linksDefinition: [],
      mediaDocuments: [],
      mediaImages: [],
      mediaVideos: []
    };

    param.BodyItems.forEach(b => {
      res.bodyItems.push(
        UokDetailContentInfoMapper.mapUokDetailContentBodyItemFrom(b))
    })

    param.LinksDefinition.forEach(l => {
      res.linksDefinition.push(
        UokDetailContentInfoMapper
          .mapUokDetailContentBodyItemFrom(l) as UokDetailContentBodyItemLinkDefinitionModel)
    });


    param.MediaDocuments.forEach(m => {
      res.mediaDocuments.push(
        UokDetailContentInfoMapper
          .mapUokDetailContentBodyItemFrom(m) as UokDetailContentBodyItemDocumentModel)
    });


    param.MediaImages.forEach(m => {
      res.mediaImages.push(
        UokDetailContentInfoMapper
          .mapUokDetailContentBodyItemFrom(m) as UokDetailContentBodyItemImageModel)
    });

    param.MediaVideos.forEach(m => {
      res.mediaVideos.push(
        UokDetailContentInfoMapper
          .mapUokDetailContentBodyItemFrom(m) as UokDetailContentBodyItemVideoModel)
    });

    return res;
  }

  mapTo(param: UokDetailContentInfoModel): UokDetailContentInfoDto {
    const res: UokDetailContentInfoDto = {
      UokId: param.uokId,
      CloneUokId: param.cloneUokId,
      BodyItems: [],
      LinksDefinition: [],
      MediaDocuments: [],
      MediaImages: [],
      MediaVideos: []
    };

    param.bodyItems.forEach(b => {
      res.BodyItems.push({
        Display: {
          Column: b.display.column,
          Row: b.display.column,
          DisplayHeader: b.display.displayHeader,
          LinkLayout: b.display.linkLayout,
          ContentLayout: b.display.contentLayout
        },
        Header: {
          IsVisible: b.header.isVisible,
          Depth: b.header.depth,
          Alignment: b.header.alignment,
          Content: b.header.content
        },
        ItemTypeName: b.itemTypeName,
        ContentTypeName: b.contentTypeName,
      })
    })

    param.linksDefinition.forEach(x => {
      res.LinksDefinition.push({
        Display: {
          Column: x.display.column,
          Row: x.display.column,
          DisplayHeader: x.display.displayHeader,
          LinkLayout: x.display.linkLayout,
          ContentLayout: x.display.contentLayout
        },
        Header: {
          IsVisible: x.header.isVisible,
          Depth: x.header.depth,
          Alignment: x.header.alignment,
          Content: x.header.content
        },
        ItemTypeName: x.itemTypeName,
        ContentTypeName: x.contentTypeName,
        GroupByUokType: x.groupByUokType,
        LinkSystemName: x.linkSystemName,
        ShowAbstract: x.showAbstract,
        ShowImage: x.showImage,
        ShowNote: x.showNote
      })
    })

    param.mediaDocuments.forEach(x => {
      res.MediaDocuments.push({
        Display: {
          Column: x.display.column,
          Row: x.display.column,
          DisplayHeader: x.display.displayHeader,
          LinkLayout: x.display.linkLayout,
          ContentLayout: x.display.contentLayout
        },
        Header: {
          IsVisible: x.header.isVisible,
          Depth: x.header.depth,
          Alignment: x.header.alignment,
          Content: x.header.content
        },
        ItemTypeName: x.itemTypeName,
        ContentTypeName: x.contentTypeName,
        Description: x.description,
        DocumentUrl: x.documentUrl,
        Ordinal: x.ordinal
      })
    })

    param.mediaImages.forEach(x => {
      res.MediaImages.push({
        Display: {
          Column: x.display.column,
          Row: x.display.column,
          DisplayHeader: x.display.displayHeader,
          LinkLayout: x.display.linkLayout,
          ContentLayout: x.display.contentLayout
        },
        Header: {
          IsVisible: x.header.isVisible,
          Depth: x.header.depth,
          Alignment: x.header.alignment,
          Content: x.header.content
        },
        ItemTypeName: x.itemTypeName,
        ContentTypeName: x.contentTypeName,
        Ordinal: x.ordinal,
        AlternateText: x.alternateText,
        ImageFullUrl: x.imageFullUrl,
        ImageSmallUrl: x.imageSmallUrl
      })
    })

    param.mediaVideos.forEach(x => {
      res.MediaVideos.push({
        Display: {
          Column: x.display.column,
          Row: x.display.column,
          DisplayHeader: x.display.displayHeader,
          LinkLayout: x.display.linkLayout,
          ContentLayout: x.display.contentLayout
        },
        Header: {
          IsVisible: x.header.isVisible,
          Depth: x.header.depth,
          Alignment: x.header.alignment,
          Content: x.header.content
        },
        ItemTypeName: x.itemTypeName,
        ContentTypeName: x.contentTypeName,
        Description: x.description,
        Ordinal: x.ordinal,
        Link: x.link,
        Uri: x.uri
      })
    })

    return res;
  }


  private static mapBodyHeaderFrom(dto: UokDetailBodiesBodyItemHeaderDto): UokDetailContentBodyHeaderModel {
    return {
      isVisible: dto.IsVisible,
      depth: dto.Depth,
      alignment: dto.Alignment,
      content: dto.Content
    }
  }

  private static mapBodyDisplayFrom(dto: UokDetailBodiesDisplayInfoDto): UokDetailContentBodyDisplayInfoModel {
    return {
      column: dto.Column,
      row: dto.Row,
      displayHeader: dto.DisplayHeader,
      linkLayout: dto.LinkLayout,
      contentLayout: dto.ContentLayout
    }
  }


  private static mapUokDetailContentBodyItemFrom(dtoBase: UokDetailBodiesBodyItemDto): UokDetailContentBodyItemModel {
    const display = UokDetailContentInfoMapper.mapBodyDisplayFrom(dtoBase.Display);
    const header = UokDetailContentInfoMapper.mapBodyHeaderFrom(dtoBase.Header);
    const itemTypeName = dtoBase.ItemTypeName;
    let model: UokDetailContentBodyItemModel | null = null;

    switch (dtoBase.ItemTypeName) {
      case 'BodyItemBool': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemBool;
        const tmp: UokDetailContentBodyItemBoolModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          value: dto.Value
        }
        model = tmp;
        break;
      }
      case 'BodyItemEnum': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemEnum;
        const tmp: UokDetailContentBodyItemEnumModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          value: dto.Value
        }
        model = tmp;
        break;
      }
      case 'BodyItemInt': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemInt;
        const tmp: UokDetailContentBodyItemIntModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          value: dto.Value
        }
        model = tmp;
        break;
      }
      case 'BodyItemString': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemString;
        const tmp: UokDetailContentBodyItemStringModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          value: dto.Value
        }
        model = tmp;
        break;
      }
      case 'BodyItemTextPlain': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemTextPlain;
        const tmp: UokDetailContentBodyItemTextPlainModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          content: dto.Content,
          ordinal: dto.Ordinal
        }
        model = tmp;
        break;
      }
      case 'BodyItemTextHtml': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemTextHtml;
        const tmp: UokDetailContentBodyItemTextHtmlModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          content: dto.Content,
          ordinal: dto.Ordinal
        }
        model = tmp;
        break;
      }
      case 'BodyItemHeader': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemHeader;
        const tmp: UokDetailContentBodyItemHeaderModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          content: dto.Content,
          ordinal: dto.Ordinal
        }
        model = tmp;
        break;
      }
      case 'BodyItemLinkDefinition': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemLinkDefinition
        const tmp: UokDetailContentBodyItemLinkDefinitionModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          groupByUokType: dto.GroupByUokType,
          linkSystemName: dto.LinkSystemName,
          showAbstract: dto.ShowAbstract,
          showImage: dto.ShowImage,
          showNote: dto.ShowNote
        }
        model = tmp;
        break;
      }
      case 'BodyItemReferredBy': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemReferredBy;
        const tmp: UokDetailContentBodyItemReferredByModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          linksForSpecificReferredBy: dto.LinksForSpecificReferredBy
        }
        model = tmp;
        break;
      }
      case 'BodyItemReferredBySpecific': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemReferredBySpecific;
        const tmp: UokDetailContentBodyItemReferredBySpecificModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          linkSystemName: dto.LinkSystemName
        }
        model = tmp;
        break;
      }
      case 'BodyItemKnowledgeTree': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemKnowledgeTree;
        const tmp: UokDetailContentBodyItemKnowledgeTreeModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          placeholder: dto.placeholder
        }
        model = tmp;
        break;
      }
      case 'BodyItemVideo': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemVideo;
        const tmp: UokDetailContentBodyItemVideoModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          ordinal: dto.Ordinal,
          link: dto.Link,
          uri: dto.Uri,
          description: dto.Description
        }
        model = tmp;
        break;
      }
      case 'BodyItemImage': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemImage;
        const tmp: UokDetailContentBodyItemImageModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          ordinal: dto.Ordinal,
          alternateText: dto.AlternateText,
          imageFullUrl: dto.ImageFullUrl,
          imageSmallUrl: dto.ImageSmallUrl
        }
        model = tmp;
        break;
      }
      case 'BodyItemDocument': {
        const dto = dtoBase as IDtoUokDetailBodiesItemsBodyItemDocument;
        const tmp: UokDetailContentBodyItemDocumentModel = {
          display,
          header,
          itemTypeName,
          contentTypeName: dto.ContentTypeName,
          ordinal: dto.Ordinal,
          description: dto.Description,
          documentUrl: dto.DocumentUrl
        }
        model = tmp;
        break;
      }

    }


    return model ?? {
      display: {
        column: -1, displayHeader: false, row: -1, contentLayout: '', linkLayout: ''
      },
      header: {
        isVisible: false, content: '', depth: -1, alignment: ''
      },
      itemTypeName: '',
      contentTypeName: ''
    };
  }
}
