import {IUserLoginResponseDto} from '../../api/dtos/security';
import {Mapper} from '../../core/abstractions/repositories';
import {AccountStateModel} from '../../core/state/account';

export class AuthMapper implements Mapper<IUserLoginResponseDto, AccountStateModel> {
  mapFrom(param: IUserLoginResponseDto): AccountStateModel {
    return {
      username: param.userName,
      token: param.access_token,
      expireTimestamp: new Date( ),
      roles: []
    };
  }

  mapTo(param: AccountStateModel): IUserLoginResponseDto {
    return {
      userName: param.username,
      access_token: param.token,
      expires_in: 0
    }
  }

}
