import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {UserAccessKeysActions} from "./actions/user-access-keys-actions";
import {UserActions} from './actions/user-actions';
import {UserCashActions} from "./actions/user-cash-actions";
import {UserCreateSetupIntentActions} from "./actions/user-create-setup-intent-actions";
import {UserStripeInfoActions} from "./actions/user-stripe-info-actions";
import {UserAccessKeyModel} from "./models/user-access-key-model.interface";
import {UserCashInfoModel} from "./models/user-cash-info-model.interface";
import {UserModel} from "./models/user-model.interface";
import {UserStateModel} from './models/user-state-model.interface';
import {StripeSubscriptionStatus, UserStripeInfoModel} from "./models/user-stripe-info-model.interface";

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
    stripe: null,
    accessKeys: [],
    cash: null,
    stripeSetupSecret: null
  }
})
@Injectable()
export class UserState {
  constructor() {
  }

  @Selector()
  static isProfileComplete(state: UserStateModel): boolean {
    return !!state.user?.name && !!state.user?.surname;
  }

  @Selector()
  static fullState(state: UserStateModel): UserStateModel {
    return state;
  }

  @Selector()
  static user(state: UserStateModel): UserModel | null {
    return state.user;
  }

  @Selector()
  static stripe(state: UserStateModel): UserStripeInfoModel | null {
    return state.stripe;
  }

  @Selector()
  static stripeSecret(state: UserStateModel): string | null {
    return state.stripeSetupSecret;
  }

  @Selector()
  static accessKeys(state: UserStateModel): UserAccessKeyModel[] {
    return state.accessKeys;
  }

  @Selector()
  static cash(state: UserStateModel): UserCashInfoModel | null {
    return state.cash;
  }

  @Selector()
  static isValid(state: UserStateModel): boolean {
    return !!state.user?.username;
  }

  @Selector()
  public static userNeedPaymentUpdate(state: UserStateModel): boolean {
    if (state.stripe === null) {
      return false;
    }

    // only if active or cancelled there is no need to payment update
    // see stripe api doc: https://stripe.com/docs/api/subscriptions/object

    return (state.stripe.subscription.status === StripeSubscriptionStatus.Incomplete ||
      state.stripe.subscription.status === StripeSubscriptionStatus.IncompleteExpired ||
      state.stripe.subscription.status === StripeSubscriptionStatus.PastDue ||
      state.stripe.subscription.status === StripeSubscriptionStatus.Unpaid);
  }

  @Action(UserActions.ClearModelSucceded)
  userClearModelSucceded(ctx: StateContext<UserStateModel>) {
    ctx.setState({
      user: null,
      stripe: null,
      accessKeys: [],
      cash: null,
      stripeSetupSecret: null
    })
  }

  //#region Get User model
  @Action(UserActions.GetModelRequested)
  userGetModelRequested(_ctx: StateContext<UserStateModel>, _action: UserActions.GetModelRequested): void {
  }

  @Action(UserActions.GetModelSucceded)
  userGetModelSucceded(ctx: StateContext<UserStateModel>, action: UserActions.GetModelSucceded): void {
    ctx.patchState({user: action.userModel});
  }

  @Action(UserActions.GetModelFailed)
  userGetModelFailed(ctx: StateContext<UserStateModel>): void {
    ctx.patchState({user: null});
  }

  @Action(UserActions.RefreshCurrentData)
  userRefreshCurrentData(ctx: StateContext<UserStateModel>): void {
    const usrData = ctx.getState().user;
    ctx.patchState({user: usrData});
  }

  //#endregion

  //#region Get Stripe Info
  @Action(UserStripeInfoActions.Succeded)
  userGetStripeInfoSucceded(ctx: StateContext<UserStateModel>, action: UserStripeInfoActions.Succeded): void {
    ctx.patchState({stripe: action.model});
  }

  //#endregion

  //#region Get Access Keys

  @Action(UserAccessKeysActions.GetAccessKeysModelSucceded)
  userGetAccessKeysSucceded(ctx: StateContext<UserStateModel>, action: UserAccessKeysActions.GetAccessKeysModelSucceded) {
    ctx.patchState({accessKeys: action.keys});
  }

  //#endregion

  @Action(UserCashActions.Succeded)
  userGetCashInfoSucceded(ctx: StateContext<UserStateModel>, action: UserCashActions.Succeded) {
    ctx.patchState({cash: action.info})
  }

  //#region Create Setup Intent
  @Action(UserCreateSetupIntentActions.Succeded)
  userCreateSetupIntentSucceded(ctx: StateContext<UserStateModel>, action: UserCreateSetupIntentActions.Succeded) {
    ctx.patchState({stripeSetupSecret: action.model.clientSecret});
  }

  @Action(UserCreateSetupIntentActions.Clear)
  userCreateSetupIntentClear(ctx: StateContext<UserStateModel>, action: UserCreateSetupIntentActions.Clear) {
    ctx.patchState({stripeSetupSecret: null});
  }

  //#endregion
}
