import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {AccountAddUserPermissions} from "./account-add-user-permissions-actions";
import {AccountSignInActions} from './account-sign-in-actions';
import {AccountSignOutActions} from "./account-sign-out-actions";
import {AccountStateModel} from './account-state-model.interface';

@State<AccountStateModel>({
  name: 'account',
  defaults: {
    token: null,
    username: null,
    roles: [],
    expireTimestamp: new Date()
  }
})

@Injectable()
export class AccountState {


  constructor() {
  }

  @Selector()
  static isLicenceFree(state: AccountStateModel): boolean {
    return state.roles?.some(r => r === 'User.Free')??true;
  }

  @Selector()
  static isEditor(state: AccountStateModel): boolean {
    return state.roles?.some(r => r === 'Grp.Editor')??true;
  }

  @Selector()
  static isSuperUser(state: AccountStateModel): boolean {
    return state.roles?.some(r => r === 'Grp.SuperUser')??true;
  }

  @Selector()
  static isLicencePro(state: AccountStateModel): boolean {
    return state.roles?.some(r => r === 'User.Pro')??false;
  }


  @Selector()
  static token(state: AccountStateModel): string | null {
    return state.token;
  }

  @Selector()
  static username(state: AccountStateModel): string | null {
    return state.username;
  }

  @Selector()
  static isAuthenticated(state: AccountStateModel): boolean {
    return !!state.token;
  }

  @Action(AccountSignInActions.Requested)
  loginRequested(_ctx: StateContext<AccountStateModel>, _action: AccountSignInActions.Requested): void {
  }

  @Action(AccountSignInActions.Succeded)
  loginSucceded(ctx: StateContext<AccountStateModel>, action: AccountSignInActions.Succeded): void {
    ctx.setState(action.payload.authModel);
    //ctx.dispatch(new Navigate(['login']));
  }

  @Action(AccountSignInActions.Failed)
  loginFailed(ctx: StateContext<AccountStateModel>): void {
    ctx.setState({
      token: null,
      username: null,
      roles: [],
      expireTimestamp: new Date()
    });
  }

  @Action(AccountAddUserPermissions.Succeded)
  accountAddUserPermissions(ctx: StateContext<AccountStateModel>, action: AccountAddUserPermissions.Succeded) {
    ctx.patchState({
      roles: action.permissions
    })
  }

  @Action(AccountSignOutActions.Succeded)
  logoutSucceded(ctx: StateContext<AccountStateModel>): void {
    ctx.setState({
      token: null,
      username: null,
      roles: [],
      expireTimestamp: new Date()
    });
  }
}
