import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {SearchEngineActions} from "./search-engine-actions";
import {SearchEngineStateModel} from "./search-engine-state-model.interface";

@State<SearchEngineStateModel>({
  name: 'searchEngine',
  defaults: {
    query: null,
    getByNameStartWith: [],
    results: [],
    totalPages: 0,
    currentPage: 1,
    bookmarksBookmarked: [],
    bookmarksArchived: [],
    bookmarksRecent: [],
    signaled: [],
    contributed: [],
    disabled: [],
    latest: [],
    published: [],
    highlighted: [],
    courses: [],
    searchByName: []
  }
})
@Injectable()
export class SearchEngineState {
  @Selector()
  static searchQuery(state: SearchEngineStateModel) {
    return state.query;
  }

  @Selector()
  static bookmarksBookmarked(state: SearchEngineStateModel) {
    return state.bookmarksBookmarked;
  }

  @Selector()
  static bookmarksArchived(state: SearchEngineStateModel) {
    return state.bookmarksArchived;
  }

  @Selector()
  static bookmarksRecent(state: SearchEngineStateModel) {
    return state.bookmarksRecent;
  }

  @Selector()
  static signaled(state: SearchEngineStateModel) {
    return state.signaled;
  }

  @Selector()
  static contributed(state: SearchEngineStateModel) {
    return state.contributed;
  }

  @Selector()
  static disabled(state: SearchEngineStateModel) {
    return state.disabled;
  }

  @Selector()
  static latest(state: SearchEngineStateModel) {
    return state.latest;
  }

  @Selector()
  static published(state: SearchEngineStateModel) {
    return state.published;
  }

  @Selector()
  static searchResults(state: SearchEngineStateModel) {
    return state.results;
  }

  @Selector()
  static searchResultsCurrentPage(state: SearchEngineStateModel) {
    return state.currentPage;
  }

  @Selector()
  static searchResultsTotalPages(state: SearchEngineStateModel) {
    return state.totalPages;
  }

  @Selector()
  static highlighted(state: SearchEngineStateModel) {
    return state.highlighted;
  }

  @Selector()
  static courses(state: SearchEngineStateModel) {
    return state.courses;
  }

  @Selector()
  static searchByName(state: SearchEngineStateModel) {
    return state.searchByName;
  }

  @Action(SearchEngineActions.SearchEngineGetByDtoSucceded)
  searchEngineGetByDtoSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetByDtoSucceded) {
    ctx.patchState({
      results: action.result.results,
      currentPage: action.result.currentPage,
      totalPages: action.result.totalPages
    })
  }

  @Action(SearchEngineActions.SearchEngineSetQuery)
  searchEngineSetQuery(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineSetQuery) {
    ctx.patchState({
      query: action.query
    })
  }

  @Action(SearchEngineActions.SearchEngineClear)
  searchEngineClear(ctx: StateContext<SearchEngineStateModel>) {
    ctx.patchState({
      query: null,
      results: []
    })
  }

  @Action(SearchEngineActions.SearchEngineGetBookmarksBookmarkedSucceded)
  searchEngineGetBookmarksBookmarkedSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetBookmarksBookmarkedSucceded) {
    ctx.patchState({
      bookmarksBookmarked: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetBookmarksArchivedSucceded)
  searchEngineGetBookmarksArchivedSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetBookmarksArchivedSucceded) {
    ctx.patchState({
      bookmarksArchived: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetBookmarksRecentSucceded)
  searchEngineGetBookmarksRecentSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetBookmarksRecentSucceded) {
    ctx.patchState({
      bookmarksRecent: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetSignaledSucceded)
  searchEngineGetSignaledSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetSignaledSucceded) {
    ctx.patchState({
      signaled: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetLatestSucceded)
  searchEngineGetLatestSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetLatestSucceded) {
    ctx.patchState({
      latest: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetPublishedSucceded)
  searchEngineGetPublishedSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetPublishedSucceded) {
    ctx.patchState({
      published: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetContributedSucceded)
  searchEngineGetContributedSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetContributedSucceded) {
    ctx.patchState({
      contributed: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetDisabledSucceded)
  searchEngineGetDisabledSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetDisabledSucceded) {
    ctx.patchState({
      disabled: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetHighlightedSucceded)
  searchEngineGetHighlightedSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetHighlightedSucceded) {
    ctx.patchState({
      highlighted: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetCoursesSucceded)
  searchEngineGetCoursesSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetCoursesSucceded) {
    ctx.patchState({
      courses: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetByNameStartsWithSucceded)
  searchEngineGetByNameStartsWithSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetByNameStartsWithSucceded) {
    ctx.patchState({
      getByNameStartWith: action.results
    })
  }

  @Action(SearchEngineActions.SearchEngineGetByNameSucceded)
  searchEngineGetByNameSucceded(ctx: StateContext<SearchEngineStateModel>, action: SearchEngineActions.SearchEngineGetByNameSucceded) {
    ctx.patchState({
      searchByName: action.results
    })
  }

  @Action(SearchEngineActions.ResetSearchEngine)
  resetSearchEngine(ctx: StateContext<SearchEngineStateModel>) {
    ctx.setState({
      query: null,
      getByNameStartWith: [],
      results: [],
      totalPages: 0,
      currentPage: 1,
      bookmarksBookmarked: [],
      bookmarksArchived: [],
      bookmarksRecent: [],
      signaled: [],
      contributed: [],
      disabled: [],
      latest: [],
      published: [],
      highlighted: [],
      courses: [],
      searchByName: []
    })
  }

  @Selector()
  static getByNameStartWith(state: SearchEngineStateModel) {
    return state.getByNameStartWith;
  }
}
