import {takeUntil} from "rxjs/operators";
import {IDtoReferredInfo} from "../../api/dtos/uok-link/idto-referred-info.interface";
import {IDtoUokLink} from "../../api/dtos/uok-link/idto-uok-link.interface";
import {IDtoUokLinksDefinition} from "../../api/dtos/uok-link/idto-uok-links-definition.interface";
import {UokLinkApiService} from "../../api/services/uok-link-api.service";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../../core/abstractions/repositories/repository-response.interface";
import {UokLinkRepository} from "../../core/repositories/uok-link-repository.class";
import {HttpUtils} from "../../core/utils/http-utils.class";

export class UokLinkRepositoryApi extends UokLinkRepository {
  constructor(private linkApi: UokLinkApiService) {
    super();
  }

  loadKnowledgeTree(id: number, lang: string): void {
    this.linkApi.loadKnowledgeTree(id, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        const body = data as IDtoReferredInfo[];
        const tmp: RepositoryResponse<IDtoReferredInfo[]> = {
          dto: body ?? null,
          info: {
            level: !body ? 1 : 0,
            messages: [
              !body ? 'IDtoReferredInfo[] is null' : 'IDtoReferredInfo[] received',
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadKnowledgeTreeResponse(tmp);
      },
      error: err => {
        const tmp: RepositoryResponse<IDtoReferredInfo[]> = {
          dto: null,
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadKnowledgeTreeResponse(tmp);
      }
    });
  }

  loadUokLinks(id: number, lang: string): void {
    this.linkApi.loadUokLinks(id, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        const body = data as IDtoUokLink[];
        const tmp: RepositoryResponse<IDtoUokLink[]> = {
          dto: body ?? null,
          info: {
            level: !body ? 1 : 0,
            messages: [
              !body ? 'IDtoUokLink[] is null' : 'IDtoUokLink[] received',
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadUokLinksResponse(tmp);
      },
      error: err => {
        const tmp: RepositoryResponse<IDtoUokLink[]> = {
          dto: null,
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadUokLinksResponse(tmp);
      }
    });
  }

  loadUokLinksDefinition(dtoUokName: string, lang: string): void {
    this.linkApi.loadUokLinksDefinition(dtoUokName, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        const body = data as IDtoUokLinksDefinition;
        const tmp: RepositoryResponse<IDtoUokLinksDefinition> = {
          dto: body ?? null,
          info: {
            level: !body ? 1 : 0,
            messages: [
              !body ? 'IDtoUokLinksDefinition is null' : 'IDtoUokLinksDefinition received',
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadUokLinksDefinitionResponse(tmp);
      },
      error: err => {
        const tmp: RepositoryResponse<IDtoUokLinksDefinition> = {
          dto: null,
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadUokLinksDefinitionResponse(tmp);
      }
    });
  }

  loadUokLinksReferred(id: number, lang: string): void {
    this.linkApi.loadUokLinksReferred(id, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        const body = data as IDtoReferredInfo[];
        const tmp: RepositoryResponse<IDtoReferredInfo[]> = {
          dto: body ?? null,
          info: {
            level: !body ? 1 : 0,
            messages: [
              !body ? 'IDtoReferredInfo[] is null' : 'IDtoReferredInfo[] received',
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadUokLinksReferredResponse(tmp);
      },
      error: err => {
        const tmp: RepositoryResponse<IDtoReferredInfo[]> = {
          dto: null,
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadUokLinksReferredResponse(tmp);
      }
    });
  }

  saveUokLinks(links: IDtoUokLink[], lang: string): void {
    this.linkApi.saveUokLinks(links, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: () => {
        const tmp: RepositoryResponseGeneric = {
          info: {
            level: 0,
            messages: ['Uok Links saved']
          }
        }
        this.setIsUpdating(false);
        this.setSaveUokLinksResponse(tmp);
      },
      error: err => {
        const tmp: RepositoryResponseGeneric = {
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        }
        this.setIsUpdating(false);
        this.setSaveUokLinksResponse(tmp);
      }
    });
  }
}
