import {Observable, ReplaySubject} from "rxjs";
import {BookmarkDto} from "../../api/dtos/uok-detail/bookmark-dto.interface";
import {UokDetailContentInfoDto} from "../../api/dtos/uok-detail/uok-detail-content-info-dto.interface";
import {UokDetailHeaderDto} from "../../api/dtos/uok-detail/uok-detail-header-dto.interface";
import {UokDetailLockInfoDto} from "../../api/dtos/uok-detail/uok-detail-lock-info-dto.interface";
import {UokDetailUserInfoDto} from "../../api/dtos/uok-detail/uok-detail-user-info-dto.interface";
import {BaseRepository} from "../abstractions/repositories/base-repository.class";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../abstractions/repositories/repository-response.interface";

export abstract class UokDetailRepository extends BaseRepository {
  //#region Uok Header
  public abstract loadUokHeader(uokId: number, lang: string): void

  protected loadUokHeaderResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailHeaderDto>>();

  public uokHeaderResponse$(): Observable<RepositoryResponse<UokDetailHeaderDto>> {
    return this.loadUokHeaderResponseSubject.asObservable();
  }

  public setUokHeaderResponse(tmp: RepositoryResponse<UokDetailHeaderDto>) {
    this.loadUokHeaderResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok User Info
  public abstract loadUokUserInfo(uokId: number, lang: string): void

  protected uokUserInfoResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailUserInfoDto>>();

  public uokUserInfoResponse$(): Observable<RepositoryResponse<UokDetailUserInfoDto>> {
    return this.uokUserInfoResponseSubject.asObservable();
  }

  public setUokUserInfoResponse(tmp: RepositoryResponse<UokDetailUserInfoDto>) {
    this.uokUserInfoResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Lock Info
  public abstract loadUokLockInfo(uokId: number, lang: string): void

  protected uokLockInfoResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailLockInfoDto>>();

  public uokLockInfoResponse$(): Observable<RepositoryResponse<UokDetailLockInfoDto>> {
    return this.uokLockInfoResponseSubject.asObservable();
  }

  public setUokLockInfoResponse(tmp: RepositoryResponse<UokDetailLockInfoDto>) {
    this.uokLockInfoResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Content Info
  public abstract loadUokContentInfo(uokId: number, lang: string): void

  protected uokContentInfoResponseSubject = new ReplaySubject<RepositoryResponse<UokDetailContentInfoDto>>();

  public uokContentInfoResponse$(): Observable<RepositoryResponse<UokDetailContentInfoDto>> {
    return this.uokContentInfoResponseSubject.asObservable();
  }

  public setUokContentInfoResponse(tmp: RepositoryResponse<UokDetailContentInfoDto>) {
    this.uokContentInfoResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Bookmark Get State
  public abstract loadUokBookmarkState(uokId: number): void

  protected loadUokBookmarkStateResponseSubject = new ReplaySubject<RepositoryResponse<BookmarkDto>>();

  public loadUokBookmarkStateResponse$(): Observable<RepositoryResponse<BookmarkDto>> {
    return this.loadUokBookmarkStateResponseSubject.asObservable();
  }

  public setLoadUokBookmarkStateResponse(tmp: RepositoryResponse<BookmarkDto>) {
    this.loadUokBookmarkStateResponseSubject.next(tmp);
  }

  //#endregion

  //#region Uok Bookmark Set State
  public abstract setUokBookmarkState(dto: BookmarkDto): void

  protected setUokBookmarkStateResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public setUokBookmarkStateResponse$(): Observable<RepositoryResponseGeneric> {
    return this.setUokBookmarkStateResponseSubject.asObservable();
  }

  public setSetUokBookmarkStateResponse(tmp: RepositoryResponseGeneric) {
    this.setUokBookmarkStateResponseSubject.next(tmp);
  }

  //#endregion
}
