export class UtilsModel {
  public static getProductImagePath(imageName: string | undefined) {
    return !!imageName ? `assets/imgs/products/${imageName}` : '';
  }

  public static getCertificateImagePath(imageName: string | undefined) {
    return !!imageName ? `assets/imgs/certificates/${imageName}` : '';
  }

  public static getContentPolicyPath(){
    return 'assets/certificates/knowlative-author-guidelines.pdf'
  }
}
