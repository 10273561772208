import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {ProductActions} from "./product-actions";
import {ProductStateModel} from "./product-state-model.interface";

@State<ProductStateModel>({
    name: 'products',
    defaults: {
        all: [],
        available: [],
        bought: [],
        expired: [],
        loadUserTimestamp: null
    }
})
@Injectable()
export class ProductState {
    constructor() {
    }

    @Selector()
    static all(state: ProductStateModel) {
        return state.all;
    }

    @Selector()
    static available(state: ProductStateModel) {
        return state.available;
    }

    @Selector()
    static expired(state: ProductStateModel) {
        return state.expired;
    }

    @Selector()
    static bought(state: ProductStateModel) {
        return state.bought;
    }

    @Action(ProductActions.All)
    UseCasesSetStateLoadAll(ctx: StateContext<ProductStateModel>, action: ProductActions.All) {
        ctx.patchState({
            all: action.models,
        })
    }

    @Action(ProductActions.Available)
    UseCasesSetStateLoadAvailable(ctx: StateContext<ProductStateModel>, action: ProductActions.Available) {
        ctx.patchState({
            available: action.models,
        })
    }

    @Action(ProductActions.Expired)
    UseCasesSetStateLoadExpired(ctx: StateContext<ProductStateModel>, action: ProductActions.Expired) {
        ctx.patchState({
            expired: action.models,
        })
    }

    @Action(ProductActions.Bought)
    UseCasesSetStateLoadBought(ctx: StateContext<ProductStateModel>, action: ProductActions.Bought) {
        ctx.patchState({
            bought: action.models,
        })
    }

    @Action(ProductActions.ResetProducts)
    resetProducts(ctx: StateContext<ProductStateModel>) {
        ctx.setState({
            all: [],
            available: [],
            bought: [],
            expired: [],
            loadUserTimestamp: null
        })
    }
}
