import {UserAccessKeyModel} from "../models/user-access-key-model.interface";

export namespace UserAccessKeysActions {
  //#region Get Access Keys
  export class GetAccessKeysModelSucceded {
    static readonly type = '[User Access Keys] Get Model Succeded';

    constructor(public keys: UserAccessKeyModel[]) {
    }
  }

  //#endregion

  //#region Get Key Info
  export class GetAccessKeyInfoModelSucceded {
    static readonly type = '[User Access Keys] Get Key Info Model Succeded';

    constructor(public keyInfo: UserAccessKeyModel) {
    }
  }

  //#endregion
}
