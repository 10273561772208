import { environment } from '../../environments/environment';
import { TranslateConfig } from '../core/services/translate-config.class';
import { TranslateConfigApp } from './translate-config-app.service';
import { TranslateConfigMock } from './translate-config-mock.service';
import { VimeoUploaderService } from './vimeo-uploader.service';
import { FilesLoaderService } from './files-loader.service';

class ServiceConcreteClassProvider {
  private static readonly App = 'app';

  public static GetTranslateConfigService(): any {
    if (environment.services.translateConfig === this.App) {
      return TranslateConfigApp;
    }
    return TranslateConfigMock;
  }

  public static GetVimeoService(): any {
    return VimeoUploaderService;
  }

  public static GetFilesLoaderService(): any {
    return FilesLoaderService;
  }
}

export const serviceProviders = [
  { provide: TranslateConfig, useClass: ServiceConcreteClassProvider.GetTranslateConfigService() },
  { provide: VimeoUploaderService, useClass: ServiceConcreteClassProvider.GetVimeoService() },
  { provide: FilesLoaderService, useClass: ServiceConcreteClassProvider.GetFilesLoaderService() },
];
