import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import {UserRepository} from '../../core/repositories';
import {UserActions} from '../../core/state/user/actions';
import {UserModel} from '../../core/state/user/models';
import {UserUpdateUseCase} from '../../core/use-cases/user';
import {DtoUtils, UseCaseStateName} from '../../core/utils';
import {RepositoryFactory} from '../../data';
import {UserMapper} from '../../data/user-repository';
import {UseCaseAppBase} from '../use-case-app-base.class';
import {MessageActivityLevel} from "../../core/utils/message-activity-level.enum";

export class UserUpdateUseCaseApp extends UseCaseAppBase<UserModel> implements UserUpdateUseCase {
  public static readonly Name: string = 'UserUpdate';
  private mapper = new UserMapper();
  private userRepository: UserRepository;

  constructor(store: Store) {
    super(store);
    this.userRepository = RepositoryFactory.GetUserRepository();
    this.setupObservables();
  }

  private setupObservables() {
    this.userRepository.userUpdateResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.info?.level === 0) {
            this.dispatchSetStateWithToast(UseCaseStateName.success, "UseCase.UserUpdate.Success", undefined, undefined, MessageActivityLevel.success);
          } else {
            this.dispatchSetFailureWithAlert(
              {localizationKey: "UseCase.UserUpdate.Failure.Message", params: DtoUtils.getMessage(response)},
              {localizationKey: "UseCase.UserUpdate.Failure.Header"},
              {localizationKey: "UseCase.UserUpdate.Failure.SubHeader"});
          }
        }
      });
  }

  override init() {
    this.dispatchInitWithMessage("UseCase.UserUpdate.Initialized");
  }

  override onExecuteUserEdit() {
    this.dispatchSetStateWithNone(UseCaseStateName.edit, "UseCase.UserUpdate.Edit");
  }

  override onExecuteUserSave(params?: UserModel) {
    if (params === undefined) {
      return;
    }
    this.dispatchSetStateWithNone(UseCaseStateName.working, "UseCase.UserUpdate.Working");
    const tmp = this.mapper.mapTo(params);
    this.userRepository.updateUser(tmp);
  }

  override onExecuteUserDiscard() {
    this.dispatch(new UserActions.RefreshCurrentData);
    this.dispatchSetStateWithToast(UseCaseStateName.initialized, "UseCase.UserUpdate.Discard");
  }

  override getName(): string {
    return UserUpdateUseCaseApp.Name;
  }

  override onDispose() {
    this.userRepository.dispose();
  }
}
