import {UokDetailHeaderModel} from "../detail/models";

export namespace HistoryStateActions {

    export class ArchiveUokDetail {
        static readonly type = '[History] Push UokDetailInHistory';

        constructor(public header: UokDetailHeaderModel) {
        }
    }

    export class ResetHistory {
        static readonly type = '[History] Reset';
    }
}
