import {IDtoSurveyInstance} from "../../api/dtos/surveys/dto-survey-instance.interface";
import {IDtoSurveyTemplate} from "../../api/dtos/surveys/dto-survey-template.interface";
import {SurveyRepository} from "../../core/repositories/survey-repository.class";

export class SurveyRepositoryMock extends SurveyRepository {
  loadAllFilledSurveys(lang: string, loadAnswers: boolean, status: string): void {
  }

  loadAllSurveys(lang: string): void {
  }

  loadFilledSurvey(id: number, lang: string, loadAnswers: boolean): void {
  }

  loadSurvey(id: number, lang: string): void {
  }

  saveSurvey(lang: string, template: IDtoSurveyTemplate): void {
  }

  saveSurveyFilled(lang: string, template: IDtoSurveyInstance): void {
  }

  setSurveyState(id: number, lang: string, stateToAssign: string): void {
  }

}
