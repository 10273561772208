import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {
  constructor(private http: HttpClient) {
  }

  /** Search only within the dto allowed */
  public getByDtos(lang: string, queryText: string, withinDto: string[], maxItems: number, page: number) {
    // prepare the dto for the query
    let dto = '';
    withinDto.forEach((elem) => {
      dto += elem + environment.splitChar;
    });

    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/bydtos?queryText=${queryText}&maxItems=${maxItems}&withinDtos=${dto}&page=${page}`);
  }

  /** Retrieve the Uok that is compliant with the tags is. The tags are separated by a semicolon */
  public getByTagIds(lang: string, includeIds: number[], maxItems: number) {
    // The two arrays are converted in a string in order to pass them to the backend
    let inIds = '';
    const outIds = ''; // not used!

    if (includeIds !== null) {
      includeIds.forEach((n) => {
        inIds = inIds + n + environment.splitChar;
      });
      inIds = inIds.slice(0, -1);
    }

    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/bytagids?inIds=${inIds}&outIds=${outIds}&maxItems=${maxItems}`);
  }

  /** Search only within the dto allowed */
  public getByName(lang: string, queryText: string, withinDto: string[], maxItems: number) {
    // prepare the dto for the query
    let dto = '';
    withinDto.forEach((elem) => {
      dto += elem + environment.splitChar;
    });

    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/byname?queryText=${queryText}&maxItems=${maxItems}&withinDtos=${dto}`);
  }

  /** Search the items that start with the char or word specified (filtering by dto is admitted) */
  public getByNameStartsWith(lang: string, queryText: string, withinDto: string[], maxItems: number) {
    // prepare the dto for the query
    let dto = '';
    withinDto.forEach((elem) => {
      dto += elem + environment.splitChar;
    });

    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/bynamestartswith?queryText=${queryText}&maxItems=${maxItems}&withinDtos=${dto}`)
  }

  /** Get the signaled Uok */
  public getSignaled(lang: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/signaled`);
  }

  /** Get the disabled Uok */
  public getDisabled(lang: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/disabled`);
  }

  /** Get the published Uok */
  public getPublished(lang: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/published`);
  }

  /** Get the published Uok */
  public getContributed(lang: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/contributed`);
  }

  /** Get the latest Uok */
  public getLatest(lang: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/latest`);
  }

  /** Get the contributed Uok when the author is also an active pro user */
  public getContributedByUokAuthor(lang: string, uokAuthorId: number) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/${lang}/uok/contributed-by-uok-author/${uokAuthorId}`);
  }

  /** Get the operators that contain the specified text */
  public getOperatorsByText(queryText: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/operator/by-text?queryText=${queryText}`);
  }


  public getBookmarksByStatus(status: string) {
    return this.http.get(`${environment.webApiV1}/SearchEngine/getBookmarksByStatus/${status}`);
  }
}
