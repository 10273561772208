import {ActivatedRoute, CanActivateFn, Router} from "@angular/router";
import {Store} from "@ngxs/store";
import {combineLatest} from "rxjs";
import {AccountState} from "../core/state/account";
import {UserState} from "../core/state/user";
import {inject} from "@angular/core";
import {Location} from "@angular/common";
import {AppRoutes} from '../app-routes';

export function canAccessAppGuard(): CanActivateFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const route = inject(ActivatedRoute);
    const location = inject(Location);
    const path = location.path();

    const isAuthenticated$ = store.select(AccountState.isAuthenticated)
    const isUserValid$ = store.select(UserState.isValid)

    let canActivate = false;
    combineLatest([isAuthenticated$, isUserValid$]).subscribe(([authenticated, userValid]) => {
      if (authenticated && userValid) {
        canActivate = true;
      } else if (!authenticated && !userValid) {
        canActivate = false;
        const returnUrl = route.snapshot.queryParamMap.get('returnUrl');

        if (path.startsWith('/main')) {
          router.navigate([`/signin`], {queryParams: {returnUrl: path}}).then();
        } else if (path.startsWith('/uok-detail')) {
          router.navigate([`/signin`], {queryParams: {returnUrl: '/main' + path}}).then();
        } else if (path.startsWith('/knowledge-my')) {
          router.navigate([`/signin`], {queryParams: {returnUrl: path.replace('/knowledge-my', '/main/my-drafts')}}).then();
        } else if (returnUrl) {
          router.navigate(['/signin'], {queryParams: {returnUrl: returnUrl}}).then();
        } else {
          router.navigate(['/signin']).then();
        }
      } else {
        canActivate = false;
      }
    })
    return canActivate;
  };
}

export function canAccessLoginGuard(): CanActivateFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    const route = inject(ActivatedRoute);

    const isAuthenticated$ = store.select(AccountState.isAuthenticated)
    const isUserValid$ = store.select(UserState.isValid)
    let canActivate = false;
    combineLatest([isAuthenticated$, isUserValid$]).subscribe(([authenticated, userValid]) => {
      if (authenticated && userValid) {
        const url = route.snapshot.queryParamMap.get('returnUrl')?.replace(/%2F/g, '/');
        router.navigate(['']).then(() => {
          if (url) {
            router.navigate([url]).then()
          }
        });
        canActivate = false;
      } else {
        canActivate = true;
      }
    })
    return canActivate;
  };
}

export function canAccessBecomeProGuard(): CanActivateFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);
    let canActivate = false;
    store.select(AccountState.isLicencePro)
      .subscribe(isPro => {
        if (isPro) {
          router.navigate([AppRoutes.dashboard]).then();
          canActivate = false;
        } else {
          canActivate = true;
        }
      })
    return canActivate;
  };
}

export function canAccessUpdatePaymentMethodView(): CanActivateFn {
  return () => {
    const store = inject(Store);
    const router = inject(Router);

    let canActivate = false;
    store.select(UserState.userNeedPaymentUpdate)
      .subscribe(needed => {
        if (needed) {
          canActivate = true
        } else {
          canActivate = false;
          router.navigate([AppRoutes.dashboard]).then();
        }
      })

    return canActivate;
  }
}
