import {takeUntil} from "rxjs/operators";
import {IDtoAccessKey} from "../../api/dtos/user";
import {AccessKeyApiService} from "../../api/services";
import {RepositoryResponse, RepositoryResponseGeneric} from "../../core/abstractions/repositories";
import {AccessKeyRepository} from "../../core/repositories";
import {HttpUtils} from "../../core/utils";

export class AccessKeyRepositoryApi extends AccessKeyRepository {
  constructor(private akApi: AccessKeyApiService) {
    super();
  }

  generateAccessKeys(friendCode: number, amount: number, discountDays: number, productionLot: string, activableToDay: number, activableToMonth: number, activableToYear: number): void {
    this.setIsUpdating(true);
    this.akApi.generateKeys(friendCode, amount, discountDays, productionLot, activableToDay, activableToMonth, activableToYear)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: data => {
            const body = data as IDtoAccessKey[];
            const tmp: RepositoryResponse<IDtoAccessKey[]> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'IDtoAccessKey[] is null' : 'IDtoAccessKey[] received',
                ]
              }
            }
            this.setIsUpdating(false);
            this.setGenerateAccessKeysResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoAccessKey[]> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setGenerateAccessKeysResponse(tmp);
          }
        }
      );
  }

  getAccessKeyInfo(key: string): void {
    this.setIsUpdating(true);
    this.akApi.getAccessKeyInfo(key)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: data => {
            const body = data as IDtoAccessKey;
            const tmp: RepositoryResponse<IDtoAccessKey> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'IDtoAccessKey is null' : 'IDtoAccessKey received',
                ]
              }
            }
            this.setIsUpdating(false);
            this.setGetAccessKeyInfoStateResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoAccessKey> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setGetAccessKeyInfoStateResponse(tmp);
          }
        }
      );
  }

  getAccessKeys(): void {
    this.setIsUpdating(true);
    this.akApi.getAccessKeys()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: data => {
            const body = data as IDtoAccessKey[];
            const tmp: RepositoryResponse<IDtoAccessKey[]> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'IDtoAccessKey[] is null' : 'IDtoAccessKey[] received',
                ]
              }
            }
            this.setIsUpdating(false);
            this.setGetAccessKeysStateResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoAccessKey[]> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setGetAccessKeysStateResponse(tmp);
          }
        }
      );
  }

  lockAccessKey(key: string): void {
    this.akApi.accessKeyLock(key)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: ['Validation Code sent']
            }
          }
          this.setIsUpdating(false);
          this.setLockAccessKeyResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLockAccessKeyResponse(tmp);
        }
      })
  }
}
