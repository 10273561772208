import {UserModel} from '../../core/state/user/models';
import {UserUpdateUseCase} from '../../core/use-cases/user';
import {UseCaseActionName} from '../../core/utils';
import {UserUpdateUseCaseApp} from './user-update-use-case-app.class';

export class UserUpdateUseCaseMock implements UserUpdateUseCase{
  dispose(): void {
  }



  public getName(): string {
    return UserUpdateUseCaseApp.Name;
  }

  execute(action: UseCaseActionName, params?: UserModel): void {
  }

  init(): void {
  }
}
