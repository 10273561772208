import { IDtoSurveyInstance } from '../../api/dtos/surveys/dto-survey-instance.interface';
import { Mapper } from '../../core/abstractions/repositories/mapper.class';
import { SurveyInstanceModel } from '../../core/state/survey/survey-instance-model.interface';

export class SurveyInstanceMapper extends Mapper<IDtoSurveyInstance, SurveyInstanceModel> {
  mapFrom(param: IDtoSurveyInstance): SurveyInstanceModel {
    const res: SurveyInstanceModel = {
      cultureName: param.CultureName,
      dbId: param.DbId,
      operatorId: param.OperatorId,
      systemName: param.SystemName,
      answers: [],
      certificate: param.Certificate,
      examinerUserId: param.ExaminerUserId,
      fillingTimestamp: param.FillingTimestamp,
      note: param.Note,
      state: param.State,
      surveyId: param.SurveyId,
      templateSummary: param.TemplateSummary,
      templateTitle: param.TemplateTitle,
    };

    param.Answers.forEach(a => {
      res.answers.push({
        answerId: a.AnswerId,
        availableAnswerId: a.AvailableAnswerId,
        boolValue: a.BoolValue,
        stringValue: a.StringValue,
        intValue: a.IntValue,
        questionId: a.QuestionId,
      });
    });

    return res;
  }

  mapTo(param: SurveyInstanceModel): IDtoSurveyInstance {
    const res: IDtoSurveyInstance = {
      Answers: [],
      Certificate: param.certificate,
      CultureName: param.cultureName,
      DbId: param.dbId,
      ExaminerUserId: param.examinerUserId,
      FillingTimestamp: param.fillingTimestamp,
      Note: param.note,
      OperatorId: param.operatorId,
      State: param.state,
      SurveyId: param.surveyId,
      SystemName: param.systemName,
      TemplateSummary: param.templateSummary,
      TemplateTitle: param.templateTitle,
    };

    param.answers.forEach(a => {
      res.Answers.push({
        AnswerId: a.answerId,
        AvailableAnswerId: a.availableAnswerId,
        BoolValue: a.boolValue,
        StringValue: a.stringValue,
        IntValue: a.intValue,
        QuestionId: a.questionId,
      });
    });

    return res;
  }

}
