import {Injectable} from "@angular/core";
import {TranslateConfig} from "../core/services/translate-config.class";

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigMock extends TranslateConfig{
  getCurrentLang(): string {
    return TranslateConfig.defaultLanguage;
  }

  setLanguage(setLang: string): void {
  }

  translate(key: string): string {
    return "";
  }

}
