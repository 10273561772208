import {Pipe, PipeTransform} from '@angular/core';
import {AccountStateModel} from '../state/account/account-state-model.interface';

@Pipe({name: 'displayUsername'})
export class DisplayUsernamePipe implements PipeTransform {
  transform(response: AccountStateModel|null|undefined): string {
    if(response === null || response === undefined){
      return '---';
    }

    if(response.username === null){
      return '-+-';
    }


    return `${response.username}`;
  }
}
