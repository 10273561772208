import {Localizable} from "../../utils";
import {UseCaseActivityDisplayMode} from "../../utils";
import {UseCaseStateName} from "../../utils";
import {UseCaseNotificationModel} from "./use-case-notification-model.interface";
import {MessageActivityLevel} from "../../utils/message-activity-level.enum";

export namespace UseCasesActions {
  export class Init {
    static readonly type = '[Use Cases Center] Use Cases Initialized';

    constructor(public useCaseName: string, public message: Localizable) {
    }
  }

  export class SetState {
    static readonly type = '[Use Cases Center] Use Cases Set State';

    constructor(
      public useCaseName: string,
      public targetState: UseCaseStateName,
      public message: Localizable,
      public header?: Localizable,
      public subHeader?: Localizable,
      public mode?: UseCaseActivityDisplayMode,
      public level?: MessageActivityLevel) {
    }
  }

  export class SetNotificationAsShown {
    static readonly type = '[Use Cases Center] Use Cases Set Notification as Shown';

    constructor(public notification: UseCaseNotificationModel) {
    }
  }

  export class Reset {
    static readonly type = '[Use Cases Center] Reset Actions and Notifications';
  }
}
