import {SearchEngineQuery} from "./search-engine-state-model.interface";

export class SearchQueryUtils {
  public static isQueryEmpty(query: SearchEngineQuery | null): boolean {
    if (query === null) {
      return true;
    }

    const emptyText = query.queryText.length === 0;
    // console.log('query empty Text:' + emptyText);

    const noDtoSelected = query.withinDto.length === 0;
    // console.log('query empty dto:'+ query.withinDto.length);
    // console.log('query dto'+ query.withinDto[0])

    return emptyText && noDtoSelected;

  }
}
