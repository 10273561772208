import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilesApiService {
  constructor(private httpClient: HttpClient) {
  }

  public getFileByName(name: string) {
    const url = `${environment.webApiV1}service/get-file?filename=${name}`;
    return this.httpClient.get(url, {observe: 'events', responseType: 'blob', reportProgress: true});
  }
}
