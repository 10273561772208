import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragAndDropDirective} from "./drag-and-drop.directive";
import { TrimDirective } from './trim.directive';
import { AutoResizeDirective } from './auto-resize.directive';

@NgModule({
  declarations: [
    DragAndDropDirective,
    TrimDirective,
    AutoResizeDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DragAndDropDirective,
    TrimDirective,
    AutoResizeDirective
  ]
})
export class DirectivesModule {
}
