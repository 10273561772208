import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import { IDtoSurveyInstance, IDtoSurveyTemplate } from '../dtos/surveys';

@Injectable({
  providedIn: 'root'
})
export class SurveyApiService {
  constructor(private httpClient: HttpClient) {
  }

  public loadSurvey(id: number, lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/survey/load/${id}`);
  }

  public loadAllAvailable(lang: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/survey/available-surveys`);
  }

  public save(lang: string, template: IDtoSurveyTemplate) {
    return this.httpClient.post(`${environment.webApiV1}${lang}/survey/save`, template);
  }

  public saveFilled(lang: string, survey: IDtoSurveyInstance) {
    return this.httpClient.post(`${environment.webApiV1}${lang}/survey/save-filled-survey`, survey);
  }

  public loadFilled(id: number, lang: string, loadAnswers: boolean) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/survey/load-filled-survey/${id}/${loadAnswers}/`);
  }

  public loadAllFilled(lang: string, loadAnswers: boolean, status: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/survey/load-filled-surveys/${loadAnswers}/${status}/`);
  }

  public setState(id: number, lang: string, stateToAssign: string) {
    return this.httpClient.get(`${environment.webApiV1}${lang}/survey/set-state-filled-survey?instanceId=${id}&stateToAssign=${stateToAssign}`);
  }
}
