export interface UserStripeInfoModel {
  creditCardLastDigits: string;
  creditCardDueDate: Date;
  subscription: StripeSubscriptionModel;
  planInfo: StripeInfoModel;
  discounts: StripeInfoModel[];
  invoices: StripeInvoicesModel;
}

export interface StripeSubscriptionModel {
  status: StripeSubscriptionStatus;
  isCancelAtPeriodEnd: boolean;
  start: Date;
  stop: Date;
}

export interface StripeInfoModel {
  description: string;
  link: string;
}

export interface StripeInvoicesModel {
  InvoicePreview: StripeInvoiceModel;
  IsPreviewEstimated: boolean;
  InvoicesHistory: StripeInvoiceModel[];
}

export interface StripeInvoiceModel {
  UrlPdfFile: string;
  Total: number;
  IsPaid: boolean;
  NextPayment: Date | null;
  Created: Date | null;
}

export enum StripeSubscriptionStatus {
  Trialing,
  Active,
  PastDue,
  Canceled,
  Unpaid,
  Incomplete,
  IncompleteExpired,
  Paused,
  NotYetCreated
}
