import {Observable, ReplaySubject} from "rxjs";
import {IDtoAccessKey} from "../../api/dtos/user/dto-access-key.interface";
import {BaseRepository} from "../abstractions/repositories/base-repository.class";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../abstractions/repositories/repository-response.interface";

export abstract class AccessKeyRepository extends BaseRepository {
  //#region Get Access Keys
  public abstract getAccessKeys(): void;

  protected getAccessKeysStateResponse = new ReplaySubject<RepositoryResponse<IDtoAccessKey[]>>()

  protected setGetAccessKeysStateResponse(responseModel: RepositoryResponse<IDtoAccessKey[]>): void {
    this.getAccessKeysStateResponse.next(responseModel);
  }

  public getAccessKeysStateResponse$(): Observable<RepositoryResponse<IDtoAccessKey[]>> {
    return this.getAccessKeysStateResponse.asObservable();
  }

  //#endregion


  //#region Lock access key
  public abstract lockAccessKey(key: string): void;

  protected lockAccessKeyResponse = new ReplaySubject<RepositoryResponseGeneric>()

  protected setLockAccessKeyResponse(responseModel: RepositoryResponseGeneric): void {
    this.lockAccessKeyResponse.next(responseModel);
  }

  public lockAccessKeyResponse$(): Observable<RepositoryResponseGeneric> {
    return this.lockAccessKeyResponse.asObservable();
  }

  //#endregion

  //#region get Access Key Info
  public abstract getAccessKeyInfo(key: string): void;

  protected getAccessKeyInfoStateResponse = new ReplaySubject<RepositoryResponse<IDtoAccessKey>>()

  protected setGetAccessKeyInfoStateResponse(responseModel: RepositoryResponse<IDtoAccessKey>): void {
    this.getAccessKeyInfoStateResponse.next(responseModel);
  }

  public getAccessKeyInfoResponse$(): Observable<RepositoryResponse<IDtoAccessKey>> {
    return this.getAccessKeyInfoStateResponse.asObservable();
  }

  //#endregion


  //#region Generate Access Keys
  public abstract generateAccessKeys(friendCode: number, amount: number, discountDays: number, productionLot: string, activableToDay: number, activableToMonth: number, activableToYear: number): void;

  protected generateAccessKeysResponse = new ReplaySubject<RepositoryResponse<IDtoAccessKey[]>>()

  protected setGenerateAccessKeysResponse(responseModel: RepositoryResponse<IDtoAccessKey[]>): void {
    this.generateAccessKeysResponse.next(responseModel);
  }

  public generateAccessKeysResponse$(): Observable<RepositoryResponse<IDtoAccessKey[]>> {
    return this.generateAccessKeysResponse.asObservable();
  }

  //#endregion
}
