export enum lKinds {
  'None' = -1,
  'Primary',
  'Secondary',
  'Extraordinary1st',
  'Extraordinary 2nd'
}


export enum lKinds2nd {
  'None' = -1,
  'Luo',
  'TendinoMuscolar',
  'Divergent'
}

export enum lCharacters {
  'None' = -1,
  'Yin',
  'Yang'
}

export enum lElements {
  'None' = -1,
  'Wood',
  'Imperial Fire',
  'Ministerial Fire',
  'Earth',
  'Metal',
  'Water',
  'Air'
}

export enum lEnergyLevels {
  'None' = -1,
  'Tai Yang',
  'Yang Ming',
  'Shao Yang',
  'Tai Yin',
  'Shao Yin',
  'Jue Yin'
}

export enum lLimbs {
  'None' = -1,
  'Arm',
  'Leg'
}

export enum lTime {
  'None' = -1,
  '1-3',
  '3-5',
  '5-7',
  '7-9',
  '9-11',
  '11-13',
  '13-15',
  '15-17',
  '17-19',
  '19-21',
  '21-23',
  '23-1'
}

export enum lIsDeep {
  'Yes',
  'No'
}

export enum lClientPosition {
  // 'None',
  'Supine' = 1,
  'Prone',
  'Lateral',
  'Sitting',
  'Standing',
  'TL Test'
}

