import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  IAuthLoginRequestDto,
  IChangePasswordRequestDto,
  IConfirmPasswordResetDto,
  ISendPasswordResetEmailDto,
} from '../dtos/security';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private httpClient: HttpClient) {
  }

  public login(dto: IAuthLoginRequestDto) {
    const body =
      'userName=' + encodeURIComponent(dto.username) +
      '&password=' + encodeURIComponent(dto.password) +
      '&captcha_token=' + encodeURIComponent(dto.captchaToken) +
      '&grant_type=password';

    // https://stackoverflow.com/questions/29246908/c-sharp-unsupported-grant-type-when-calling-web-api
    return this.httpClient.post(`${environment.webApi}token`, body, {
      headers: new HttpHeaders().set('Content-Type', 'x-www-form-urlencoded'),
      //observe: 'body'
    });
  }

  public logout() {
    return this.httpClient.post(`${environment.webApi}account/logout`, '');
  }

  public changePassword(dto: IChangePasswordRequestDto) {
    const body = {
      ConfirmPassword: dto.confirmPassword,
      NewPassword: dto.newPassword,
      OldPassword: dto.oldPassword,
    };
    return this.httpClient.post(`${environment.webApi}account/change-password`, JSON.stringify(body));
  }

  public sendResetPasswordEmail(dto: ISendPasswordResetEmailDto) {
    return this.httpClient.post(`${environment.webApi}account/send-reset-password-email`, JSON.stringify(dto));
  }

  public confirmResetPassword(dto: IConfirmPasswordResetDto) {
    return this.httpClient.post(`${environment.webApi}account/confirm-reset-password`, JSON.stringify(dto));
  }
}
