import {SurveyInstanceModel} from "./survey-instance-model.interface";
import {SurveyTemplateModel} from "./survey-template-model.interface";

export namespace SurveyActions {
  export class SurveyTemplateLoad {
    static readonly type = '[Survey State] Load Survey Template';

    constructor(public model: SurveyTemplateModel) {
    }

  }

  export class SurveyInstancesLoad {
    static readonly type = '[Survey State] Load Survey Instance';

    constructor(public models: SurveyInstanceModel[]) {
    }
  }

  export class SurveyInstanceSave {
    static readonly type = '[Survey State] Save Survey Instance';

    constructor(public model: SurveyInstanceModel | null, public pendingSave: boolean) {
    }
  }

  export class SurveyClearState {
    static readonly type = '[Survey State] Clear Survey State';
  }
}
