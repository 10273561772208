import {HttpErrorResponse} from "@angular/common/http";
import {takeUntil} from "rxjs/operators";
import {IDtoSurveyInstance} from "../../api/dtos/surveys/dto-survey-instance.interface";
import {IDtoSurveyTemplate} from "../../api/dtos/surveys/dto-survey-template.interface";
import {SurveyApiService} from "../../api/services/survey-api.service";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../../core/abstractions/repositories/repository-response.interface";
import {SurveyRepository} from "../../core/repositories/survey-repository.class";
import {HttpUtils} from "../../core/utils/http-utils.class";

export class SurveyRepositoryApi extends SurveyRepository {
  constructor(private surveyApi: SurveyApiService) {
    super();
  }

  loadSurvey(id: number, lang: string): void {
    this.surveyApi.loadSurvey(id, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoSurveyTemplate;
          const tmp: RepositoryResponse<IDtoSurveyTemplate> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SurveyTemplateDto is null' : 'SurveyTemplateDto received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadSurveyResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoSurveyTemplate> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadSurveyResponse(tmp);
        }
      });
  }

  loadAllSurveys(lang: string): void {
    this.surveyApi.loadAllAvailable(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoSurveyTemplate[];
          const tmp: RepositoryResponse<IDtoSurveyTemplate[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SurveyTemplateDto[] is null' : 'SurveyTemplateDto[] received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadAllSurveysResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoSurveyTemplate[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadAllSurveysResponse(tmp);
        }
      });
  }


  saveSurvey(lang: string, template: IDtoSurveyTemplate): void {
    this.surveyApi.save(lang, template)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: () => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 0,
                messages: [
                  'Survey template saved'
                ]
              }
            }
            this.setIsUpdating(false);
            this.setSaveSurveyResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setSaveSurveyResponse(tmp);
          }
        }
      );
  }

  saveSurveyFilled(lang: string, template: IDtoSurveyInstance): void {
    this.surveyApi.saveFilled(lang, template)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: () => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 0,
                messages: [
                  'Survey filled saved'
                ]
              }
            }
            this.setIsUpdating(false);
            this.setSaveSurveyFilledResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setSaveSurveyFilledResponse(tmp);
          }
        }
      );
  }

  loadFilledSurvey(id: number, lang: string, loadAnswers: boolean): void {
    this.surveyApi.loadFilled(id, lang, loadAnswers)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoSurveyInstance;
          const tmp: RepositoryResponse<IDtoSurveyInstance> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SurveyInstanceDto is null' : 'SurveyInstanceDto received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadFilledSurveyResponse(tmp);
        },
        error: err => {
          let e = err as HttpErrorResponse;
          const tmp: RepositoryResponse<IDtoSurveyInstance> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadFilledSurveyResponse(tmp);
        }
      });
  }

  loadAllFilledSurveys(lang: string, loadAnswers: boolean, status: string): void {
    this.surveyApi.loadAllFilled(lang, loadAnswers, status)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoSurveyInstance[];
          const tmp: RepositoryResponse<IDtoSurveyInstance[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'SurveyInstanceDto[] is null' : 'SurveyInstanceDto[] received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadAllFilledSurveysResponse(tmp);
        },
        error: err => {
          let e = err as HttpErrorResponse;
          const tmp: RepositoryResponse<IDtoSurveyInstance[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadAllFilledSurveysResponse(tmp);
        }
      });
  }

  setSurveyState(id: number, lang: string, stateToAssign: string): void {
    this.surveyApi.setState(id, lang, stateToAssign)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: () => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 0,
                messages: [
                  'Survey state set'
                ]
              }
            }
            this.setIsUpdating(false);
            this.setSetSurveyStateResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setSetSurveyStateResponse(tmp);
          }
        }
      );
  }
}
