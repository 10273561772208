import {IDtoUokLinksDefinition} from "../../api/dtos/uok-link/idto-uok-links-definition.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {UokLinksDefinitionModel} from "../../core/state/detail/models/link/uok-links-definition-model.interface";

export class UokLinksDefinitionMapper extends Mapper<IDtoUokLinksDefinition, UokLinksDefinitionModel> {
  mapFrom(param: IDtoUokLinksDefinition): UokLinksDefinitionModel {
    const res: UokLinksDefinitionModel = {
      cultureName: param.CultureName,
      dbId: param.DbId,
      operatorId: param.OperatorId,
      systemName: param.SystemName,
      linksDefinition: [],
      linksReferredDefinition: []
    };

    param.LinksDefinition.forEach(l => {
      res.linksDefinition.push({
        allowedUokDtoNames: l.AllowedUokDtoNames,
        leftUokCaption: l.LeftUokCaption,
        leftUokDtoName: l.LeftUokDtoName,
        linkSystemName: l.LinkSystemName,
        uokLeftHelpText: l.UokLeftHelpText
      })
    })

    param.LinksReferredDefinition.forEach(l => {
      res.linksReferredDefinition.push({
        caption: l.Caption,
        linkSystemName: l.LinkSystemName
      })
    })

    return res;
  }

  mapTo(param: UokLinksDefinitionModel): IDtoUokLinksDefinition {
    const res: IDtoUokLinksDefinition = {
      CultureName: param.cultureName,
      DbId: param.dbId,
      OperatorId: param.operatorId,
      SystemName: param.systemName,
      LinksDefinition: [],
      LinksReferredDefinition: []
    };

    param.linksDefinition.forEach(l => {
      res.LinksDefinition.push({
        AllowedUokDtoNames: l.allowedUokDtoNames,
        LeftUokCaption: l.leftUokCaption,
        LeftUokDtoName: l.leftUokDtoName,
        LinkSystemName: l.linkSystemName,
        UokLeftHelpText: l.uokLeftHelpText
      })
    })

    param.linksReferredDefinition.forEach(l => {
      res.LinksReferredDefinition.push({
        Caption: l.caption,
        LinkSystemName: l.linkSystemName
      })
    })

    return res;
  }

}
