export enum UseCaseStateName {
  initialized,
  edit,
  working,
  success,
  failure,
  disposed
}

export enum UseCaseActionName {
  userEdit,
  userSave,
  userDiscard,
  run
}
