import {UseCaseActionName} from '../../core/utils';
import { StripeUnsubscribeUseCase } from '../../core/use-cases/stripe';

export class StripeUnsubscribeUseCaseMock implements StripeUnsubscribeUseCase{
  dispose(): void {
  }

  getName(): string {
    return "";
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }
}
