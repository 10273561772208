import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {DraftInfoModel} from "./draft-info-model.interface";
import {DraftStateActions} from "./draft-state-actions";
import {DraftStateModel} from "./draft-state-model.interface";
import {IDtoTagInfo} from "../../../api/dtos/uok";
import {UploadProgress} from "../../../services/vimeo-uploader.service";

@State<DraftStateModel>({
  name: 'draft',
  defaults: {
    currentDraftVideoUpload: [],
    expiredDrafts: [],
    rejectDrafts: [],
    submittedDrafts: [],
    workingDrafts: [],
    tags: []
  }
})
@Injectable()
export class DraftState {
  constructor() {
  }

  @Selector()
  static tagsInfo(state: DraftStateModel): IDtoTagInfo[] {
    return state.tags;
  }

  // @Selector()
  // static currentDraftDto(state: DraftStateModel): DraftDto | null {
  //   return state.currentDraftDto;
  // }

  @Selector()
  static expiredDraftsInfo(state: DraftStateModel): DraftInfoModel[] {
    return state.expiredDrafts;
  }

  @Selector()
  static submittedDraftsInfo(state: DraftStateModel): DraftInfoModel[] {
    return state.submittedDrafts;
  }

  @Selector()
  static rejectedDraftsInfo(state: DraftStateModel): DraftInfoModel[] {
    return state.rejectDrafts;
  }

  @Selector()
  static workingDraftsInfo(state: DraftStateModel): DraftInfoModel[] {
    return state.workingDrafts;
  }

  @Selector()
  static videoUploadProgress(state: DraftStateModel): UploadProgress[] {
    return state.currentDraftVideoUpload;
  }

  // @Selector()
  // static videoUploadProgressByUri(state: DraftStateModel): (uri: string) => UploadProgress | undefined {
  //   return (uri: string) => {
  //     return state.currentDraftVideoUpload.find((obj: UploadProgress) => {
  //       if (obj && obj.uploadUri === uri) {
  //         return obj;
  //       }
  //       return undefined;
  //     });
  //   }
  // }

  @Action(DraftStateActions.LoadExpiredDraftsInfo)
  loadExpiredDraftsInfo(ctx: StateContext<DraftStateModel>, action: DraftStateActions.LoadExpiredDraftsInfo) {
    ctx.patchState({
      expiredDrafts: action.expiredDrafts
    })
  }

  @Action(DraftStateActions.LoadRejectedDraftsInfo)
  loadRejectedDraftsInfo(ctx: StateContext<DraftStateModel>, action: DraftStateActions.LoadRejectedDraftsInfo) {
    ctx.patchState({
      rejectDrafts: action.rejectedDrafts
    })
  }

  @Action(DraftStateActions.LoadSubmittedDraftsInfo)
  loadSubmittedDraftsInfo(ctx: StateContext<DraftStateModel>, action: DraftStateActions.LoadSubmittedDraftsInfo) {
    ctx.patchState({
      submittedDrafts: action.submittedDrafts
    })
  }

  @Action(DraftStateActions.LoadWorkingDraftsInfo)
  loadWorkingDraftsInfo(ctx: StateContext<DraftStateModel>, action: DraftStateActions.LoadWorkingDraftsInfo) {
    ctx.patchState({
      workingDrafts: action.workingDrafts
    })
  }

  @Action(DraftStateActions.LoadDraftTagsInfo)
  loadDraftTagsInfo(ctx: StateContext<DraftStateModel>, action: DraftStateActions.LoadDraftTagsInfo) {
    ctx.patchState({
      tags: action.tags
    })
  }

  @Action(DraftStateActions.UpdateVideoUpload)
  updateVideoUpload(ctx: StateContext<DraftStateModel>, action: DraftStateActions.UpdateVideoUpload) {
    let progress = ctx.getState().currentDraftVideoUpload ?? [];
    const toUpdate = progress.find(x => x.uploadUri === action.status.uploadUri);

    if (progress.length >= 10) {
      progress = progress.splice(0, 1);
    }

    if (toUpdate) {
      ctx.patchState({
        currentDraftVideoUpload: ctx.getState().currentDraftVideoUpload.map(p => {
          if (p.uploadUri === action.status.uploadUri) {
            return action.status;
          }
          return p;
        })
      })
    } else {
      progress.push(action.status);
      ctx.patchState({
        currentDraftVideoUpload: progress
      })
    }
  }

  @Action(DraftStateActions.ClearVideoUploads)
  clearVideoUploads(ctx: StateContext<DraftStateModel>) {
    ctx.patchState({
      currentDraftVideoUpload: []
    })
  }

  // @Action(DraftStateActions.InitCurrentDraftDto)
  // initCurrentDraftDto(ctx: StateContext<DraftStateModel>, action: DraftStateActions.InitCurrentDraftDto) {
  //   ctx.patchState({
  //     currentDraftDto: action.draft
  //   })
  // }
  //
  // @Action(DraftStateActions.InitCurrentDraftForm)
  // initCurrentDraft(ctx: StateContext<DraftStateModel>, action: DraftStateActions.InitCurrentDraftForm) {
  //   if (action.draft) {
  //     ctx.patchState({
  //       currentDraftForm: {
  //         model: action.draft,
  //         dirty: true,
  //         status: '',
  //         errors: {}
  //       },
  //     })
  //   } else {
  //     ctx.patchState({
  //       currentDraftForm: {
  //         model: undefined,
  //         dirty: false,
  //         status: '',
  //         errors: {}
  //       },
  //     })
  //   }
  // }
  //
  // @Action(DraftStateActions.ResetDrafts)
  // resetDrafts(ctx: StateContext<DraftStateModel>) {
  //   ctx.setState({
  //     currentDraftForm: {
  //       model: undefined,
  //       dirty: false,
  //       status: '',
  //       errors: {}
  //     },
  //     currentDraftDto: null,
  //     currentDraftVideoUpload: [],
  //     expiredDrafts: [],
  //     rejectDrafts: [],
  //     submittedDrafts: [],
  //     workingDrafts: [],
  //     tags: []
  //   })
  // }
}
