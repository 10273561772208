import {AccountSignOutUseCase} from '../../core/use-cases/account';
import {UseCaseActionName} from '../../core/utils';
import {AccountSignOutUseCaseApp} from './account-sign-out-use-case-app.class';

export class AccountSignOutUseCaseMock implements AccountSignOutUseCase {
  constructor() {
  }

  dispose(): void {
  }

  public getName(): string {
    return AccountSignOutUseCaseApp.Name;
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }
}
