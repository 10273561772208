import {takeUntil} from "rxjs/operators";
import {IDtoTagInfo, IDtoUokDraftInfo, IdtoUokDraftPublish} from "../../api/dtos/uok";
import {DraftApiService} from "../../api/services";
import {RepositoryResponse, RepositoryResponseGeneric} from "../../core/abstractions/repositories";
import {DraftRepository} from "../../core/repositories";
import {HttpUtils} from "../../core/utils";
import {IDtoDraft} from '../../api/dtos/uok/dto-draft.interface';

export class DraftRepositoryApi extends DraftRepository {
  constructor(private draftApi: DraftApiService) {
    super();
  }

  public override saveDraft(lang: string, dto: IDtoDraft): void {
    this.setIsUpdating(true);
    this.draftApi.saveDraft(dto, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoDraft;
          const tmp: RepositoryResponse<IDtoDraft> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'DraftDtoInterface is null' : 'DraftDtoInterface received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setSaveDraft(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoDraft> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setSaveDraft(tmp);
        }
      });
  }

  public override getDraft(lang: string, draftId: number): void {
    this.setIsUpdating(true);
    this.draftApi.getDraft(draftId, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoDraft;
          const tmp: RepositoryResponse<IDtoDraft> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'DraftDtoInterface is null' : 'DraftDtoInterface received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setGetDraft(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoDraft> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setGetDraft(tmp);
        }
      });
  }

  public override createDraftFromUok(lang: string, uokId: number): void {
    this.setIsUpdating(true);
    this.draftApi.createDraftFromUok(uokId, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoDraft;
          const tmp: RepositoryResponse<IDtoDraft> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'DraftDtoInterface is null' : 'DraftDtoInterface received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setCreateDraftFromUok(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoDraft> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setCreateDraftFromUok(tmp);
        }
      });
  }

  getDraftsInfoByState(lang: string, stateAsNumber: number): void {
    this.setIsUpdating(true);
    this.draftApi.getDraftsByState(stateAsNumber, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoUokDraftInfo[];
          const tmp: RepositoryResponse<IDtoUokDraftInfo[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'IDtoUokDraftInfo[] is null' : 'IDtoUokDraftInfo[] received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setDraftsInfoByState(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoUokDraftInfo[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setDraftsInfoByState(tmp);
        }
      });
  }

  public override getTags(lang: string): void {
    this.setIsUpdating(true);
    this.draftApi.getTags(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as IDtoTagInfo[];
          const tmp: RepositoryResponse<IDtoTagInfo[]> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'DraftDtoInterface is null' : 'DraftDtoInterface received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setGetTags(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<IDtoTagInfo[]> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setGetTags(tmp);
        }
      });
  }

  public override submit(draftId: number): void {
    this.setIsUpdating(true);
    this.draftApi.submit(draftId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                `Draft ${draftId} submitted`
              ]
            }
          }
          this.setIsUpdating(false);
          this.setSubmitResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setSubmitResponse(tmp);
        }
      });
  }

  public override delete(draftId: number): void {
    this.setIsUpdating(true);
    this.draftApi.delete(draftId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                `Draft ${draftId} deleted`
              ]
            }
          }
          this.setIsUpdating(false);
          this.setDeleteResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setDeleteResponse(tmp);
        }
      });
  }

  public override publish(model: IdtoUokDraftPublish): void {
    this.setIsUpdating(true);
    this.draftApi.publish(model)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                `Draft ${model.DbId} published`
              ]
            }
          }
          this.setIsUpdating(false);
          this.setPublishResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setPublishResponse(tmp);
        }
      });
  }

  public override reject(draftId: number, message: string): void {
    this.setIsUpdating(true);
    this.draftApi.reject(draftId, message)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                `Draft ${draftId} rejected`
              ]
            }
          }
          this.setIsUpdating(false);
          this.setRejectResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setRejectResponse(tmp);
        }
      });
  }
}
