import {BookmarkDto} from "../../api/dtos/uok-detail/bookmark-dto.interface";
import {UokDetailRepository} from "../../core/repositories/uok-detail-repository.class";

export class UokDetailRepositoryMock extends UokDetailRepository {
  loadUokContentInfo(uokId: number, lang: string): void {
  }

  loadUokHeader(uokId: number, lang: string): void {
  }

  loadUokLockInfo(uokId: number, lang: string): void {
  }

  loadUokUserInfo(uokId: number, lang: string): void {
  }

  loadUokBookmarkState(uokId: number): void {
  }

  setUokBookmarkState(dto: BookmarkDto): void {
  }
}
