import { environment } from '../../../environments/environment';
import { ServiceLocator } from '../../core/utils';
import { Store } from '@ngxs/store';
import { StripeSubscriptionCreateUseCaseApp } from './stripe-subscription-create-use-case-app.class';
import {
  StripePaymentIntentConfirmUseCase,
  StripeSubscriptionCreateUseCase,
  StripeSetupIntentConfirmUseCase,
  StripeCustomerGetUseCase,
  StripeSetupIntentGetUseCase,
  StripeLoadInfoUseCase,
  StripeResubscribeUseCase,
  StripeUnsubscribeUseCase,
} from '../../core/use-cases/stripe';
import { StripeSubscriptionCreateUseCaseMock } from './stripe-subscription-create-use-case-mock.class';
import { StripePaymentIntentConfirmUseCaseApp } from './stripe-payment-confirm-use-case-app.class';
import { StripePaymentIntentConfirmUseCaseMock } from './stripe-payment-confirm-use-case-mock.class';
import { StripeCustomerGetUseCaseMock } from './stripe-customer-get-use-case-mock.class';
import { StripeCustomerGetUseCaseApp } from './stripe-customer-get-use-case-app.class';
import { StripeSetupIntentConfirmUseCaseApp } from './stripe-setup-intent-confirm-use-case-app.class';
import { StripeSetupIntentConfirmUseCaseMock } from './stripe-setup-intent-confirm-use-case-mock.class';
import { StripeSetupIntentGetUseCaseApp } from './stripe-setup-intent-get-use-case-app.class';
import { StripeSetupIntentGetUseCaseMock } from './stripe-setup-intent-get-use-case-mock.class';
import { StripeLoadInfoUseCaseApp } from './stripe-load-info-use-case-app.class';
import { StripeLoadInfoUseCaseMock } from './stripe-load-info-use-case-mock.class';
import { StripeResubscribeUseCaseMock } from './stripe-resubscribe-use-case-mock.class';
import { StripeResubscribeUseCaseApp } from './stripe-resubscribe-use-case-app.class';
import { StripeUnsubscribeUseCaseMock } from './stripe-unsubscribe-use-case-mock.class';
import { StripeUnsubscribeUseCaseApp } from './stripe-unsubscribe-use-case-app.class';

export class StripeUseCaseFactory {
  private static readonly App = 'app';


  public static GetCreateSubscription(): StripeSubscriptionCreateUseCase {
    if (environment.useCases.stripe.createSubscription === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeSubscriptionCreateUseCaseApp(store);
    }
    return new StripeSubscriptionCreateUseCaseMock();
  }

  public static GetPaymentIntentConfirm(): StripePaymentIntentConfirmUseCase {
    if (environment.useCases.stripe.confirmPayment === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripePaymentIntentConfirmUseCaseApp(store);
    }
    return new StripePaymentIntentConfirmUseCaseMock();
  }

  public static GetSetupIntentGet(): StripeSetupIntentGetUseCase {
    if (environment.useCases.stripe.getSetupIntent === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeSetupIntentGetUseCaseApp(store);
    }
    return new StripeSetupIntentGetUseCaseMock();
  }

  public static GetSetupIntentConfirm(): StripeSetupIntentConfirmUseCase {
    if (environment.useCases.stripe.confirmSetupIntent === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeSetupIntentConfirmUseCaseApp(store);
    }
    return new StripeSetupIntentConfirmUseCaseMock();
  }

  public static GetCustomer(): StripeCustomerGetUseCase {
    if (environment.useCases.stripe.getCustomer === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeCustomerGetUseCaseApp(store);
    }
    return new StripeCustomerGetUseCaseMock();
  }

  public static LoadStripeInfo(): StripeLoadInfoUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeLoadInfoUseCaseApp(store);
    }
    return new StripeLoadInfoUseCaseMock();
  }

  public static UserUnsubscribe(): StripeUnsubscribeUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeUnsubscribeUseCaseApp(store);
    }
    return new StripeUnsubscribeUseCaseMock();
  }

  public static UserResubscribe(): StripeResubscribeUseCase {
    if (environment.useCases.auth.login === this.App) {
      const store = ServiceLocator.injector.get(Store);
      return new StripeResubscribeUseCaseApp(store);
    }
    return new StripeResubscribeUseCaseMock();
  }

}
