import { UseCaseActionName } from "src/app/core/utils";
import {StripeSubscriptionCreateUseCase} from "../../core/use-cases/stripe";
import { StripeSubscriptionCreateModel } from "src/app/core/state/stripe";

export class StripeSubscriptionCreateUseCaseMock implements StripeSubscriptionCreateUseCase {
  init(): void {
      throw new Error("Method not implemented.");
  }
  execute(action: UseCaseActionName, params?: StripeSubscriptionCreateModel | undefined): void {
      throw new Error("Method not implemented.");
  }
  getName(): string {
      throw new Error("Method not implemented.");
  }
  dispose(): void {
      throw new Error("Method not implemented.");
  }

  public static Name = 'StripeCreateSubscriptionMock';


}
