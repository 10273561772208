import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { StripeRepository } from '../../core/repositories';
import { DtoUtils, UseCaseStateName } from '../../core/utils';
import { RepositoryFactory } from '../../data';
import { UseCaseAppBase } from '../use-case-app-base.class';
import { StripeUnsubscribeUseCase } from '../../core/use-cases/stripe';

export class StripeUnsubscribeUseCaseApp extends UseCaseAppBase<null> implements StripeUnsubscribeUseCase {
  public static readonly Name: string = 'UserUnsubscribe';
  private userRepository: StripeRepository = RepositoryFactory.GetStripeRepository();

  constructor(store: Store) {
    super(store);

    this.userRepository.userUnsubscribeResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.info?.level === 0) {
            this.dispatchSetStateWithToast(UseCaseStateName.success, 'UseCase.UserUnsubscribe.Success');
          } else {
            this.dispatchSetFailureWithAlert(
              { localizationKey: 'UseCase.UserUnsubscribe.Failure.Message', params: DtoUtils.getMessage(response) },
              { localizationKey: 'UseCase.UserUnsubscribe.Failure.Header' },
              { localizationKey: 'UseCase.UserUnsubscribe.Failure.SubHeader' });
          }
        },
      });
  }

  override getName(): string {
    return StripeUnsubscribeUseCaseApp.Name;
  }

  override onExecuteRun(params?: null): void {
    this.dispatchSetStateWithToast(UseCaseStateName.working, 'UseCase.UserUnsubscribe.Working');
    this.userRepository.userUnsubscribe();
  }

  override init(): void {
    this.dispatchInitWithMessage('UseCase.UserUnsubscribe.Initialized');
  }

  override onDispose() {
    this.userRepository.dispose();
  }
}
