import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'translatePlural',
  pure: false
})
export class TranslatePluralPipe implements PipeTransform {

  transform(key: string, number: number): string {
    return `${key}.${number == 0 ? 'none' : number == 1 ? 'singular' : 'plural'}`;
  }
}
