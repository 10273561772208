import {UserCashInfoModel} from "../models/user-cash-info-model.interface";

export namespace UserCashActions {
  export class Succeded {
    static readonly type = '[User Cash] Cash Info';

    constructor(public info: UserCashInfoModel) {
    }
  }
}
