import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductApiService {
  constructor(private httpClient: HttpClient) {
  }

  public load(lang: string) {
    return this.httpClient.get(`${environment.webApiV1}/product/${lang}/load`);
  }

  public loadAdmin(lang: string, userId: number) {
    return this.httpClient.get(`${environment.webApiV1}/product/${lang}/load-admin/${userId}`);
  }

  public activate(productId: number, userEmail: string, userName: string, userSurname: string) {
    return this.httpClient.post(`${environment.webApiV1}/product/activate?productId=${productId}&userEmail=${userEmail}&userName=${userName}&userSurname=${userSurname}`, '');
  }
}
