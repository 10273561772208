import {IDtoAccessKey} from "../../../api/dtos/user/dto-access-key.interface";

export namespace SignupStateActions {
  export class InitWizard {
    static readonly type = '[Signup] Init to empty values';

    constructor() {
    }
  }

  export class NextStepClicked {
    static readonly type = '[Signup] Next Step Clicked';

    constructor() {
    }
  }

  export class PreviousStepClicked {
    static readonly type = '[Signup] Previous Step Clicked';

    constructor() {
    }
  }

  export class SetIsUpdating {
    static readonly type = '[Signup] Set IsUpdating flag';

    constructor(public isUpdating: boolean) {
    }
  }

  export class SetCredentialsAndMoveNext {
    static readonly type = '[Signup] Set Credentials and move next';

    constructor(
      public name: string,
      public surname: string,
      public username: string,
      public password: string,
      public friendCode: number,
      public accessKeyData: IDtoAccessKey | null) {
    }
  }

  export class SetEmailValidationCodeAndMoveNext {
    static readonly type = '[Signup] Set Validation Code and move next';

    constructor(public code: string) {
    }
  }

  export class SelectPlanAndMoveNext {
    static readonly type = '[Signup] Select Plan and move next';

    constructor(public proChecked: boolean) {
    }
  }

  export class SelectPaymentAndMoveNext {
    static readonly type = '[Signup] Select Payment method and move next';

    constructor(public plan: string) {
    }
  }

  export class MoveToErrorStep {
    static readonly type = '[Signup] Move to error step';

    constructor(public error: string | null) {
    }
  }

  export class MoveToWelcomeStep{
    static readonly type = '[Signup] Move to Welcome step';
    constructor() {
    }
  }

}
