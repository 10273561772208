import {Observable, ReplaySubject} from "rxjs";
import {ISearchEngineItemDto} from "../../api/dtos/searchengine";
import {BaseRepository, RepositoryResponse} from "../abstractions/repositories";
import {ISearchEnginePaginatedResultDto} from "../../api/dtos/searchengine/search-engine-paginated-result-dto.interface";

export abstract class SearchRepository extends BaseRepository {
  //#region Get By Tags
  public abstract getByTags(lang: string, queryText: string, withinDto: string[], maxItems: number, page: number): void

  protected getByTagsResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEnginePaginatedResultDto>>();

  public getByTagsResponse$(): Observable<RepositoryResponse<ISearchEnginePaginatedResultDto>> {
    return this.getByTagsResponseSubject.asObservable();
  }

  public setGetByTagsResponse(tmp: RepositoryResponse<ISearchEnginePaginatedResultDto>) {
    this.getByTagsResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get By Ids
  public abstract getByTagIds(lang: string, includeIds: number[], maxItems: number): void

  protected getByTagIdsResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getByTagIdsResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getByTagIdsResponseSubject.asObservable();
  }

  public setGetByTagIdsResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getByTagIdsResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get By Name
  public abstract getByName(lang: string, queryText: string, withinDto: string[], maxItems: number): void

  protected getByNameResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getByNameResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getByNameResponseSubject.asObservable();
  }

  public setGetByNameResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getByNameResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get By Name Starts With
  public abstract getByNameStartsWith(lang: string, queryText: string, withinDto: string[], maxItems: number): void

  protected getByNameStartsWithResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getByNameStartsWithResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getByNameStartsWithResponseSubject.asObservable();
  }

  public setGetByNameStartsWithResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getByNameStartsWithResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Signaled
  public abstract getSignaled(lang: string): void

  protected getSignaledResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getSignaledResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getSignaledResponseSubject.asObservable();
  }

  public setGetSignaledResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getSignaledResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Disabled
  public abstract getDisabled(lang: string): void

  protected getDisabledResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getDisabledResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getDisabledResponseSubject.asObservable();
  }

  public setGetDisabledResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getDisabledResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Published
  public abstract getPublished(lang: string): void

  protected getPublishedResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getPublishedResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getPublishedResponseSubject.asObservable();
  }

  public setGetPublishedResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getPublishedResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Contributed
  public abstract getContributed(lang: string): void

  protected getContributedResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getContributedResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getContributedResponseSubject.asObservable();
  }

  public setGetContributedResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getContributedResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Contributed By Uok Author
  public abstract getContributedByUokAuthor(lang: string, uokAuthorId: number): void

  protected getContributedByUokAuthorResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getContributedByUokAuthorResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getContributedByUokAuthorResponseSubject.asObservable();
  }

  public setGetContributedByUokAuthorResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getContributedByUokAuthorResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Latest
  public abstract getLatest(lang: string): void

  protected getLatestResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getLatestResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getLatestResponseSubject.asObservable();
  }

  public setGetLatestResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getLatestResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Operators By Text
  public abstract getOperatorsByText(queryText: string): void

  protected getOperatorsByTextResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getOperatorsByTextResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getOperatorsByTextResponseSubject.asObservable();
  }

  public setGetOperatorsByTextResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getOperatorsByTextResponseSubject.next(tmp);
  }

  //#endregion

  //#region Get Bookmarks By Status
  public abstract getBookmarksByStatus(status: string): void

  protected getBookmarksByStatusResponseSubject = new ReplaySubject<RepositoryResponse<ISearchEngineItemDto[]>>();

  public getBookmarksByStatusResponse$(): Observable<RepositoryResponse<ISearchEngineItemDto[]>> {
    return this.getBookmarksByStatusResponseSubject.asObservable();
  }

  public setGetBookmarksByStatusResponse(tmp: RepositoryResponse<ISearchEngineItemDto[]>) {
    this.getBookmarksByStatusResponseSubject.next(tmp);
  }

  //#endregion
}
