import {AccountStateModel} from './account-state-model.interface';

export namespace AccountSignInActions{
  export class Requested {
    static readonly type = '[Auth] Login Requested';

    constructor(public payload: { username: string; password: string }) {
    }
  }

  export class Succeded {
    static readonly type = '[Auth] Login Successed';

    constructor(public payload: { authModel: AccountStateModel }) {
    }
  }

  export class Failed {
    static readonly type = '[Auth] Login Failed';

    constructor() {
    }
  }
}
