import {IProductDto} from "../../api/dtos/products/product-dto.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {ProductModel} from "../../core/state/product/product-model.interface";

export class ProductMapper implements Mapper<IProductDto, ProductModel> {
  mapFrom(param: IProductDto): ProductModel {
    const res: ProductModel = {
      additionalInfo: param.AdditionalInfo,
      cultureName: param.CultureName,
      dbId: param.DbId,
      isAccessible: param.IsAccessible,
      isActive: param.IsActive,
      isPurchased: param.IsPurchased,
      mainUokId: param.MainUokId,
      operatorId: param.OperatorId,
      productDescription: param.ProductDescription,
      productDescriptionDismissed: param.ProductDescriptionDismissed,
      productDescriptionExpired: param.ProductDescriptionExpired,
      productImage: param.ProductImage,
      productName: param.ProductName,
      remainingDaysForFree: param.RemainingDaysForFree,
      shopDescription: param.ShopDescription,
      shopLink: param.ShopLink,
      surveys: [],
      systemName: param.SystemName
    };

    param.Surveys.forEach(s => {
      res.surveys.push({
        certificate: s.Certificate,
        cultureName: s.CultureName,
        dbId: s.DbId,
        fillingTimestamp: s.FillingTimestamp,
        score: s.Score,
        state: s.State,
        summary: s.Summary,
        title: s.Title,
        type: s.Type,
        userSurveyId: s.UserSurveyId,
        numberOfRetriesRemaining: s.NumberOfRetriesRemaining
      })
    })

    return res;
  }

  mapTo(param: ProductModel): IProductDto {
    const res: IProductDto = {
      AdditionalInfo: param.additionalInfo,
      CultureName: param.cultureName,
      DbId: param.dbId,
      IsAccessible: param.isAccessible,
      IsActive: param.isActive,
      IsPurchased: param.isPurchased,
      MainUokId: param.mainUokId,
      OperatorId: param.operatorId,
      ProductDescription: param.productDescription,
      ProductDescriptionDismissed: param.productDescriptionDismissed,
      ProductDescriptionExpired: param.productDescriptionExpired,
      ProductImage: param.productImage,
      ProductName: param.productName,
      RemainingDaysForFree: param.remainingDaysForFree,
      ShopDescription: param.shopDescription,
      ShopLink: param.shopLink,
      Surveys: [],
      SystemName: param.systemName
    }

    param.surveys.forEach(s => {
      res.Surveys.push({
        Certificate: s.certificate,
        CultureName: s.cultureName,
        DbId: s.dbId,
        FillingTimestamp: s.fillingTimestamp,
        Score: s.score,
        State: s.state,
        Summary: s.summary,
        Title: s.title,
        Type: s.type,
        UserSurveyId: s.userSurveyId,
        NumberOfRetriesRemaining: s.numberOfRetriesRemaining
      })
    })

    return res;
  }
}
