import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {MenuItemChild, MenuRoot} from "../../../presentation/ui-kit/layout/models";
import {
  RemoveDraftMenuItem, RemoveLinkEditorMenuItem,
  RemoveUokMenuItem, LastMenuItem,
  SetDraftMenuItem, SetLinkEditorMenuItem,
  SetMenuExpand,
  SetShowMobileMenu,
  SetToggleMenu, SetUokMenuItem,
  UpdateActiveMenu
} from "./main-menu-actions.class";
import {MainMenuModel} from "./main-menu-model.interface";
import {AppRoutes} from '../../../app-routes';

const defaults: MainMenuModel = {
  showMobileMenu: false,
  showSidebar: false,
  menuRoot: [
    {
      menuItemChild: {
        iconName: 'squares',
        labelKey: 'Cmp.MainMenu.Items.Dashboard.Label',
        route: AppRoutes.dashboard
      }
    },
    {
      menuItemChild: {
        iconName: 'building-library',
        labelKey: 'Cmp.MainMenu.Items.Knowledge.Label',
        route: AppRoutes.knowledge,
      }
    },
    {
      menuItemChild: {
        iconName: 'bookmark-outline',
        labelKey: 'Cmp.MainMenu.Items.Bookmarks.Label',
        route: AppRoutes.bookmarks,
      }
    },
    {
      menuItemChild: {
        iconName: 'pencil-square',
        labelKey: 'Cmp.MainMenu.Items.MyDrafts.Label',
        route: AppRoutes.myDrafts,
      }
    },
    {
      menuItemChild: {
        iconName: 'book-open',
        labelKey: 'Cmp.MainMenu.Items.Library.Label',
        route: AppRoutes.library,
      }
    }
  ]
}

@State<MainMenuModel>({
  name: 'mainMenu',
  defaults: defaults
})
@Injectable()
export class MainMenuState {
  constructor(private router: Router) {
  }

  @Selector()
  static menuRoot(state: MainMenuModel): MenuRoot[] {
    return state.menuRoot;
  }

  @Selector()
  static showMobileMenu(state: MainMenuModel): boolean {
    return state.showMobileMenu;
  }

  @Selector()
  static showSidebar(state: MainMenuModel): boolean {
    return state.showSidebar;
  }

  @Action(SetShowMobileMenu)
  setShowMobileMenu(ctx: StateContext<MainMenuModel>, action: SetShowMobileMenu): void {
    ctx.patchState({showMobileMenu: action.showMobileMenu});
  }

  @Action(SetToggleMenu)
  setToggleMenu(ctx: StateContext<MainMenuModel>, action: SetToggleMenu): void {
    action.menu.expanded = !action.menu.expanded;
    const serializedMenu = JSON.stringify(ctx.getState().menuRoot);
    const clone: Array<MenuRoot> = JSON.parse(serializedMenu);

    ctx.patchState({
      showSidebar: true,
      menuRoot: clone
    });
  }

  @Action(SetMenuExpand)
  private expand(ctx: StateContext<MainMenuModel>, action: SetMenuExpand) {
    action.items.forEach((item) => {
      item.expanded = this.isActive(item.route);
      if (item.children) this.expand(ctx, item.children);
    });
  }

  @Action(SetUokMenuItem)
  setUokMenuItem(ctx: StateContext<MainMenuModel>, action: SetUokMenuItem) {
    this.setMenuItemById(ctx, action.item, 'uok-menu-item');
  }

  @Action(RemoveUokMenuItem)
  removeUokMenuItem(ctx: StateContext<MainMenuModel>): void {
    this.removeMenuItemById(ctx, 'uok-menu-item')
  }

  @Action(SetDraftMenuItem)
  setDraftMenuItem(ctx: StateContext<MainMenuModel>, action: SetDraftMenuItem) {
    this.setMenuItemById(ctx, action.item, 'draft-menu-item');
  }

  @Action(RemoveDraftMenuItem)
  removeDraftMenuItem(ctx: StateContext<MainMenuModel>): void {
    this.removeMenuItemById(ctx, 'draft-menu-item')
  }

  @Action(SetLinkEditorMenuItem)
  setLinkEditorMenuItem(ctx: StateContext<MainMenuModel>, action: SetDraftMenuItem) {
    this.setMenuItemById(ctx, action.item, 'link-editor-menu-item');
  }

  @Action(RemoveLinkEditorMenuItem)
  removeLinkEditorMenuItem(ctx: StateContext<MainMenuModel>): void {
    this.removeMenuItemById(ctx, 'link-editor-menu-item')
  }

  private removeMenuItemById(ctx: StateContext<MainMenuModel>, id: string): void {
    const root = ctx.getState().menuRoot;
    const index = root.findIndex(x => x.menuItemChild?.id === id) ?? -1;
    if (index !== -1) {
      root.splice(index, 1)
    }
  }

  private setMenuItemById(ctx: StateContext<MainMenuModel>, item: LastMenuItem, id: string) {
    const root = ctx.getState().menuRoot;
    const index = root.findIndex(x => x.menuItemChild?.id === id) ?? -1;

    if (!item) {
      return;
    }

    const tmp: MenuItemChild = {
      id: id,
      labelKey: item.label,
      route: item.path,
      iconName: item.icon,
      active: true
    }

    if (index === -1) {
      root.push({
        menuItemChild: tmp
      });
    } else {
      root.splice(index, 1, {
        menuItemChild: tmp
      })
    }
  }


  @Action(UpdateActiveMenu)
  private updateActiveMenu(ctx: StateContext<MainMenuModel>) {
    const root = ctx.getState().menuRoot;
    root.forEach((menuItem) => {
      let activeGroup = false;
      if (menuItem.menuItem) {
        menuItem.menuItem.children?.forEach((childMenu) => {
          const active = this.isActive(childMenu.route);
          childMenu.expanded = active;
          childMenu.active = active;
          if (active) {
            activeGroup = true;
          }
          if (childMenu.children) {
            this.expand(ctx, {items: childMenu.children});
          }

        });
        menuItem.menuItem.active = activeGroup;
      } else if (menuItem.menuItemChild) {
        // menuItem.menuItemChild.active = this.isActive(menuItem.menuItemChild.route);
        const item = root.find(x => x.menuItemChild?.route === menuItem.menuItemChild?.route);

        if (item && item.menuItemChild) {
          item.menuItemChild.active = this.isActive(menuItem.menuItemChild.route);
        }
      }
    });

    ctx.patchState({
      menuRoot: root
    });
  }

  private isActive(instruction: any): boolean {
    // console.log("TREE: " + this.router.createUrlTree([instruction]), {
    //   paths: 'subset',
    //   queryParams: 'subset',
    //   fragment: 'ignored',
    //   matrixParams: 'ignored',
    // });

    // console.log({
    //   route: instruction,
    //   res: a
    // })

    return this.router.isActive(this.router.createUrlTree([instruction]), {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
