import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
  //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  @Output() private filesChangeEmitter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('class.fileover') fileOver: boolean = false;

  constructor() {
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    const files: Array<File> = evt.dataTransfer.files;
    this.fileOver = false;
    if (files?.length > 0) {
      this.filesChangeEmitter.emit(files);
    }
  }
}
