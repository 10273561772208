import {inject, Injectable} from '@angular/core';
import {MatomoRouterInterceptor, MatomoTracker} from 'ngx-matomo-client';
import {Store} from '@ngxs/store';
import {NavigationEnd} from '@angular/router';
import {UserState} from './core/state/user';

@Injectable()
export class MatomoUserIdInterceptor implements MatomoRouterInterceptor {
  private readonly tracker = inject(MatomoTracker);
  private readonly store = inject(Store);

  beforePageTrack(event: NavigationEnd) {
    const user = this.store.selectSnapshot(UserState.user);
    if (user) {
      this.tracker.setUserId(user.dbId.toString());
    }
  }
}
