import {UokLinkModel, UokLinksDefinitionModel} from '../detail/models/link';
import {UokDetailContentInfoModel, UokDetailHeaderModel} from '../detail/models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LinkEditorStateActions {

  export class LoadHeader {
    static readonly type = '[Link Editor State] Load Uok Header';

    constructor(public model: UokDetailHeaderModel) {
    }
  }

  export class LoadContentInfo {
    static readonly type = '[Link Editor State] Load Uok Content';

    constructor(public model: UokDetailContentInfoModel) {
    }
  }

  export class LoadLinks {
    static readonly type = '[Link Editor State] Load Links';

    constructor(public model: UokLinkModel[]) {
    }
  }

  export class LoadLinksDefinition {
    static readonly type = '[Link Editor State] Load Link Definitions';

    constructor(public model: UokLinksDefinitionModel) {
    }
  }

  export class AddLink {
    static readonly type = '[Link Editor State] Add Link';

    constructor(public model: UokLinkModel) {
    }
  }

  export class RemoveLink {
    static readonly type = '[Link Editor State] Remove Link';

    constructor(public model: UokLinkModel) {
    }
  }

  export class UpdateLink {
    static readonly type = '[Link Editor State] Update Link';

    constructor(public model: UokLinkModel) {
    }
  }

  export class ClearState {
    static readonly type = '[Link Editor State] Clear state';
  }
}
