import {IDtoStripeData, IDtoStripeInfo, IDtoStripeInvoices} from "../../api/dtos/stripe";
import {Mapper} from "../../core/abstractions/repositories";
import {
  StripeInfoModel,
  StripeInvoicesModel,
  StripeSubscriptionStatus,
  UserStripeInfoModel
} from "../../core/state/user/models";

export class StripeInfoMapper implements Mapper<IDtoStripeData, UserStripeInfoModel> {
  mapFrom(param: IDtoStripeData): UserStripeInfoModel {
    return {
      creditCardDueDate: param.CreditCardDueDate,
      creditCardLastDigits: param.CreditCardLastDigits,
      discounts: this.discountsDtoToModel(param.Discounts),
      invoices: this.invoicesDtoToModel(param.Invoices),
      planInfo: {
        description: param.PlanInfo.Description,
        link: param.PlanInfo.Link
      },
      subscription: {
        status: param.Subscription.Status,
        isCancelAtPeriodEnd: param.Subscription.IsCancelAtPeriodEnd,
        start: param.Subscription.Start,
        stop: param.Subscription.Stop
      }
    };
  }

  private discountsDtoToModel(dto: IDtoStripeInfo[]): StripeInfoModel[] {
    const tmp: StripeInfoModel[] = [];
    dto.forEach(discount => {
      tmp.push({
        description: discount.Description,
        link: discount.Link
      })
    })
    return tmp;
  }

  private invoicesDtoToModel(dto: IDtoStripeInvoices): StripeInvoicesModel {
    const tmp: StripeInvoicesModel = {
      InvoicesHistory: [],
      IsPreviewEstimated: dto.IsPreviewEstimated,
      InvoicePreview: {
        UrlPdfFile: dto.InvoicePreview?.UrlPdfFile ?? '',
        Total: dto.InvoicePreview?.Total ?? 0,
        Created: dto.InvoicePreview?.Created ?? null,
        NextPayment: dto.InvoicePreview?.NextPayment ?? null,
        IsPaid: dto.InvoicePreview?.IsPaid ?? false
      }
    }

    dto.InvoicesHistory.forEach(invoice => {
      tmp.InvoicesHistory.push({
        UrlPdfFile: invoice.UrlPdfFile,
        Total: invoice.Total,
        IsPaid: invoice.IsPaid,
        NextPayment: invoice.NextPayment,
        Created: invoice.Created
      })
    })

    return tmp;
  }

  mapTo(param: UserStripeInfoModel): IDtoStripeData {
    return {
      CreditCardDueDate: new Date(),
      CreditCardLastDigits: "",
      CultureName: "",
      DbId: 0,
      Discounts: [],
      Invoices: {
        InvoicePreview: {
          UrlPdfFile: '',
          Total: 0,
          IsPaid: false,
          NextPayment: null,
          Created: null
        },
        InvoicesHistory: [],
        IsPreviewEstimated: true,
      },
      OperatorId: 0,
      PlanInfo: {
        Description: '',
        Link: ''
      },
      Subscription: {
        Status: StripeSubscriptionStatus.Active,
        IsCancelAtPeriodEnd: true,
        Start: new Date(),
        Stop: new Date(),
      },
      SystemName: ""
    }
  }


}
