import {IDtoUserOperator} from "../../api/dtos/user";
import {IUserDto} from '../../api/dtos/user';
import {Mapper} from '../../core/abstractions/repositories';
import {UserModel} from "../../core/state/user/models";

export class UserMapper implements Mapper<IUserDto, UserModel> {
  mapFrom(param: IDtoUserOperator): UserModel {
    const tmp: UserModel = {
      state: param.State,
      dbId: param.DbId,
      operatorId: param.OperatorId,
      username: param.UserName,
      email: param.Email,
      name: param.Name,
      surname: param.Surname,
      companyName: param.CompanyName,
      vatNumber: param.VATNumber,
      sexGender: param.SexGender,
      birthDate: param.BirthDate,
      birthCity: param.BirthCity,
      birthProvince: param.BirthProvince,
      birthPostalCode: param.BirthPostalCode,
      birthCountry: param.BirthCountry,
      addressCity: param.AddressCity,
      addressCountry: param.AddressCountry,
      addressPostalCode: param.AddressPostalCode,
      addressProvince: param.AddressProvince,
      addressRoad: param.AddressRoad,
      cultureName: param.CultureName,
      phoneHome: param.PhoneHome,
      phoneMobile: param.PhoneMobile,
      phoneOffice: param.PhoneOffice,
      privacyState: param.PrivacyState,
      friends: param.Friends,
      publicNickName: param.PublicNickName,
      isLicenseFree: param.IsLicenceFree,
      uokAuthorId: param.UokAuthorId,
      profileImageFull:param.ProfileImageFull,
      profileImageSmall:param.ProfileImageSmall,
      contentPolicyAccepted: param.ContentPolicyAccepted,
      uokAccessRights: []
    };

    param.UokAccessRights.forEach(r => {
      tmp.uokAccessRights.push({
        canRead: r.CanRead,
        canWrite: r.CanWrite,
        dtoUokSystemName: r.DtoUokSystemName
      })
    })

    return tmp;
  }

  mapTo(param: UserModel): IDtoUserOperator {
    return {
      State: param.state,
      AddressCity: param.addressCity,
      AddressCountry: param.addressCountry,
      AddressPostalCode: param.addressPostalCode,
      AddressProvince: param.addressProvince,
      AddressRoad: param.addressRoad,
      BirthCity: param.birthCity,
      BirthCountry: param.birthCountry,
      BirthDate: param.birthDate,
      BirthPostalCode: param.birthPostalCode,
      BirthProvince: param.birthProvince,
      CompanyName: param.companyName,
      CultureName: param.cultureName,
      DbId: param.dbId,
      Email: param.email,
      FiscalCode1: "",
      FiscalCode2: "",
      Groups: [],
      IsLicenceFree: param.isLicenseFree,
      Name: param.name,
      Note: "",
      OperatorId: param.operatorId,
      PhoneHome: param.phoneHome,
      PhoneMobile: param.phoneMobile,
      PhoneOffice: param.phoneOffice,
      PrivacyState: param.privacyState,
      SexGender: param.sexGender,
      Surname: param.surname,
      SystemName: "",
      Title: "",
      UokAccessRights: [],
      UserName: param.username,
      VATNumber: param.vatNumber,
      Friends: param.friends,
      PublicNickName: param.publicNickName,
      UokAuthorId: param.uokAuthorId,
      ProfileImageFull:param.profileImageFull,
      ProfileImageSmall:param.profileImageSmall,
      ContentPolicyAccepted: param.contentPolicyAccepted
    };
  }

}
