import { StripeSetupIntentGetModel } from "src/app/core/state/stripe";
import { UseCaseActionName } from "src/app/core/utils";
import {StripeSetupIntentGetUseCase} from "../../core/use-cases/stripe";

export class StripeSetupIntentGetUseCaseMock implements StripeSetupIntentGetUseCase {
  public static Name = 'StripeGetSetupIntent';
  init(): void {
    throw new Error("Method not implemented.");
  }
  execute(action: UseCaseActionName, params?: StripeSetupIntentGetModel | undefined): void {
    throw new Error("Method not implemented.");
  }
  getName(): string {
    return StripeSetupIntentGetUseCaseMock.Name;
  }
  dispose(): void {
    throw new Error("Method not implemented.");
  }

}
