import * as tus from 'tus-js-client';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Subject} from "rxjs";
import {environment} from "../../environments/environment";
import {Injectable, OnDestroy} from "@angular/core";
import {Store} from "@ngxs/store";
import {DraftStateActions} from "../core/state/draft";
import UpdateVideoUpload = DraftStateActions.UpdateVideoUpload;

export class UploadVideos {
    constructor(public video: File, public path: string, public uploadUri: string) {
        this.video = video;
        this.path = path;
        this.uploadUri = uploadUri;
    }
}

export class UploadProgress {
    constructor(public progress: number, public path: string, public uploadUri: string, public name: string) {
        this.progress = progress;
        this.path = path;
        this.uploadUri = uploadUri;
        this.name = name;
    }
}

@Injectable({
    providedIn: 'root'
})
export class VimeoUploaderService implements OnDestroy {
    uploadPercentage$: Subject<number> = new Subject<number>();
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private http: HttpClient, private store: Store) {
        this.uploadPercentage$.next(0)
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    createVideo(file: File) {
        const body = {
            name: file.name,
            upload: {
                approach: 'tus',
                size: file.size,
            },
        };

        const header: HttpHeaders = new HttpHeaders()
            .set('Authorization', 'bearer ' + environment.vimeoAccessToken)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/vnd.vimeo.*+json;version=3.4');
        return this.http.post('https://api.vimeo.com/me/videos', body, {
            headers: header,
            observe: 'body',
        });
    }

    deleteVideo(id: string) {
        const header: HttpHeaders = new HttpHeaders()
            .set('Authorization', 'bearer ' + environment.vimeoAccessToken)
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/vnd.vimeo.*+json;version=3.4');
        return this.http.delete(`https://api.vimeo.com/videos/${id}`, {
            headers: header,
            observe: 'body',
        });
    }

    async uploadVideo(file: UploadVideos) {
        this.uploadPercentage$.next(0);
        const upload = new tus.Upload(file.video, {
            uploadUrl: file.uploadUri,
            endpoint: file.uploadUri,
            retryDelays: [0, 1000, 3000, 5000],
            onError: (error) => {
                console.log('Failed: ' + file.video.name + error);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const uploadPercentage = Math.trunc((bytesUploaded / bytesTotal) * 100);
                this.uploadPercentage$.next(uploadPercentage);
                this.store.dispatch(new UpdateVideoUpload(new UploadProgress(uploadPercentage, file.path, file.uploadUri, file.video.name)))
            },
            onSuccess: () => {
                // console.log('Download' + file.video.name + 'from' + upload.url);
                // console.log('Videos uploaded successfully');
            },
        });

        upload.start();
    }
}
