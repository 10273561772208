import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {UokDetailHeaderModel} from "../detail/models";
import {HistoryStateActions} from "./history-state-actions";
import {HistoryStateModel} from "./history-state-model.interface";

@State<HistoryStateModel>({
    name: 'history',
    defaults: {
        uokHeaders: []
    }
})
@Injectable()
export class HistoryState {

    @Selector()
    public static archivedHeaders(state: HistoryStateModel): UokDetailHeaderModel[] {
        return state.uokHeaders;
    }

    @Action(HistoryStateActions.ArchiveUokDetail)
    public archiveUokDetailState(ctx: StateContext<HistoryStateModel>, action: HistoryStateActions.ArchiveUokDetail) {
        if (!action.header) {
            return;
        }

        const uoks = [...ctx.getState().uokHeaders];
        const index = uoks.findIndex(x => x.uokId === action.header?.uokId)

        if (index === -1) {
            uoks.push(action.header);
        } else {
            uoks.splice(index, 1);
            uoks.push(action.header);
        }

        if (uoks.length > 10) {
            // remove the oldest
            uoks.splice(0, 1);
        }

        ctx.patchState({
            uokHeaders: uoks
        });
    }

    @Action(HistoryStateActions.ResetHistory)
    public resetHistory(ctx: StateContext<HistoryStateModel>) {
        ctx.setState({
            uokHeaders: []
        });
    }
}
