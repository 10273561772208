import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DisplayMessagesPipe, DisplayUsernamePipe, SafePipe, TranslatePluralPipe} from "./pipes";
import {IntegerInputDirective, MaskDirective} from "./directives";


@NgModule({
  declarations: [
    SafePipe,
    TranslatePluralPipe,
    MaskDirective,
    IntegerInputDirective,
    DisplayUsernamePipe,
    DisplayMessagesPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [],
  exports: [
    SafePipe,
    MaskDirective,
    IntegerInputDirective,
    TranslatePluralPipe,
    DisplayUsernamePipe,
    DisplayMessagesPipe
  ]
})
export class CoreModule {
}
