import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {IDtoUokLink} from "../dtos/uok-link";

@Injectable({
  providedIn: 'root'
})
export class UokLinkApiService {
  constructor(private http: HttpClient) {
  }

  public loadUokLinksDefinition(dtoUokName: string, lang: string) {
    return this.http.get(`${environment.webApiV1}${lang}/uok/links/definition?dtoUokName=${dtoUokName}`);
  }

  public loadUokLinks(id: number, lang: string){
    return this.http.get(`${environment.webApiV1}${lang}/uok/links/load/${id}`);
  }

  public loadUokLinksReferred(id: number, lang: string) {
    return this.http.get(`${environment.webApiV1}${lang}/uok/links/referredby/${id}`);
  }

  public loadKnowledgeTree(id: number, lang: string){
    return this.http.get(`${environment.webApiV1}${lang}/uok/links/knowledgetree/${id}`);
  }

  public saveUokLinks(links: IDtoUokLink[], lang: string){
    return this.http.post(`${environment.webApiV1}${lang}/uok/links/save`, links);
  }
}
