import {UserSetupIntentModel} from "../models/user-setup-intent-model.interface";

export namespace UserCreateSetupIntentActions {
  export class Succeded {
    static readonly type = '[User Create Setup Intent] User Create Setup Intent Succeded';

    constructor(public model: UserSetupIntentModel) {
    }
  }

  export class Clear {
    static readonly type = '[User Clear Setup Intent] User Clear Setup Intent Succeded';
  }
}
