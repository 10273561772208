import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {SurveyActions} from "./survey-actions";
import {SurveyStateModel} from "./survey-state-model.interface";

@State<SurveyStateModel>({
  name: 'surveys',
  defaults: {
    currentTemplate: null,
    currentInstance: null,
    instancePendingSave: null,
    filledInstances: []
  }
})
@Injectable()
export class SurveyState {
  @Selector()
  static currentTemplate(state: SurveyStateModel) {
    return state.currentTemplate;
  }

  @Selector()
  static currentInstance(state: SurveyStateModel) {
    return {
      instance: state.currentInstance,
      pendingSave: state.instancePendingSave
    };
  }

  @Selector()
  static filled(state: SurveyStateModel) {
    return state.filledInstances;
  }

  @Action(SurveyActions.SurveyTemplateLoad)
  surveyTemplateLoad(ctx: StateContext<SurveyStateModel>, action: SurveyActions.SurveyTemplateLoad) {
    ctx.patchState({
      currentTemplate: action.model
    })
  }

  @Action(SurveyActions.SurveyInstancesLoad)
  surveyInstancesLoad(ctx: StateContext<SurveyStateModel>, action: SurveyActions.SurveyInstancesLoad) {
    ctx.patchState({
      filledInstances: action.models
    })
  }

  @Action(SurveyActions.SurveyInstanceSave)
  surveyInstanceSave(ctx: StateContext<SurveyStateModel>, action: SurveyActions.SurveyInstanceSave) {
    ctx.patchState({
      currentInstance: action.model ?? ctx.getState().currentInstance,
      instancePendingSave: action.pendingSave
    })
  }

  @Action(SurveyActions.SurveyClearState)
  surveyClearState(ctx: StateContext<SurveyStateModel>) {
    ctx.patchState({
      currentTemplate: null,
      currentInstance: null,
      instancePendingSave: null,
      filledInstances: []
    })
  }
}
