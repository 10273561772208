import {IDtoAccessKey} from "../../api/dtos/user/dto-access-key.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {UserAccessKeyModel} from "../../core/state/user/models/user-access-key-model.interface";

export class AccessKeyMapper extends Mapper<IDtoAccessKey, UserAccessKeyModel> {
  mapFrom(param: IDtoAccessKey): UserAccessKeyModel {
    return {
      CultureName: param.CultureName,
      DbId: param.DbId,
      OperatorId: param.OperatorId,
      SystemName: param.SystemName,
      code: param.Code,
      discountDays: param.DiscountDays,
      isValid: param.IsValid,
      state: param.State,
      utcDateActive: param.UtcDateActive,
      utcDateExpire: param.UtcDateExpire,
      utcDateLock: param.UtcDateLock
    };
  }

  mapTo(param: UserAccessKeyModel): IDtoAccessKey {
    return {
      Code: param.code,
      CultureName: param.CultureName,
      DbId: param.DbId,
      DiscountDays: param.discountDays,
      IsValid: param.isValid,
      OperatorId: param.OperatorId,
      State: param.state,
      SystemName: param.SystemName,
      UtcDateActive: param.utcDateActive,
      UtcDateExpire: param.utcDateExpire,
      UtcDateLock: param.utcDateLock
    };
  }

}
