import {Observable, ReplaySubject} from "rxjs";
import {IDtoTagInfo, IDtoUokDraftInfo, IdtoUokDraftPublish} from "../../api/dtos/uok";
import {BaseRepository, RepositoryResponseGeneric} from "../abstractions/repositories";
import {RepositoryResponse} from "../abstractions/repositories";
import {IDtoDraft} from '../../api/dtos/uok/dto-draft.interface';

export abstract class DraftRepository extends BaseRepository {

  // GET DRAFT
  public abstract getDraft(lang: string, draftId: number): void;

  protected getDraftSubject = new ReplaySubject<RepositoryResponse<IDtoDraft>>();

  public getDraft$(): Observable<RepositoryResponse<IDtoDraft>> {
    return this.getDraftSubject.asObservable();
  }

  public setGetDraft(tmp: RepositoryResponse<IDtoDraft>) {
    this.getDraftSubject.next(tmp);
  }

  public abstract createDraftFromUok(lang: string, uokId: number): void;

  protected createDraftFromUokSubject = new ReplaySubject<RepositoryResponse<IDtoDraft>>();

  public createDraftFromUok$(): Observable<RepositoryResponse<IDtoDraft>> {
    return this.createDraftFromUokSubject.asObservable();
  }

  public setCreateDraftFromUok(tmp: RepositoryResponse<IDtoDraft>) {
    this.createDraftFromUokSubject.next(tmp);
  }

  // SAVE DRAFT
  public abstract saveDraft(lang: string, dto: IDtoDraft): void;

  protected saveDraftSubject = new ReplaySubject<RepositoryResponse<IDtoDraft>>();

  public saveDraft$(): Observable<RepositoryResponse<IDtoDraft>> {
    return this.saveDraftSubject.asObservable();
  }

  public setSaveDraft(tmp: RepositoryResponse<IDtoDraft>) {
    this.saveDraftSubject.next(tmp);
  }

  // GET DRAFT INFOS
  public abstract getDraftsInfoByState(lang: string, stateAsNumber: number): void;

  protected draftsInfoByStateSubject = new ReplaySubject<RepositoryResponse<IDtoUokDraftInfo[]>>();

  public getDraftsInfoByState$(): Observable<RepositoryResponse<IDtoUokDraftInfo[]>> {
    return this.draftsInfoByStateSubject.asObservable();
  }

  public setDraftsInfoByState(tmp: RepositoryResponse<IDtoUokDraftInfo[]>) {
    this.draftsInfoByStateSubject.next(tmp);
  }

  // GET TAGS
  public abstract getTags(lang: string): void;

  protected getTagsSubject = new ReplaySubject<RepositoryResponse<IDtoTagInfo[]>>();

  public getTags$(): Observable<RepositoryResponse<IDtoTagInfo[]>> {
    return this.getTagsSubject.asObservable();
  }

  public setGetTags(tmp: RepositoryResponse<IDtoTagInfo[]>) {
    this.getTagsSubject.next(tmp);
  }

  //#region Submit Draft
  public abstract submit(draftId: number): void

  protected submitResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public submitResponse$(): Observable<RepositoryResponseGeneric> {
    return this.submitResponseSubject.asObservable();
  }

  public setSubmitResponse(tmp: RepositoryResponseGeneric) {
    this.submitResponseSubject.next(tmp);
  }

  //#endregion

  //#region Delete Draft
  public abstract delete(draftId: number): void

  protected deleteResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public deleteResponse$(): Observable<RepositoryResponseGeneric> {
    return this.deleteResponseSubject.asObservable();
  }

  public setDeleteResponse(tmp: RepositoryResponseGeneric) {
    this.deleteResponseSubject.next(tmp);
  }

  //#endregion

  //#region Publish Draft
  public abstract publish(model: IdtoUokDraftPublish): void

  protected publishResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public publishResponse$(): Observable<RepositoryResponseGeneric> {
    return this.publishResponseSubject.asObservable();
  }

  public setPublishResponse(tmp: RepositoryResponseGeneric) {
    this.publishResponseSubject.next(tmp);
  }

  //#endregion

  //#region Reject Draft
  public abstract reject(draftId: number, message: string): void

  protected rejectResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public rejectResponse$(): Observable<RepositoryResponseGeneric> {
    return this.rejectResponseSubject.asObservable();
  }

  public setRejectResponse(tmp: RepositoryResponseGeneric) {
    this.rejectResponseSubject.next(tmp);
  }

  //#endregion
}
