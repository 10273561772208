import { Observable, ReplaySubject } from 'rxjs';
import { IUserLoginResponseDto } from '../../api/dtos/security';
import { IChangePasswordRequestDto } from '../../api/dtos/security';
import { BaseRepository } from '../abstractions/repositories';
import {
  RepositoryResponse,
  RepositoryResponseGeneric,
} from '../abstractions/repositories';

export abstract class AccountRepository extends BaseRepository {

  public abstract logout(): void;

  //#region Change Password
  public abstract changePassword(dto: IChangePasswordRequestDto): void;

  protected changePasswordSubject = new ReplaySubject<RepositoryResponseGeneric>();

  public changePasswordResponse$(): Observable<RepositoryResponseGeneric> {
    return this.changePasswordSubject.asObservable();
  }

  protected setChangePasswordStateResponse(responseModel: RepositoryResponseGeneric): void {
    this.changePasswordSubject.next(responseModel);
  }

  //#endregion

  //#region Login
  public abstract login(username: string, password: string, captchaToken: string): void;

  protected authStateResponseSubject = new ReplaySubject<RepositoryResponse<IUserLoginResponseDto>>();

  public loginAuthStateResponse$(): Observable<RepositoryResponse<IUserLoginResponseDto>> {
    return this.authStateResponseSubject.asObservable();
  }

  protected setLoginAuthStateResponse(responseDto: RepositoryResponse<IUserLoginResponseDto>): void {
    this.authStateResponseSubject.next(responseDto);
  }

  //#endregion
}
