import {UserGetUseCase} from '../../core/use-cases/user';
import {UseCaseActionName} from '../../core/utils';

export class UserGetUseCaseMock implements UserGetUseCase{
  dispose(): void {
  }


  getName(): string {
    return "";
  }

  init(): void {
  }

  execute(action: UseCaseActionName, params?: { updateLogin: boolean }): void {
  }

}
