import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { AccountUseCaseFactory } from '../../use-cases/account';
import { UseCaseActionName } from '../../core/utils';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor, OnDestroy {
  private signOutUc = AccountUseCaseFactory.GetAccountSignOut();

  constructor(private store: Store) {
  }

  ngOnDestroy(): void {
    this.signOutUc.dispose();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle the error here
        // console.error('error occurred:', error);
        if (error.status === 401) {
          this.signOutUc.init();
          this.signOutUc.execute(UseCaseActionName.run);
        }

        //throw error as per requirement
        return throwError(() => {
          return error;
        });
      }),
    );
  }
}
