import Dexie, {Table} from 'dexie';

export interface DraftData {
  key: string;
  model: string | null;
  dto: string | null;
}

export interface DraftSettings {
  key: string;
  data: any;
}

// https://dexie.org/docs/Tutorial/Angular
export class DraftCustomDb extends Dexie {
  draftData!: Table<DraftData, string>;
  draftSettings!: Table<DraftSettings, string>;

  constructor() {
    super('draftDb', {autoOpen: true, allowEmptyDB: true});

    // this.version(1).stores({
    //   draftData: '&key',
    // });

    this.version(2).stores({
      draftData: '&key',
      draftSettings: '&key'
    });
  }
}

export const db = new DraftCustomDb();
