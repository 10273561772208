import { Observable, ReplaySubject } from 'rxjs';
import { BaseRepository, RepositoryResponse, RepositoryResponseGeneric } from '../abstractions/repositories';
import {
  IDtoUserCashInfo,
  IDtoUserOperator,
  ISendEmailValidationCodeDto,
  IUserRegistrationRequestDto,
} from '../../api/dtos/user';


export abstract class UserRepository extends BaseRepository {

  //#region User Infos
  public abstract getUserStateModel(updateLoginData: boolean): void

  protected userStateResponseSubject = new ReplaySubject<RepositoryResponse<IDtoUserOperator>>();

  public userStateResponse$(): Observable<RepositoryResponse<IDtoUserOperator>> {
    return this.userStateResponseSubject.asObservable();
  }

  public setUserStateResponse(tmp: RepositoryResponse<IDtoUserOperator>) {
    this.userStateResponseSubject.next(tmp);
  }

  //#endregion

  //#region User Update
  public abstract updateUser(user: IDtoUserOperator): void;

  protected userUpdateResponseSubject = new ReplaySubject<RepositoryResponseGeneric>();

  // public userUpdateResponse$(): Observable<RepositoryResponse<IDtoUserOperator>> {
  //   return this.userUpdateResponseSubject.asObservable();
  // }
  //
  // public setUserUpdateResponse(tmp: RepositoryResponse<IDtoUserOperator>) {
  //   this.userUpdateResponseSubject.next(tmp);
  // }

  public userUpdateResponse$(): Observable<RepositoryResponseGeneric> {
    return this.userUpdateResponseSubject.asObservable();
  }

  public setUserUpdateResponse(tmp: RepositoryResponseGeneric) {
    this.userUpdateResponseSubject.next(tmp);
  }

  //#endregion

  //#region User Cash
  public abstract getUserCash(): void;

  public abstract setUserCashToProcessing(): void;

  protected getUserCashResponseSubject = new ReplaySubject<RepositoryResponse<IDtoUserCashInfo>>();

  public getUserCashResponse$(): Observable<RepositoryResponse<IDtoUserCashInfo>> {
    return this.getUserCashResponseSubject.asObservable();
  }

  public setUserCashResponse(tmp: RepositoryResponse<IDtoUserCashInfo>) {
    this.getUserCashResponseSubject.next(tmp);
  }

  //#endregion


  //#region Registration
  public abstract register(dto: IUserRegistrationRequestDto): void;

  protected registerResponse = new ReplaySubject<RepositoryResponseGeneric>();

  public registerResponse$(): Observable<RepositoryResponseGeneric> {
    return this.registerResponse.asObservable();
  }

  public setRegisterResponse(tmp: RepositoryResponseGeneric) {
    this.registerResponse.next(tmp);
  }

  //#endregion


  //#region Send Validation Code Email
  public abstract sendValidationCodeEmail(dto: ISendEmailValidationCodeDto): void;

  protected sendValidationCodeEmailResponse = new ReplaySubject<RepositoryResponseGeneric>();

  public sendValidationCodeEmailResponse$(): Observable<RepositoryResponseGeneric> {
    return this.sendValidationCodeEmailResponse.asObservable();
  }

  public setSendValidationCodeEmailResponse(tmp: RepositoryResponseGeneric) {
    this.sendValidationCodeEmailResponse.next(tmp);
  }

  //#endregion
}
