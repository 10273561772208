import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessKeyApiService {
  constructor(private httpClient: HttpClient) {
  }

  public accessKeyLock(key: string) {
    return this.httpClient.patch(`${environment.webApiV1}access-key/lock?key=${key}`, '')
  }

  public getAccessKeys() {
    return this.httpClient.get(`${environment.webApiV1}access-key/all`);
  }

  public getAccessKeyInfo(key: string) {
    return this.httpClient.get(`${environment.webApiV1}access-key/info?key=${key}`);
  }

  public generateKeys(friendCode: number, amount: number, discountDays: number, productionLot: string, activableToDay: number, activableToMonth: number, activableToYear: number) {
    const urlDetail = `${environment.webApiV1}access-key/generate?` +
      `friendCode=${friendCode}&amount=${amount}&discountDays=${discountDays}&productionLot=${productionLot}` +
      `&activableToDay=${activableToDay}&activableToMonth=${activableToMonth}&activableToYear=${activableToYear}`;
    return this.httpClient.post(urlDetail, '')
  }
}
