// eslint-disable-next-line @typescript-eslint/no-namespace
import {
  StripePaymentIntentModel,
  StripeSetupIntentModel,
  StripeSubscriptionModel,
} from './models';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace StripeStateActions {
  export class Init {
    static readonly type = '[Stripe] Init to empty values';

    constructor() {
    }
  }

  export class SubscriptionCreateRequested {
    static readonly type = '[Stripe] Create subscription requested';

    constructor() {
    }
  }

  export class SubscriptionCreateCompleted {
    static readonly type = '[Stripe] Create subscription completed';

    constructor(public response: StripeSubscriptionModel) {
    }
  }

  export class PaymentIntentConfirmRequested {
    static readonly type = '[Stripe] Confirm paymentIntent requested';

    constructor() {
    }
  }

  export class PaymentIntentConfirmCompleted {
    static readonly type = '[Stripe] Confirm paymentIntent completed';

    constructor(public paymentInfo: StripePaymentIntentModel) {
    }
  }

  export class SetupIntentGetRequested {
    static readonly type = '[Stripe] Get setup intent requested';

    constructor() {
    }
  }

  export class SetupIntentGetCompleted {
    static readonly type = '[Stripe] Get setup intent completed';

    constructor(public setupInfo: StripeSetupIntentModel) {
    }
  }

  export class SetupIntentConfirmRequested {
    static readonly type = '[Stripe] Confirm setup intent requested';

    constructor() {
    }
  }

  export class SetupIntentConfirmCompleted {
    static readonly type = '[Stripe] Confirm setup intent completed';

    constructor(public setupInfo: StripeSetupIntentModel) {
    }
  }

  export class CustomerGetRequested {
    static readonly type = '[Stripe] Get Customer requested';

    constructor() {
    }
  }

  export class CustomerGetCompleted {
    static readonly type = '[Stripe] Get Customer completed';

    constructor(public customerInfo: {
      customerId: string;
      planId: string;
      planPrice: number;
    }) {
    }
  }

  export class SetIsHandlingCallbackFlag {
    static readonly type = '[Stripe] Set Is Handling Callback Flag';

    constructor(public working: boolean) {
    }
  }
}
