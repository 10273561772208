import {ISendEmailValidationCodeDto} from 'src/app/api/dtos/user/send-email-validation-code-dto.interface';
import {IUserRegistrationRequestDto} from 'src/app/api/dtos/user/user-registration-request-dto.interface';
import { IDtoStripeData, IUpdatePaymentMethodDto } from '../../api/dtos/stripe';
import {IDtoUserCashInfo, IDtoUserOperator} from "../../api/dtos/user";
import {RepositoryResponse} from '../../core/abstractions/repositories';
import {UserRepository} from '../../core/repositories';
import {StripeSubscriptionStatus, UserStates} from "../../core/state/user/models";

export class UserRepositoryMock extends UserRepository {
  // public userAddSubscription(dto: IStripeAddSubscriptionDto): void {
  //   throw new Error('Method not implemented.');
  // }

  public userUpdatePaymentMethod(dto: IUpdatePaymentMethodDto): void {
    throw new Error('Method not implemented.');
  }

  public getUserCash(): void {
    this.setIsUpdating(true);
    this.setIsUpdating(false);

    const tmp: RepositoryResponse<IDtoUserCashInfo> = {
      dto: {
        Total: 100,
        History: [
          {
            Month: 1,
            Year: 2022,
            Status: 'Pending',
            Credit: 0,
            Debit: 0
          },
          {
            Month: 2,
            Year: 2022,
            Status: 'Payed',
            Credit: 0,
            Debit: 0
          },
          {
            Month: 3,
            Year: 2022,
            Status: 'Pending',
            Credit: 0,
            Debit: 0
          }
        ]
      },
      info: {
        level: 0,
        messages: ['UserCashDto received']
      }
    };
    this.setUserCashResponse(tmp);
  }

  public setUserCashToProcessing(): void {
  }

  public updateUser(user: IDtoUserOperator): void {
  }

  public getUserStateModel(updateLoginData: boolean = false): void {
    this.setIsUpdating(true);
    const tmp: RepositoryResponse<IDtoUserOperator> = {
      dto: {
        State: UserStates.ActiveWithAutomaticRenewal,
        AddressCity: "",
        AddressCountry: "",
        AddressPostalCode: "",
        AddressProvince: "",
        AddressRoad: "",
        BirthCity: "",
        BirthCountry: "",
        BirthDate: new Date(),
        BirthPostalCode: "",
        BirthProvince: "",
        CompanyName: "",
        CultureName: "",
        DbId: 1,
        Email: "test@test.com",
        FiscalCode1: "",
        FiscalCode2: "",
        Groups: [],
        IsLicenceFree: false,
        Name: "Test Name",
        Note: "",
        OperatorId: 0,
        PhoneHome: "",
        PhoneMobile: "",
        PhoneOffice: "",
        PrivacyState: 0,
        SexGender: "",
        Surname: "Test Surname",
        SystemName: "",
        Title: "",
        UokAccessRights: [],
        UserName: "test@test.com",
        VATNumber: "",
        UokAuthorId: -1,
        ProfileImageFull: '',
        ProfileImageSmall: '',
        ContentPolicyAccepted: true,
        Friends: {
          CurrentPro: 0,
          CurrentFree: 0,
          History: [
            {
              Month: 1,
              Year: 2022,
              ProFriends: 1
            },
            {
              Month: 2,
              Year: 2022,
              ProFriends: 1
            }
          ]
        },
        PublicNickName: ''
      },
      info: {
        level: 0,
        messages: ['UserDto received']
      }
    }
    this.setIsUpdating(false);
    this.setUserStateResponse(tmp);
  }



  private generateRandomInvoice() {
    return {
      UrlPdfFile: 'https://example.com/invoice.pdf',
      Total: Math.floor(Math.random() * 100) + 10,
      IsPaid: Math.random() < 0.5,
      NextPayment: new Date(),
      Created: new Date(),
    };
  }

  private generateRandomDiscount() {
    return {
      Description: 'Discount Description',
      Link: 'https://example.com/discount',
    };
  }

  public register(dto: IUserRegistrationRequestDto): void {
    throw new Error('Method not implemented.');

  }

  public sendValidationCodeEmail(dto: ISendEmailValidationCodeDto): void {
    throw new Error('Method not implemented.');
  }
}
