import {AbstractControl} from '@angular/forms';

// tslint:disable-next-line:interface-name
export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {

  public static number(control: AbstractControl): ValidationResult|null {
    const hasNumber = /\d/.test(control.value);
    if (!hasNumber) {
      return { number: true };
    }
    return null;
  }

  public static upper(control: AbstractControl): ValidationResult|null {
    const hasUpper = /[A-Z]/.test(control.value);
    if (!hasUpper) {
      return { upper: true };
    }
    return null;
  }

  public static lower(control: AbstractControl): ValidationResult|null {
    const hasLower = /[a-z]/.test(control.value);
    if (!hasLower) {
      return { lower: true };
    }
    return null;
  }

  public static special(control: AbstractControl): ValidationResult|null {
    const hasSpecial = /[@#$%!*?&-]/.test(control.value);
    if (!hasSpecial) {
      return { special: true };
    }
    return null;
  }

  // public static strong(control: FormControl): ValidationResult {
  //     let hasNumber = /\d/.test(control.value);
  //     let hasUpper = /[A-Z]/.test(control.value);
  //     let hasLower = /[a-z]/.test(control.value);
  //     let hasSpecial = /[@#$%!*?&]/.test(control.value);
  //     //console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower, hasSpecial);
  //     const valid = hasNumber && hasUpper && hasLower && hasSpecial;
  //     if (!valid) {
  //        return { strong: true };
  //     }
  //     return null;
  // }

  public static matchPassword(AC: AbstractControl):ValidationResult | null {
    const password = AC.get('password')?.value??'';
    const confirmPassword = AC.get('confirmPassword')?.value??'-';
    if (password !== confirmPassword) {
      // console.log('false');
      AC.get('confirmPassword')?.setErrors({ matchPassword: true });
      return {'matchPassword':true}
      //AC.get('confirmPassword')?.setErrors({ matchPassword: true });
    } //else {
      // console.log('true');
      return null;
  //  }
  }
}
