import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ToastItem} from './toast-item.interface';


@Injectable({
  providedIn: 'root'
})
export class ToastController {

  private bsToastItem: BehaviorSubject<ToastItem | null> = new BehaviorSubject<ToastItem | null>(null)

  public displayToastItem(toastItem: ToastItem) {
    this.bsToastItem.next(toastItem);
  }

  public get toastItem$(): Observable<ToastItem | null> {
    return this.bsToastItem.asObservable();
  }

  public clearToastItem() {
    this.bsToastItem.next(null);
  }
}
