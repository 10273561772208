import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {IDtoUokAuthor} from '../dtos/uok';
import {IDtoUser} from "../dtos/user";
import {ISendEmailValidationCodeDto} from '../dtos/user';
import {IUserRegistrationRequestDto} from '../dtos/user';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private httpClient: HttpClient) {
  }

  //#region User
  public getUserInfo(updateLoginData: boolean) {
    return this.httpClient.get(`${environment.webApiV1}user/info?updateLogin=${updateLoginData}`);
  }

  public update(model: IDtoUser) {
    return this.httpClient.post(`${environment.webApiV1}user/update`, model);
  }

  //#endregion

  //#region Cash
  public setUserCashToProcessing() {
    return this.httpClient.post(`${environment.webApiV1}cash/set-to-processing`, '');
  }

  public getUserCashInfo() {
    return this.httpClient.get(`${environment.webApiV1}cash/info`);
  }

  //#endregion

  //#region Author
  public addAuthor(dto: IDtoUokAuthor) {
    return this.httpClient.post(`${environment.webApiV1}author/add`, JSON.stringify(dto));
  }

  public getAuthor() {
    return this.httpClient.get(`${environment.webApiV1}author/info`);
  }

  //#endregion

  //#region Registration
  public register(dto: IUserRegistrationRequestDto) {
    return this.httpClient.post(`${environment.webApi}account/register`, JSON.stringify(dto));
  }

  public sendValidationEmail(dto: ISendEmailValidationCodeDto) {
    return this.httpClient.post(`${environment.webApi}account/send-email-validation-code`, JSON.stringify(dto));
  }

  public sendWelcomeEmail(email: string) {
    return this.httpClient.post(`${environment.webApi}account/send-welcome-email?email=${email}`, '');
  }

  //#endregion
}
