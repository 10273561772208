import {Action, Selector, State, StateContext} from '@ngxs/store';
import {UokLinkModel, UokLinksDefinitionModel} from '../detail/models/link';
import {ILinkEditorModel} from './link-editor-model.interface';
import {LinkEditorStateActions} from './link-editor-actions';
import {UokDetailContentInfoModel, UokDetailHeaderModel} from '../detail/models';
import {UokDetailStateActions, UokDetailStateModel} from '../detail';

@State<ILinkEditorModel>({
  name: 'linkEditor',
  defaults: {
    header: null,
    contentInfo: null,
    links: [],
    linksDefinition: null
  }
})
export class LinkEditorState {
  @Selector()
  static header(state: ILinkEditorModel): UokDetailHeaderModel | null {
    return state.header;
  }

  @Selector()
  static content(state: ILinkEditorModel): UokDetailContentInfoModel | null {
    return state.contentInfo;
  }

  @Selector()
  static links(state: ILinkEditorModel): UokLinkModel[] {
    return state.links;
  }


  @Selector()
  static linksDefinition(state: ILinkEditorModel): UokLinksDefinitionModel | null {
    return state.linksDefinition;
  }

  @Action(LinkEditorStateActions.LoadHeader)
  loadHeader(ctx: StateContext<ILinkEditorModel>, action: LinkEditorStateActions.LoadHeader) {
    ctx.patchState({
      header: action.model
    })
  }

  @Action(LinkEditorStateActions.LoadContentInfo)
  loadContentInfo(ctx: StateContext<ILinkEditorModel>, action: LinkEditorStateActions.LoadContentInfo) {
    ctx.patchState({
      contentInfo: action.model
    })
  }

  @Action(LinkEditorStateActions.LoadLinks)
  loadLinks(ctx: StateContext<ILinkEditorModel>, action: LinkEditorStateActions.LoadLinks) {
    ctx.patchState({
      links: action.model
    })
  }

  @Action(LinkEditorStateActions.LoadLinksDefinition)
  loadLinkDefinitions(ctx: StateContext<ILinkEditorModel>, action: LinkEditorStateActions.LoadLinksDefinition) {
    ctx.patchState({
      linksDefinition: action.model
    })
  }

  @Action(LinkEditorStateActions.AddLink)
  addLink(ctx: StateContext<UokDetailStateModel>, action: LinkEditorStateActions.AddLink) {
    const updatedLinks = ctx.getState().links;
    ctx.patchState({
      links: [action.model].concat(updatedLinks)
    })
  }

  @Action(LinkEditorStateActions.RemoveLink)
  removeLink(ctx: StateContext<UokDetailStateModel>, action: LinkEditorStateActions.RemoveLink) {
    const updatedLinks = ctx.getState().links;
    const linkIndex = updatedLinks.findIndex(x => x.dbId === action.model.dbId);
    if (linkIndex >= 0) {
      updatedLinks.splice(linkIndex, 1);
      ctx.patchState({
        links: updatedLinks
      })
    }
  }

  @Action(LinkEditorStateActions.UpdateLink)
  updateLink(ctx: StateContext<UokDetailStateModel>, action: LinkEditorStateActions.UpdateLink) {
    const updatedLinks = ctx.getState().links;
    const linkIndex = updatedLinks.findIndex(x => x.dbId === action.model.dbId);
    if (linkIndex >= 0) {
      updatedLinks[linkIndex] = action.model;
      ctx.patchState({
        links: updatedLinks
      })
    } else {
      ctx.patchState({
        links: [action.model].concat(updatedLinks)
      })
    }
  }

  @Action(LinkEditorStateActions.ClearState)
  clearState(ctx: StateContext<ILinkEditorModel>) {
    ctx.patchState({
      header: null,
      contentInfo: null,
      links: [],
      linksDefinition: null
    })
  }
}
