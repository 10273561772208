import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {BookmarkState} from "../../utils";
import {
  UokDetailContentInfoModel,
  UokDetailHeaderModel,
  UokDetailLockInfoModel,
  UokDetailUserInfoModel
} from "./models";
import {ReferredInfoModel, UokLinkModel} from "./models/link";
import {UokDetailStateActions} from "./uok-detail-state-actions";
import {UokDetailStateModel} from "./uok-detail-state-model.interface";
import {SearchEngineItemModel} from '../search-engine';

@State<UokDetailStateModel>({
  name: 'uokDetail',
  defaults: {
    header: null,
    contentInfo: null,
    lockInfo: null,
    userInfo: null,
    links: [],
    referredInfo: [],
    knowledgeTree: [],
    contributedUoks: [],
    bookmarkState: BookmarkState.Deleted
  }
})
@Injectable()
export class UokDetailState {
  static fullState(state: UokDetailStateModel): UokDetailStateModel {
    return state;
  }

  @Selector()
  static header(state: UokDetailStateModel): UokDetailHeaderModel | null {
    return state.header;
  }

  @Selector()
  static contributedUoks(state: UokDetailStateModel): SearchEngineItemModel[] {
    return state.contributedUoks;
  }

  @Selector()
  static content(state: UokDetailStateModel): UokDetailContentInfoModel | null {
    return state.contentInfo;
  }

  @Selector()
  static lockInfo(state: UokDetailStateModel): UokDetailLockInfoModel | null {
    return state.lockInfo;
  }

  @Selector()
  static userInfo(state: UokDetailStateModel): UokDetailUserInfoModel | null {
    return state.userInfo;
  }

  @Selector()
  static links(state: UokDetailStateModel): UokLinkModel[] {
    return state.links;
  }

  @Selector()
  static referredInfo(state: UokDetailStateModel): ReferredInfoModel[] {
    return state.referredInfo;
  }

  @Selector()
  static knowledgeTree(state: UokDetailStateModel): ReferredInfoModel[] {
    return state.knowledgeTree;
  }

  @Selector()
  static bookmarkState(state: UokDetailStateModel): BookmarkState {
    return state.bookmarkState;
  }


  @Action(UokDetailStateActions.LoadHeader)
  loadHeader(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadHeader) {
    ctx.patchState({
      header: action.model
    })
  }

  @Action(UokDetailStateActions.LoadContentInfo)
  loadContentInfo(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadContentInfo) {
    ctx.patchState({
      contentInfo: action.model
    })
  }

  @Action(UokDetailStateActions.LoadLockInfo)
  loadLockInfo(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadLockInfo) {
    ctx.patchState({
      lockInfo: action.model
    })
  }

  @Action(UokDetailStateActions.LoadUserInfo)
  loadUserInfo(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadUserInfo) {
    ctx.patchState({
      userInfo: action.model
    })
  }

  @Action(UokDetailStateActions.LoadLinks)
  loadLinks(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadLinks) {
    ctx.patchState({
      links: action.model
    })
  }

  @Action(UokDetailStateActions.LoadLinksReferred)
  loadReferredInfo(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadLinksReferred) {
    ctx.patchState({
      referredInfo: action.model
    })
  }

  @Action(UokDetailStateActions.LoadKnowledgeTree)
  loadKnowledgeTree(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadKnowledgeTree) {
    ctx.patchState({
      knowledgeTree: action.model
    })
  }

  @Action(UokDetailStateActions.ClearState)
  clearState(ctx: StateContext<UokDetailStateModel>) {
    ctx.patchState({
      header: null,
      contentInfo: null,
      lockInfo: null,
      userInfo: null,
      links: [],
      referredInfo: [],
      knowledgeTree: [],
      contributedUoks: []
    })
  }

  @Action(UokDetailStateActions.LoadBookmarksState)
  loadBookmarksState(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadBookmarksState) {
    ctx.patchState({
      bookmarkState: action.state
    })
  }

  @Action(UokDetailStateActions.LoadContributedUoks)
  loadContributedUoks(ctx: StateContext<UokDetailStateModel>, action: UokDetailStateActions.LoadContributedUoks) {
    ctx.patchState({
      contributedUoks: action.uoks
    })
  }
}
