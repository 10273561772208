import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import {UserRepository} from '../../../core/repositories';
import {UserCashActions} from '../../../core/state/user/actions';
import {UserLoadCashInfoUseCase} from '../../../core/use-cases/user';
import {DtoUtils, UseCaseStateName} from '../../../core/utils';
import {RepositoryFactory} from '../../../data';
import {UserCashMapper} from '../../../data/user-repository';
import {UseCaseAppBase} from '../../use-case-app-base.class';

export class UserLoadCashInfoUseCaseApp extends UseCaseAppBase<null> implements UserLoadCashInfoUseCase {
  public static readonly Name: string = 'UserLoadCashInfo';

  mapper = new UserCashMapper();
  private userRepository: UserRepository;

  constructor(store: Store) {
    super(store);

    this.userRepository = RepositoryFactory.GetUserRepository();

    this.userRepository.getUserCashResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {localizationKey: "UseCase.UserLoadCashInfo.Failure.Message", params: DtoUtils.getMessage(response)},
              {localizationKey: "UseCase.UserLoadCashInfo.Failure.Header"},
              {localizationKey: "UseCase.UserLoadCashInfo.Failure.SubHeader"});
          } else {
            this.dispatch(new UserCashActions.Succeded(this.mapper.mapFrom(response.dto)))
            this.dispatchSetStateWithNone(UseCaseStateName.success, "UseCase.UserLoadCashInfo.Success");
          }
        }
      })
  }

  getName(): string {
    return UserLoadCashInfoUseCaseApp.Name;
  }

  override onExecuteRun(_params?: null) {
    this.dispatchSetStateWithNone(UseCaseStateName.working, "UseCase.UserLoadCashInfo.Working");
    this.userRepository.getUserCash();
  }

  override init(): void {
    this.dispatchInitWithMessage("UseCase.UserLoadCashInfo.Initialized");
  }

  override onDispose() {
    this.userRepository.dispose();
  }
}
