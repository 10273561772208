import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {SignupStateActions} from "./signup-state-actions";
import {SignupStateModel} from "./signup-state-model.interface";

@State<SignupStateModel>({
  name: 'signup',
  defaults: {
    wizardData: {
      username: "",
      name: "",
      surname: "",
      password: "",
      friendCode: 0,
      isProChecked: true,
      creditCardToken: "",
      accessKeyData: null,
      emailValidationCode: ""
    },
    wizardState: {
      currentStep: 1,
      isNextButtonEnabled: true,
      isPreviousButtonEnabled: false,
      isUpdating: false,
      errorOnRegister: null
    }
  }
})
@Injectable()
export class SignupState {

  private static readonly maxStepNumber = 5;
  private static readonly minStepNumber = 1;

  @Selector()
  static isUpdating(state: SignupStateModel) {
    return state.wizardState.isUpdating;
  }

  @Selector()
  static isNextButtonEnabled(state: SignupStateModel) {
    return state.wizardState.isNextButtonEnabled;
  }

  @Selector()
  static isPreviousButtonEnabled(state: SignupStateModel) {
    return state.wizardState.isPreviousButtonEnabled;
  }

  @Selector()
  static currentWizardData(state: SignupStateModel) {
    return state.wizardData;
  }

  @Selector()
  static currentWizardState(state: SignupStateModel) {
    return state.wizardState;
  }



  @Action(SignupStateActions.InitWizard)
  initWizard(ctx: StateContext<SignupStateModel>) {
    ctx.setState({
      wizardData: {
        username: "",
        name: "",
        surname: "",
        password: "",
        friendCode: 0,
        isProChecked: true,
        creditCardToken: "",
        accessKeyData: null,
        emailValidationCode: ""
      },
      wizardState: {
        currentStep: 1,
        isNextButtonEnabled: true,
        isPreviousButtonEnabled: false,
        isUpdating: false,
        errorOnRegister: null
      }
    })
  }

  @Action(SignupStateActions.NextStepClicked)
  nextStepClicked(ctx: StateContext<SignupStateModel>) {
    const state = ctx.getState();
    let curStep = state.wizardState.currentStep + 1;
    const nextEnabled = curStep >= SignupState.maxStepNumber;
    const prevEnabled = curStep <= SignupState.minStepNumber;
    if (curStep > SignupState.maxStepNumber) {
      curStep = SignupState.maxStepNumber;
    } else if (curStep < SignupState.minStepNumber) {
      curStep = SignupState.minStepNumber;
    }

    ctx.patchState({
      wizardState: {
        ...state.wizardState,
        currentStep: curStep,
        isNextButtonEnabled: nextEnabled,
        isPreviousButtonEnabled: prevEnabled
      }
    });
  }

  @Action(SignupStateActions.PreviousStepClicked)
  previousStepClicked(ctx: StateContext<SignupStateModel>) {
    const state = ctx.getState();
    let curStep = state.wizardState.currentStep - 1;
    const nexEnabled = curStep >= SignupState.maxStepNumber;
    const prevEnabled = curStep <= SignupState.minStepNumber;
    if (curStep > SignupState.maxStepNumber) {
      curStep = SignupState.maxStepNumber;
    } else if (curStep < SignupState.minStepNumber) {
      curStep = SignupState.minStepNumber;
    }

    ctx.patchState({
      wizardState: {
        ...state.wizardState,
        currentStep: curStep,
        isNextButtonEnabled: nexEnabled,
        isPreviousButtonEnabled: prevEnabled
      }
    });

  }

  @Action(SignupStateActions.SetIsUpdating)
  setIsUpdating(ctx: StateContext<SignupStateModel>, action: SignupStateActions.SetIsUpdating) {
    const state = ctx.getState();

    ctx.patchState({
      wizardState: {
        ...state.wizardState,
        isUpdating: action.isUpdating
      }
    });
  }

  @Action(SignupStateActions.SetCredentialsAndMoveNext)
  setCredentialsAndMoveNext(ctx: StateContext<SignupStateModel>, action: SignupStateActions.SetCredentialsAndMoveNext) {
    const state = ctx.getState();
    ctx.patchState({
      wizardData: {
        ...state.wizardData,
        friendCode: action.friendCode,
        name: action.name,
        surname: action.surname,
        password: action.password,
        username: action.username,
        accessKeyData: action.accessKeyData
      },
      wizardState: {
        ...state.wizardState,
        currentStep: 2,
        isNextButtonEnabled: false,
        isPreviousButtonEnabled: false
      }
    });

  }

  @Action(SignupStateActions.SetEmailValidationCodeAndMoveNext)
  setEmailValidationCodeAndMoveNext(ctx: StateContext<SignupStateModel>, action: SignupStateActions.SetEmailValidationCodeAndMoveNext) {
    const state = ctx.getState();
    ctx.patchState({
      wizardData: {
        ...state.wizardData,
        emailValidationCode: action.code
      },
      wizardState: {
        ...state.wizardState,
        currentStep: 3,
        isNextButtonEnabled: false,
        isPreviousButtonEnabled: false
      }
    });
  }

  @Action(SignupStateActions.SelectPlanAndMoveNext)
  selectPlanAndMoveNext(ctx: StateContext<SignupStateModel>, action: SignupStateActions.SelectPlanAndMoveNext) {
    const state = ctx.getState();
    ctx.patchState({
      wizardData: {
        ...state.wizardData,
        isProChecked: action.proChecked
      },
      wizardState: {
        ...state.wizardState,
        currentStep: 4,
        isNextButtonEnabled: false,
        isPreviousButtonEnabled: false
      }
    });
  }

  @Action(SignupStateActions.SelectPaymentAndMoveNext)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  selectPaymentAndMoveNext(ctx: StateContext<SignupStateModel>, action: SignupStateActions.SelectPaymentAndMoveNext) {
    const state = ctx.getState();
    ctx.patchState({
      wizardState: {
        ...state.wizardState,
        currentStep: 5,
        isNextButtonEnabled: false,
        isPreviousButtonEnabled: false
      }
    });
  }

  @Action(SignupStateActions.MoveToErrorStep)
  moveToErrorStep(ctx: StateContext<SignupStateModel>, action: SignupStateActions.MoveToErrorStep) {
    const state = ctx.getState();
    ctx.patchState({
      wizardState: {
        ...state.wizardState,
        currentStep: -1,
        isNextButtonEnabled: false,
        isPreviousButtonEnabled: false,
        errorOnRegister: action.error
      }
    });
  }

@Action(SignupStateActions.MoveToWelcomeStep)
  moveToWelcomeStep(ctx: StateContext<SignupStateModel>){
  const state = ctx.getState();
  ctx.patchState({
    wizardState: {
      ...state.wizardState,
      currentStep: SignupState.maxStepNumber,
      isNextButtonEnabled: false,
      isPreviousButtonEnabled: false,
      errorOnRegister: null
    }
  });
}

}
