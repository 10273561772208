import {Directive, ElementRef, HostListener, Input} from '@angular/core';

// from git hub:
// https://github.com/thiagoprz/ionic-input-mask/tree/main/src/lib

@Directive({
  selector: '[appMask]',
})
export class MaskDirective {

  @Input('appMask') pattern: string = '';

  constructor(private el: ElementRef) {
    //public model: NgModel,
    //        @Attribute('appMask') pattern: string) {
    // this.pattern = pattern;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {
    let value = event.target.value;
    const pattern = this.pattern;
    if (event.keyIdentifier === 'U+0008' || event.keyCode === 8 || event.key === 'Backspace') {
      if (value.length) {
        // prevent fatal exception when backspacing empty value in progressive web app
        // remove all trailing formatting then delete character
        while (pattern[value.length] && pattern[value.length] !== '*') {
          value = value.substring(0, value.length - 1);
        }
        // remove all leading formatting to restore placeholder
        if (pattern.substring(0, value.length).indexOf('*') < 0) {
          value = value.substring(0, value.length - 1);
        }
      }
    } else {
      let maskIndex = value.length;
      let formatted = '';
      formatted += value;
      if (maskIndex < pattern.length) {
        // apply trailing formatting
        while (pattern[maskIndex] !== '*') {
          formatted += pattern[maskIndex];
          maskIndex++;
        }
      }
      value = formatted;
    }
    event.target.value = value;
    this.el.nativeElement.value = value;
    return true;
  }

}
