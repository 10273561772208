export class NumericRange {
    readonly minimum: number;
    readonly maximum: number;

    public constructor(minimum: number, maximum: number) {
        this.minimum = minimum;
        this.maximum = maximum;
    }

    public IsValid(): boolean {
        return this.minimum <= this.maximum;
    }

    public ContainsValue(value: number): boolean {
        return (this.minimum <= value) && (this.maximum >= value);
    }

    public Intersects(range: NumericRange | undefined): boolean {
        // manage 'undefined' values
        if (!range) {
            return false;
        }
        // operator && is executed first, then operator || is evaluated
        return this.IsValid() &&
            range.IsValid() &&
            (
                (range.ContainsValue(this.minimum) || range.ContainsValue(this.maximum)) ||
                (this.ContainsValue(range.minimum) || this.ContainsValue(range.maximum))
            );
    }
}
