import {AccountSignInUseCase} from '../../core/use-cases/account';
import {UseCaseActionName} from '../../core/utils';
import {AccountSignInUseCaseApp} from './account-sign-in-use-case-app.class';

export class AccountSignInUseCaseMock implements AccountSignInUseCase {

  constructor() {
  }

  dispose(): void {
  }

  getName(): string {
    return AccountSignInUseCaseApp.Name;
  }

  execute(action: UseCaseActionName, params?: { username: string; password: string }): void {
  }

  init(): void {
  }
}
