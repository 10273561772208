import {
  UokDetailContentInfoModel,
  UokDetailHeaderModel,
  UokDetailLockInfoModel,
  UokDetailUserInfoModel
} from './models';
import {ReferredInfoModel, UokLinkModel} from './models/link';
import {BookmarkState} from '../../utils';
import {SearchEngineItemModel} from '../search-engine';

export namespace UokDetailStateActions {
  export class LoadHeader {
    static readonly type = '[Uok Detail State] Load Header';

    constructor(public model: UokDetailHeaderModel) {
    }
  }

  export class LoadContentInfo {
    static readonly type = '[Uok Detail State] Load Content Info';

    constructor(public model: UokDetailContentInfoModel) {
    }
  }

  export class LoadLockInfo {
    static readonly type = '[Uok Detail State] Load Lock Info';

    constructor(public model: UokDetailLockInfoModel) {
    }
  }

  export class LoadUserInfo {
    static readonly type = '[Uok Detail State] Load User Info';

    constructor(public model: UokDetailUserInfoModel) {
    }
  }

  export class LoadLinks {
    static readonly type = '[Uok Detail State] Load Links';

    constructor(public model: UokLinkModel[]) {
    }
  }

  export class LoadLinksReferred {
    static readonly type = '[Uok Detail State] Load Links Referred';

    constructor(public model: ReferredInfoModel[]) {
    }
  }

  export class LoadKnowledgeTree {
    static readonly type = '[Uok Detail State] Load Knowledge Tree';

    constructor(public model: ReferredInfoModel[]) {
    }
  }

  export class LoadBookmarksState {
    static readonly type = '[Uok Detail State] Load Link Definitions';

    constructor(public state: BookmarkState) {
    }
  }

  export class ClearState {
    static readonly type = '[Uok Detail State] Clear State';
  }

  export class LoadContributedUoks {
    static readonly type = '[Uok Detail State] Load Contributed Uoks';

    constructor(public uoks: SearchEngineItemModel[]) {
    }
  }
}
