import {DraftInfoModel} from "./draft-info-model.interface";
import {IDtoTagInfo} from "../../../api/dtos/uok";
import {UploadProgress} from "../../../services/vimeo-uploader.service";

export namespace DraftStateActions {
  export class LoadExpiredDraftsInfo {
    static readonly type = '[Draft] Load Expired Draft Info List';

    constructor(public expiredDrafts: DraftInfoModel[]) {
    }
  }

  export class LoadRejectedDraftsInfo {
    static readonly type = '[Draft] Load Rejected Draft Info List';

    constructor(public rejectedDrafts: DraftInfoModel[]) {
    }
  }

  export class LoadSubmittedDraftsInfo {
    static readonly type = '[Draft] Load Submitted Draft Info List';

    constructor(public submittedDrafts: DraftInfoModel[]) {
    }
  }

  export class LoadWorkingDraftsInfo {
    static readonly type = '[Draft] Load Working Draft Info List';

    constructor(public workingDrafts: DraftInfoModel[]) {
    }
  }

  export class LoadDraftTagsInfo {
    static readonly type = '[Draft] Load Draft Tags Info List';

    constructor(public tags: IDtoTagInfo[]) {
    }
  }

  // export class ResetDrafts {
  //   static readonly type = '[Draft] Reset';
  // }
  //
  // export class InitCurrentDraftForm {
  //   static readonly type = '[Draft] Current Draft Form Initialization';
  //
  //   constructor(public draft: any | null) {
  //   }
  // }
  //
  // export class InitCurrentDraftDto {
  //   static readonly type = '[Draft] Current Draft Dto Initialization';
  //
  //   constructor(public draft: DraftDto | null) {
  //   }
  // }

  export class UpdateVideoUpload {
    static readonly type = '[Draft] Update video upload status';

    constructor(public status: UploadProgress) {
    }
  }

  export class ClearVideoUploads {
    static readonly type = '[Draft] Clear videos upload status';
  }
}
