import {UserGetAccessKeysUseCase} from '../../../core/use-cases/user';
import {UseCaseActionName} from '../../../core/utils';

export class UserGetAccessKeysUseCaseMock implements UserGetAccessKeysUseCase {



  getName(): string {
    return "";
  }

  dispose(): void {
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }
}
