import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Store} from "@ngxs/store";
import {TranslateConfig} from "../core/services/translate-config.class";

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigApp extends TranslateConfig {
  private static readonly localStorageLangKey = 'k-lang';
  private userStateLanguage: string | null = null;

  constructor(private translateService: TranslateService, private store: Store) {
    super();
    this.currentLang = localStorage.getItem(TranslateConfigApp.localStorageLangKey);

    this.store.select(state => state.user).subscribe(u => {
      this.userStateLanguage = u?.user?.cultureName ?? null;
    })
  }

  setLanguage(setLang: string) {
    this.translateService.use(setLang);
    localStorage.setItem(TranslateConfigApp.localStorageLangKey, setLang);
  }

  getCurrentLang(): string {
    if (this.userStateLanguage) {
      localStorage.setItem(TranslateConfigApp.localStorageLangKey, this.userStateLanguage);
      this.currentLang = this.userStateLanguage;
      this.translateService.use(this.currentLang);
    } else if (this.currentLang) {
      this.translateService.use(this.currentLang);
    } else {
      const langToStore = this.translateService.getBrowserLang() ?? TranslateConfig.defaultLanguage;
      localStorage.setItem(TranslateConfigApp.localStorageLangKey, langToStore);
      this.currentLang = langToStore;
      this.translateService.use(this.currentLang);
    }

    return this.currentLang;
    // return localStorage.getItem(TranslateConfigApp.localStorageLangKey) ?? TranslateConfig.defaultLanguage;
  }

  translate(key: string): string {
    return this.translateService.instant(key);
  }
}
