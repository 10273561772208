import {takeUntil} from "rxjs/operators";
import {BookmarkDto} from "src/app/api/dtos/uok-detail/bookmark-dto.interface";
import {UokDetailContentInfoDto} from "../../api/dtos/uok-detail/uok-detail-content-info-dto.interface";
import {UokDetailHeaderDto} from "../../api/dtos/uok-detail/uok-detail-header-dto.interface";
import {UokDetailLockInfoDto} from "../../api/dtos/uok-detail/uok-detail-lock-info-dto.interface";
import {UokDetailUserInfoDto} from "../../api/dtos/uok-detail/uok-detail-user-info-dto.interface";
import {UokDetailApiService} from "../../api/services/uok-detail-api.service";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../../core/abstractions/repositories/repository-response.interface";
import {UokDetailRepository} from "../../core/repositories/uok-detail-repository.class";
import {HttpUtils} from "../../core/utils/http-utils.class";

export class UokDetailRepositoryApi extends UokDetailRepository {
  constructor(private uokApi: UokDetailApiService) {
    super();
  }

  loadUokContentInfo(uokId: number, lang: string): void {
    this.uokApi.getContentInfo(uokId, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as UokDetailContentInfoDto;
          const tmp: RepositoryResponse<UokDetailContentInfoDto> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'UokContentInfo is null' : 'UokContentInfo received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokContentInfoResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<UokDetailContentInfoDto> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokContentInfoResponse(tmp);
        }
      });
  }

  loadUokHeader(uokId: number, lang: string): void {
    this.uokApi.getHeader(uokId, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as UokDetailHeaderDto;
          const tmp: RepositoryResponse<UokDetailHeaderDto> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'UokHeader is null' : 'UokHeader received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokHeaderResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<UokDetailHeaderDto> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokHeaderResponse(tmp);
        }
      });
  }

  loadUokLockInfo(uokId: number, lang: string): void {
    this.uokApi.getLockInfo(uokId, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as UokDetailLockInfoDto;
          const tmp: RepositoryResponse<UokDetailLockInfoDto> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'UokLockInfo is null' : 'UokLockInfo received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokLockInfoResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<UokDetailLockInfoDto> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokLockInfoResponse(tmp);
        }
      });
  }

  loadUokUserInfo(uokId: number, lang: string): void {
    this.uokApi.getUserInfo(uokId, lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as UokDetailUserInfoDto;
          const tmp: RepositoryResponse<UokDetailUserInfoDto> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'UokUserInfo is null' : 'UokUserInfo received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokUserInfoResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<UokDetailUserInfoDto> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setUokUserInfoResponse(tmp);
        }
      });
  }

  public loadUokBookmarkState(uokId: number): void {
    this.uokApi.getBookmarkState(uokId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const body = data as BookmarkDto;
          const tmp: RepositoryResponse<BookmarkDto> = {
            dto: body ?? null,
            info: {
              level: !body ? 1 : 0,
              messages: [
                !body ? 'BookmarkDto is null' : 'BookmarkDto received',
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadUokBookmarkStateResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponse<BookmarkDto> = {
            dto: null,
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setLoadUokBookmarkStateResponse(tmp);
        }
      });
  }

  public setUokBookmarkState(dto: BookmarkDto): void {
    this.uokApi.setBookmarkState(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: ['Bookmark state set']
            }
          }
          this.setIsUpdating(false);
          this.setSetUokBookmarkStateResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err)
              ]
            }
          }
          this.setIsUpdating(false);
          this.setSetUokBookmarkStateResponse(tmp);
        }
      });
  }
}


