import {UseCaseActionName} from "src/app/core/utils";
import {StripeSetupIntentConfirmUseCase} from "../../core/use-cases/stripe";
import {StripeElements} from "@stripe/stripe-js";

export class StripeSetupIntentConfirmUseCaseMock implements StripeSetupIntentConfirmUseCase {

  public static Name = 'StripeSetupIntentConfirm';

  init(): void {
    throw new Error("Method not implemented.");
  }

  execute(action: UseCaseActionName, params?: {
    stripeElements: StripeElements;
    returnUrl: string;
  } | undefined): void {
    throw new Error("Method not implemented.");
  }

  getName(): string {
    return StripeSetupIntentConfirmUseCaseMock.Name;
  }

  dispose(): void {
    throw new Error("Method not implemented.");
  }

}
