import {UokDetailUserInfoDto} from "../../api/dtos/uok-detail/uok-detail-user-info-dto.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {UokDetailUserInfoModel} from "../../core/state/detail/models/uok-detail-user-info-model.interface";

export class UokDetailUserInfoMapper extends Mapper<UokDetailUserInfoDto, UokDetailUserInfoModel> {
  mapFrom(param: UokDetailUserInfoDto): UokDetailUserInfoModel {
    const res: UokDetailUserInfoModel = {
      boundUserId: param.BoundUserId,
      contributors: [],
      firstContributorId: param.FirstContributorId,
      uokId: param.UokId
    };

    param.Contributors.forEach(c => {
      res.contributors.push({
        abstract: c.Abstract,
        dateOfPublish: c.DateOfPublish,
        id: c.Id,
        imageSmallUrl: c.ImageSmallUrl,
        name: c.Name,
        role: c.Role,
        version: c.Version
      })
    })

    return res;
  }

  mapTo(param: UokDetailUserInfoModel): UokDetailUserInfoDto {
    const res: UokDetailUserInfoDto = {
      BoundUserId: param.boundUserId,
      Contributors: [],
      FirstContributorId: param.firstContributorId,
      UokId: param.uokId
    };

    param.contributors.forEach(c => {
      res.Contributors.push({
        Abstract: c.abstract,
        DateOfPublish: c.dateOfPublish,
        Id: c.id,
        ImageSmallUrl: c.imageSmallUrl,
        Name: c.name,
        Role: c.role,
        Version: c.version
      })
    })

    return res;
  }

}
