import {UseCaseActionName} from "src/app/core/utils";
import {StripeCustomerGetUseCase} from "../../core/use-cases/stripe/stripe-customer-get-use-case.interface";

export class StripeCustomerGetUseCaseMock
  implements StripeCustomerGetUseCase {

  public static Name = 'StripeCustomerGetUseCase';

  init(): void {
    throw new Error("Method not implemented.");
  }

  execute(action: UseCaseActionName, params?: { email: string; } | undefined): void {
    throw new Error("Method not implemented.");
  }

  public getName(): string {
    return StripeCustomerGetUseCaseMock.Name;
  }

  dispose(): void {
    throw new Error("Method not implemented.");
  }
}

