import { takeUntil } from 'rxjs/operators';
import { ISendEmailValidationCodeDto } from 'src/app/api/dtos/user/send-email-validation-code-dto.interface';
import { UserRepository } from '../../core/repositories';
import { UserApiService } from '../../api/services';
import { IDtoUserCashInfo, IDtoUserOperator, IUserRegistrationRequestDto } from '../../api/dtos/user';
import { RepositoryResponse, RepositoryResponseGeneric } from '../../core/abstractions/repositories';
import { HttpUtils } from '../../core/utils';

export class UserRepositoryApi extends UserRepository {
  constructor(private userApi: UserApiService) {
    super();
  }

  public updateUser(user: IDtoUserOperator): void {
    this.userApi.update(user)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          //console.log(data);
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                'UserDto updated',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setUserUpdateResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setUserUpdateResponse(tmp);
        },
      });
  }

  getUserStateModel(updateLoginData: boolean): void {
    this.userApi.getUserInfo(updateLoginData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            //console.log(data);
            const body = data as IDtoUserOperator;
            const tmp: RepositoryResponse<IDtoUserOperator> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'UserDto is null' : 'UserDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserStateResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoUserOperator> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserStateResponse(tmp);
          },
        },
      );
  }

  public register(dto: IUserRegistrationRequestDto): void {
    this.userApi.register(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                'User Registered',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setRegisterResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setRegisterResponse(tmp);
        },
      });
  }

  public sendValidationCodeEmail(dto: ISendEmailValidationCodeDto): void {
    this.userApi.sendValidationEmail(dto)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 0,
              messages: [
                'Validation Code sent',
              ],
            },
          };
          this.setIsUpdating(false);
          this.setSendValidationCodeEmailResponse(tmp);
        },
        error: err => {
          const tmp: RepositoryResponseGeneric = {
            info: {
              level: 1,
              messages: [
                HttpUtils.getHttpErrorMessages(err),
              ],
            },
          };
          this.setIsUpdating(false);
          this.setSendValidationCodeEmailResponse(tmp);
        },
      });
  }

  public getUserCash(): void {
    this.userApi.getUserCashInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            //console.log(data);
            const body = data as IDtoUserCashInfo;
            const tmp: RepositoryResponse<IDtoUserCashInfo> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'UserCashInfoDto is null' : 'UserCashInfoDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserCashResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoUserCashInfo> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserCashResponse(tmp);
          },
        },
      );
  }

  setUserCashToProcessing(): void {
    this.userApi.setUserCashToProcessing()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
          next: data => {
            //console.log(data);
            const body = data as IDtoUserCashInfo;
            const tmp: RepositoryResponse<IDtoUserCashInfo> = {
              dto: body ?? null,
              info: {
                level: !body ? 1 : 0,
                messages: [
                  !body ? 'UserCashInfoDto is null' : 'UserCashInfoDto received',
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserCashResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponse<IDtoUserCashInfo> = {
              dto: null,
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err),
                ],
              },
            };
            this.setIsUpdating(false);
            this.setUserCashResponse(tmp);
          },
        },
      );
  }


}
