import {takeUntil} from "rxjs/operators";
import {IProductDto} from "../../api/dtos/products/product-dto.interface";
import {ProductApiService} from "../../api/services/product-api.service";
import {
  RepositoryResponse,
  RepositoryResponseGeneric
} from "../../core/abstractions/repositories/repository-response.interface";
import {ProductRepository} from "../../core/repositories/product-repository.class";
import {HttpUtils} from "../../core/utils/http-utils.class";

export class ProductRepositoryApi extends ProductRepository {
  constructor(private productApi: ProductApiService) {
    super();
  }

  loadProducts(lang: string): void {
    this.productApi.load(lang)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
      next: data => {
        const body = data as IProductDto[];
        const tmp: RepositoryResponse<IProductDto[]> = {
          dto: body ?? null,
          info: {
            level: !body ? 1 : 0,
            messages: [
              !body ? 'ProductDto[] is null' : 'ProductDto[] received',
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadProductsResponse(tmp);
      },
      error: err => {
        const tmp: RepositoryResponse<IProductDto[]> = {
          dto: null,
          info: {
            level: 1,
            messages: [
              HttpUtils.getHttpErrorMessages(err)
            ]
          }
        }
        this.setIsUpdating(false);
        this.setLoadProductsResponse(tmp);
      }
    });
  }


  activateProduct(productId: number, userEmail: string, userName: string, userSurname: string): void {
    this.productApi.activate(productId, userEmail, userName, userSurname)
      .subscribe({
          next: () => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 0,
                messages: [
                  'Product Activated'
                ]
              }
            }
            this.setIsUpdating(false);
            this.setActivateProductResponse(tmp);
          },
          error: err => {
            const tmp: RepositoryResponseGeneric = {
              info: {
                level: 1,
                messages: [
                  HttpUtils.getHttpErrorMessages(err)
                ]
              }
            }
            this.setIsUpdating(false);
            this.setActivateProductResponse(tmp);
          }
        }
      );
  }

}
