import {SearchEngineItemModel} from "./search-engine-item-model.interface";
import {SearchEngineQuery} from "./search-engine-state-model.interface";
import {SearchEnginePaginatedResultModel} from "./search-engine-paginated-result-model.interface";

export namespace SearchEngineActions {
    export class SearchEngineGetByDtoSucceded {
        static readonly type = '[SearchEngine] Get By Dto Succeded';

        constructor(public result: SearchEnginePaginatedResultModel) {
        }
    }

    export class SearchEngineSetQuery {
        static readonly type = '[SearchEngine] Set Query';

        constructor(public query: SearchEngineQuery) {
        }
    }

    export class SearchEngineClear {
        static readonly type = '[SearchEngine] Clear';
    }

    export class SearchEngineGetBookmarksBookmarkedSucceded {
        static readonly type = '[SearchEngine] Get Bookmarks bookmarked';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetBookmarksArchivedSucceded {
        static readonly type = '[SearchEngine] Get Bookmarks archived';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetBookmarksRecentSucceded {
        static readonly type = '[SearchEngine] Get Bookmarks recent';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetByNameStartsWithSucceded {
        static readonly type = '[SearchEngine] Get By Name Starts With';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetByNameSucceded {
        static readonly type = '[SearchEngine] Get By Name';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetHighlightedSucceded {
        static readonly type = '[SearchEngine] Get Highlighted';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetCoursesSucceded {
        static readonly type = '[SearchEngine] Get Courses';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetContributedByUokAuthorSucceded {
        static readonly type = '[SearchEngine] Get Contributed By Uok Author';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetContributedSucceded {
        static readonly type = '[SearchEngine] Get Contributed';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetDisabledSucceded {
        static readonly type = '[SearchEngine] Get Disabled';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetLatestSucceded {
        static readonly type = '[SearchEngine] Get Latest';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetOperatorsByTextSucceded {
        static readonly type = '[SearchEngine] Get Operators By Text';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetPublishedSucceded {
        static readonly type = '[SearchEngine] Get Published';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class SearchEngineGetSignaledSucceded {
        static readonly type = '[SearchEngine] Get Signaled';

        constructor(public results: SearchEngineItemModel[]) {
        }
    }

    export class ResetSearchEngine {
        static readonly type = '[SearchEngine] Reset';

        constructor() {
        }
    }
}
