import {UseCaseActionName} from '../../core/utils';
import {StripeLoadInfoUseCaseApp} from './stripe-load-info-use-case-app.class';
import { StripeLoadInfoUseCase } from '../../core/use-cases/stripe';

export class StripeLoadInfoUseCaseMock implements StripeLoadInfoUseCase {
  dispose(): void {
  }


  public getName(): string {
    return StripeLoadInfoUseCaseApp.Name;
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }
}
