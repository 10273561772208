export enum UokStates {
  None = 0x00,
  /** Uok is currently in use */
  Active = 0x01,
  /** Uok is under revision by the scientific team */
  Reviewing = 0x02,
  /** The Uok was replaced by a new one */
  Obsolete = 0x04,
  /** Uok is signaled to the system */
  Signaled = 0x08,
  /** Uok is not available to the system */
  Disabled = 0x010,
  /** Uok is not modifiable */
  NonModifiable = 0x20,
}
