import {UserLoadCashInfoUseCase} from '../../../core/use-cases/user';
import {UseCaseActionName} from '../../../core/utils';

export class UserLoadCashInfoUseCaseMock implements UserLoadCashInfoUseCase {
  public static readonly Name: string = 'UserLoadCashInfo';

  dispose(): void {
  }

  getName(): string {
    return "";
  }

  execute(action: UseCaseActionName, params?: null): void {
  }

  init(): void {
  }

}
