import {IChangePasswordRequestDto, IUserLoginResponseDto} from "../../api/dtos/security";
import {RepositoryResponse} from '../../core/abstractions/repositories';
import {AccountRepository} from '../../core/repositories';

export class AuthRepositoryMock extends AccountRepository {
  public login(username: string, password: string, captchaToken: string): void {
    this.setIsUpdating(true);
    let tmp: RepositoryResponse<IUserLoginResponseDto> = {
      dto: {
        access_token: 'TESTTOKEN',
        expires_in: 0,
        userName: username
      },
      info: {
        level: 0,
        messages: [
          'message ok'
        ]
      }
    }
    this.setIsUpdating(false);
    this.setLoginAuthStateResponse(tmp);
  }

  changePassword(dto: IChangePasswordRequestDto): void {
    this.setChangePasswordStateResponse({
      info: {
        level: 0,
        messages: [
          'message ok'
        ]
      }
    });
  }

  logout(): void {
    throw new Error('Method not implemented.');
  }
}
