import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { UseCaseStateName } from 'src/app/core/utils/use-case-state-name.enum';
import { StripeRepository } from '../../core/repositories';
import { UserStripeInfoActions } from '../../core/state/user/actions';
import { DtoUtils } from '../../core/utils';
import { RepositoryFactory } from '../../data';
import { StripeInfoMapper } from '../../data/user-repository';
import { UseCaseAppBase } from '../use-case-app-base.class';
import { StripeLoadInfoUseCase } from '../../core/use-cases/stripe';

export class StripeLoadInfoUseCaseApp extends UseCaseAppBase<null> implements StripeLoadInfoUseCase {
  public static readonly Name: string = 'UserLoadStripeInfo';

  private stripeMapper = new StripeInfoMapper();
  private stripeRepository: StripeRepository = RepositoryFactory.GetStripeRepository();

  constructor(store: Store) {
    super(store);

    this.stripeRepository.userStripeInfoResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            // this.dispatchSetFailureWithAlert(
            //   { localizationKey: 'UseCase.UserLoadStripeInfo.Failure.Message', params: DtoUtils.getMessage(response) },
            //   { localizationKey: 'UseCase.UserLoadStripeInfo.Failure.Header' },
            //   { localizationKey: 'UseCase.UserLoadStripeInfo.Failure.SubHeader' });

            this.dispatchSetStateWithNone(UseCaseStateName.failure, 'UseCase.UserLoadStripeInfo.Failure.Message', undefined,  { localizationKey: 'UseCase.UserLoadStripeInfo.Failure.Message', params: DtoUtils.getMessage(response) });

          } else {
            this.dispatch(new UserStripeInfoActions.Succeded(this.stripeMapper.mapFrom(response.dto)));
            this.dispatchSetStateWithNone(UseCaseStateName.success, 'UseCase.UserLoadStripeInfo.Success');
          }
        },
      });
  }

  override init(): void {
    this.dispatchInitWithMessage('UseCase.UserLoadStripeInfo.Initialized');
  }

  override onExecuteRun(_params?: null | undefined): void {
    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.UserLoadStripeInfo.Working');
    this.stripeRepository.getUserStripeInfo();
  }

  public getName(): string {
    return StripeLoadInfoUseCaseApp.Name;
  }

  override onDispose() {
    this.stripeRepository.dispose();
  }
}
