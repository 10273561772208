import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {UseCaseNotificationModel, UseCasesActions, UseCasesState} from '../../../core/state/use-cases';
import {UseCaseActivityDisplayMode} from '../../../core/utils';
import {ToastController} from '../controllers/toast-controller.class';

export class UseCasesNotificationHandler {
    private destroy$: Subject<boolean> = new Subject<boolean>();
    private notificationsToShow$: Observable<UseCaseNotificationModel[]>;

    constructor(private store: Store,
                private translate: TranslateService,
                private toastController: ToastController) {

        this.notificationsToShow$ = this.store.select(UseCasesState.notificationToShow);

        this.notificationsToShow$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (notifications) => {
                if (!notifications) {
                    return;
                }

                for (const notification of notifications) {
                    this.displayNotification(notification);
                    this.store.dispatch(new UseCasesActions.SetNotificationAsShown(notification));
                }
            })
    }

    public dispose(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private displayNotification(notification: UseCaseNotificationModel) {
        switch (notification.displayMode) {
            case UseCaseActivityDisplayMode.none: {
                if (!environment.production) {

                    this.translate.get(notification.message.localizationKey)
                        .subscribe((msg: string) => {
                            // console.log(msg);
                        });

                }
            }
                break;
            case UseCaseActivityDisplayMode.toast: {
                this.translate.get(notification.message.localizationKey, notification.message.params)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((msg: string) => {
                        this.toastController.displayToastItem({
                            header: msg,
                            id: notification.id,
                            severity: notification.level
                        })
                    });
            }
                break;
            case UseCaseActivityDisplayMode.alert: {
                this.translate.get(notification.message.localizationKey)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((msg: string) => {
                        this.toastController.displayToastItem({
                            header: msg,
                            id: notification.id,
                            severity: notification.level
                        })
                    })
            }
                break;
        }
    }
}
