import {
  DtoStripeCustomerGetRequest,
  DtoStripeSetupIntentGetRequest,
  DtoStripeSubscriptionApplyAccessKeyRequest,
  DtoStripeSubscriptionCreateRequest,
  IDtoStripePaymentMethodUpdateFinalizeRequest,
} from 'src/app/api/dtos/stripe';
import { StripeRepository } from '../../core/repositories';
import { StripeElements } from '@stripe/stripe-js';

export class StripeRepositoryMock extends StripeRepository {
  public override subscriptionApplyAccessKey(dto: DtoStripeSubscriptionApplyAccessKeyRequest): void {
    throw new Error('Method not implemented.');
  }

  public override subscriptionCreate(dto: DtoStripeSubscriptionCreateRequest): void {
    throw new Error('Method not implemented.');
  }

  public override paymentConfirm(stripeElements: StripeElements, returnUrl: string): void {
    throw new Error('Method not implemented.');
  }

  public override setupIntentGet(dto: DtoStripeSetupIntentGetRequest): void {
    throw new Error('Method not implemented.');
  }

  public override setupIntentConfirm(stripeElements: StripeElements, returnUrl: string): void {
    throw new Error('Method not implemented.');
  }

  public override customerGet(dto: DtoStripeCustomerGetRequest): void {
    throw new Error('Method not implemented.');
  }

  updatePaymentMethodFinalize(dto: IDtoStripePaymentMethodUpdateFinalizeRequest): void {
    throw new Error('Method not implemented.');
  }

  getUserStripeInfo(): void {
    throw new Error('Method not implemented.');
  }

  userResubscribe(): void {
    throw new Error('Method not implemented.');
  }

  userUnsubscribe(): void {
    throw new Error('Method not implemented.');
  }
}
