import {IDtoUserCashInfo} from "../../api/dtos/user/dto-user-cash-info.interface";
import {Mapper} from "../../core/abstractions/repositories/mapper.class";
import {UserCashInfoModel} from "../../core/state/user/models/user-cash-info-model.interface";

export class UserCashMapper implements Mapper<IDtoUserCashInfo, UserCashInfoModel> {
  mapFrom(param: IDtoUserCashInfo): UserCashInfoModel {
    const tmp: UserCashInfoModel = {
      total: param.Total,
      history: []
    }
    param.History.forEach(h => {
      tmp.history.push({
        credit: h.Credit,
        debit: h.Debit,
        month: h.Month,
        status: h.Status,
        year: h.Year
      })
    })

    return tmp;
  }

  mapTo(param: UserCashInfoModel): IDtoUserCashInfo {
    const tmp: IDtoUserCashInfo = {
      Total: param.total,
      History: []
    }
    param.history.forEach(h => {
      tmp.History.push({
        Credit: h.credit,
        Debit: h.debit,
        Month: h.month,
        Status: h.status,
        Year: h.year
      })
    })

    return tmp;
  }

}
