import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import { StripeRepository } from '../../core/repositories';
import {DtoUtils, UseCaseStateName} from '../../core/utils';
import {RepositoryFactory} from '../../data';
import {UseCaseAppBase} from '../use-case-app-base.class';
import { StripeResubscribeUseCase } from '../../core/use-cases/stripe';

export class StripeResubscribeUseCaseApp extends UseCaseAppBase<null> implements StripeResubscribeUseCase {
  public static readonly Name: string = 'UserResubscribe';
  private stripeRepository: StripeRepository=RepositoryFactory.GetStripeRepository();

  constructor(store: Store) {
    super(store);

    this.stripeRepository.userResubscribeResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response && response.info?.level === 0) {
            this.dispatchSetStateWithToast(UseCaseStateName.success, "UseCase.UserResubscribe.Success");
          } else {
            this.dispatchSetFailureWithAlert(
              {localizationKey: "UseCase.UserResubscribe.Failure.Message", params: DtoUtils.getMessage(response)},
              {localizationKey: "UseCase.UserResubscribe.Failure.Header"},
              {localizationKey: "UseCase.UserResubscribe.Failure.SubHeader"});
          }
        }
      })
  }

  override init(): void {
    this.dispatchInitWithMessage("UseCase.UserResubscribe.Initialized");
  }

  override onExecuteRun(_params?: null | undefined): void {
    this.dispatchSetStateWithToast(UseCaseStateName.working, "UseCase.UserResubscribe.Working");
    this.stripeRepository.userResubscribe();
  }

  getName(): string {
    return StripeResubscribeUseCaseApp.Name;
  }

  override onDispose() {
    this.stripeRepository.dispose();
  }
}
