import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[appAutoResize]',
})
export class AutoResizeDirective implements AfterViewInit {

  constructor(private el: ElementRef) {
  }

  ngAfterViewInit() {
    this.resize();
  }

  @HostListener('input')
  onInput() {
    this.resize();
  }

  resize() {
    const textarea = this.el.nativeElement;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = textarea.scrollHeight + 'px'; // Set it to the scroll height
    textarea.style.overflow = 'hidden'; // Hide scrollbars
    textarea.style.resize = 'none';
  }
}
