import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StripeStateModel } from './stripe-state-model.interface';
import { StripeStateActions } from './stripe-state-actions';

@State<StripeStateModel>({
    name: 'stripe',
    defaults: {
      isCreatingPayment: false,
      isCreatingSubscription: false,
      isCreatingSetupIntent: false,
      isCreatingCustomer: false,
      paymentIntent: undefined,
      subscription: undefined,
      setupIntent: undefined,
      customer: undefined,
      isHandlingCallback: false,
    },
  },
)
@Injectable()
export class StripeState {
  @Selector()
  static isHandlingCallback(state: StripeStateModel) {
    return state.isHandlingCallback;
  }

  @Selector()
  static isWorking(state: StripeStateModel) {
    return state.isCreatingSubscription || state.isCreatingPayment || state.isCreatingSetupIntent;
  }

  @Selector()
  static paymentIntent(state: StripeStateModel) {
    return state.paymentIntent;
  }

  @Selector()
  static subscription(state: StripeStateModel) {
    return state.subscription;
  }

  @Selector()
  static setupIntent(state: StripeStateModel) {
    return state.setupIntent;
  }

  @Selector()
  static customer(state: StripeStateModel) {
    return state.customer;
  }

  @Action(StripeStateActions.Init)
  init(ctx: StateContext<StripeStateModel>) {
    ctx.setState({
      isCreatingPayment: false,
      isCreatingSubscription: false,
      isCreatingSetupIntent: false,
      isCreatingCustomer: false,
      paymentIntent: undefined,
      subscription: undefined,
      setupIntent: undefined,
      customer: undefined,
      isHandlingCallback: false,
    });
  }

  @Action(StripeStateActions.SetupIntentGetRequested)
  setupIntentGetRequested(ctx: StateContext<StripeStateModel>) {
    ctx.patchState({
      isCreatingSetupIntent: true,
      setupIntent: undefined,
    });
  }

  @Action(StripeStateActions.SetupIntentGetCompleted)
  setupIntentGetCompleted(ctx: StateContext<StripeStateModel>, action: StripeStateActions.SetupIntentGetCompleted) {
    ctx.patchState({
      isCreatingSetupIntent: false,
      setupIntent: {
        setupIntent: action.setupInfo.setupIntent,
        paymentMethod: action.setupInfo.paymentMethod,
        setupIntentStatus: action.setupInfo.setupIntentStatus,
        setupIntentClientSecret: action.setupInfo.setupIntentClientSecret,
      },
    });
  }

  @Action(StripeStateActions.SubscriptionCreateRequested)
  subscriptionCreateRequested(ctx: StateContext<StripeStateModel>) {
    ctx.patchState({
      isCreatingSubscription: true,
      subscription: undefined,
    });
  }

  @Action(StripeStateActions.SubscriptionCreateCompleted)
  subscriptionCreateCompleted(ctx: StateContext<StripeStateModel>, action: StripeStateActions.SubscriptionCreateCompleted) {
    const state = ctx.getState();
    state.subscription;
    ctx.patchState({
      isCreatingSubscription: false,
      subscription: action.response,
    });
  }

  @Action(StripeStateActions.PaymentIntentConfirmRequested)
  createPaymentIntentRequested(ctx: StateContext<StripeStateModel>) {
    ctx.patchState({
      isCreatingPayment: true,
      paymentIntent: undefined,
    });
  }


  @Action(StripeStateActions.PaymentIntentConfirmCompleted)
  createPaymentIntentCompleted(ctx: StateContext<StripeStateModel>, action: StripeStateActions.PaymentIntentConfirmCompleted) {
    ctx.patchState({
      isCreatingPayment: false,
      paymentIntent: action.paymentInfo,
    });
  }


  @Action(StripeStateActions.SetupIntentConfirmRequested)
  createSetupIntentRequested(ctx: StateContext<StripeStateModel>) {
    ctx.patchState({
      isCreatingSetupIntent: true,
      setupIntent: undefined,
    });
  }

  @Action(StripeStateActions.SetupIntentConfirmCompleted)
  createSetupIntentCompleted(ctx: StateContext<StripeStateModel>, action: StripeStateActions.SetupIntentConfirmCompleted) {
    ctx.patchState({
      isCreatingSetupIntent: false,
      setupIntent: {
        setupIntent: action.setupInfo.setupIntent,
        paymentMethod: action.setupInfo.paymentMethod,
        setupIntentStatus: action.setupInfo.setupIntentStatus,
        setupIntentClientSecret: action.setupInfo.setupIntentClientSecret,
      },
    });
  }


  @Action(StripeStateActions.CustomerGetRequested)
  customerRequested(ctx: StateContext<StripeStateModel>) {
    ctx.patchState({
      isCreatingCustomer: true,
      customer: undefined,
    });
  }

  @Action(StripeStateActions.CustomerGetCompleted)
  customerCompleted(ctx: StateContext<StripeStateModel>, action: StripeStateActions.CustomerGetCompleted) {
    ctx.patchState({
      isCreatingCustomer: false,
      customer: {
        customerId: action.customerInfo.customerId,
        planId: action.customerInfo.planId,
        planPrice: action.customerInfo.planPrice,
      },
    });
  }

  @Action(StripeStateActions.SetIsHandlingCallbackFlag)
  setIsHandlingCallbackFlag(ctx: StateContext<StripeStateModel>, action: StripeStateActions.SetIsHandlingCallbackFlag) {
    ctx.patchState({
      isHandlingCallback: action.working,
    });
  }
}
