import {ProductModel} from "./product-model.interface";

export namespace ProductActions {
    export class All {
        static readonly type = '[Product State] Load All Products';

        constructor(public models: ProductModel[]) {
        }
    }

    export class Expired {
        static readonly type = '[Product State] Load Expired Products';

        constructor(public models: ProductModel[]) {
        }
    }

    export class Available {
        static readonly type = '[Product State] Load Available Products';

        constructor(public models: ProductModel[]) {
        }
    }

    export class Bought {
        static readonly type = '[Product State] Load Bought Products';

        constructor(public models: ProductModel[]) {
        }
    }

    export class LoadAdmin {
        static readonly type = '[Product State] Load Admin Products';

        constructor(public models: ProductModel[]) {
        }
    }

    export class ResetProducts {
        static readonly type = '[Product State] Reset Products';

        constructor() {
        }
    }
}
