import {Injectable} from "@angular/core";
import {Action, Selector, State, StateContext} from "@ngxs/store";
import {patch} from "@ngxs/store/operators";
import {DateAndTime} from "../../utils";
import {UseCaseActivityDisplayMode} from "../../utils";
import {UseCaseStateName} from "../../utils";
import {UseCaseNotificationModel} from "./use-case-notification-model.interface";
import {UseCaseStateModel} from "./use-case-state-model.interface";
import {UseCasesActions} from "./use-cases-actions";
import {UseCasesStateModel} from "./use-cases-state-model.interface";
import {MessageActivityLevel} from "../../utils/message-activity-level.enum";

@State<UseCasesStateModel>({
    name: 'useCases',
    defaults: {
        useCasesState: [],
        useCasesNotifications: []
    }
})
@Injectable()
export class UseCasesState {
    private static notificationId = 1;

    @Selector()
    static notificationToShow(state: UseCasesStateModel): UseCaseNotificationModel[] {
        const notifications: UseCaseNotificationModel[] = [];

        state.useCasesNotifications.forEach(notification => {
            if (notification.toShow) {
                notifications.push(notification);
            }
        });

        return notifications
    }

    @Selector()
    static isUseCaseWorking(state: UseCasesStateModel) {
        return (useCaseName: string) => {
            const found = state.useCasesState.find((obj) => {
                return obj.useCaseName === useCaseName && obj.currentState === UseCaseStateName.working;
            });

            return found !== undefined
        }
    }

    @Selector()
    static isUseCaseEdit(state: UseCasesStateModel) {
        return (useCaseName: string) => {
            const found = state.useCasesState.find((obj) => {
                return obj.useCaseName === useCaseName &&
                    (obj.currentState === UseCaseStateName.edit || obj.currentState === UseCaseStateName.failure);
            });

            return found !== undefined
        }
    }

    @Selector()
    static useCaseState(state: UseCasesStateModel) {
        return (useCaseName: string) => {
            const found = state.useCasesState.find((obj) => {
                return obj.useCaseName === useCaseName;
            });

            return found?.currentState
        }
    }

    @Selector()
    static isUseCaseFailure(state: UseCasesStateModel) {
        return (useCaseName: string) => {
            const found = state.useCasesState.find((obj) => {
                return obj.useCaseName === useCaseName && obj.currentState === UseCaseStateName.failure;
            });

            return found !== undefined;
        }
    }

    @Selector()
    static isUseCaseSuccess(state: UseCasesStateModel) {
        return (useCaseName: string) => {
            const found = state.useCasesState.find((obj) => {
                return obj.useCaseName === useCaseName && obj.currentState === UseCaseStateName.success;
            });

            return found !== undefined;
        }
    }

    @Selector()
    static lastUseCaseError(state: UseCasesStateModel) {
        return (useCaseName: string) => {

            const sorted = state.useCasesNotifications.sort(
                (a, b) =>
                    a.timestamp.getTime() - b.timestamp.getTime());

            const found = sorted.find((obj) => {
                return obj.useCaseName === useCaseName && obj.level === MessageActivityLevel.error;
            });

            return found;
        }
    }


    @Action(UseCasesActions.Init)
    UseCasesInit(ctx: StateContext<UseCasesStateModel>, action: UseCasesActions.Init) {
        const currentUseCases = [...ctx.getState().useCasesState];
        const foundUc = currentUseCases.find(uc =>
            (uc.useCaseName === action.useCaseName));

        const now = DateAndTime.now();
        if (foundUc === null || foundUc === undefined) {
            const ucState: UseCaseStateModel = {
                currentState: UseCaseStateName.initialized,
                useCaseName: action.useCaseName,
                lastUpdateTimestamp: now
            }
            currentUseCases.push(ucState);
        } else {
            foundUc.currentState = UseCaseStateName.initialized;
            foundUc.lastUpdateTimestamp = now;
        }
        const currentNotifications = [...ctx.getState().useCasesNotifications];
        const ucNotification: UseCaseNotificationModel = {
            id: UseCasesState.notificationId++,
            useCaseName: action.useCaseName,
            level: MessageActivityLevel.info,
            displayMode: UseCaseActivityDisplayMode.none,
            toShow: true,
            timestamp: now,
            message: action.message
        }
        currentNotifications.push(ucNotification);

        ctx.setState(
            patch<UseCasesStateModel>({
                useCasesState: currentUseCases,
                useCasesNotifications: currentNotifications
            })
        );
    }

    @Action(UseCasesActions.SetState)
    UseCasesSetState(ctx: StateContext<UseCasesStateModel>, action: UseCasesActions.SetState) {
        const currentUseCases = [...ctx.getState().useCasesState];
        const foundUc = currentUseCases.find(uc =>
            (uc.useCaseName === action.useCaseName));

        if (foundUc === undefined || foundUc === null) {
            return;
        }

        const now = DateAndTime.now();
        foundUc.lastUpdateTimestamp = now;
        foundUc.currentState = action.targetState;

        const ucNotification: UseCaseNotificationModel = {
            id: UseCasesState.notificationId++,
            useCaseName: action.useCaseName,
            level: action.level === undefined ? MessageActivityLevel.info : action.level,
            displayMode: action.mode === undefined ? UseCaseActivityDisplayMode.toast : action.mode,
            toShow: true,
            timestamp: now,
            message: action.message,
            header: action.header,
            subHeader: action.subHeader
        }

        const currentNotifications = [...ctx.getState().useCasesNotifications];
        currentNotifications.push(ucNotification);

        ctx.setState(
            patch<UseCasesStateModel>({
                useCasesState: currentUseCases,
                useCasesNotifications: currentNotifications
            })
        );
    }

    @Action(UseCasesActions.SetNotificationAsShown)
    UseCasesSetNotificationAsShown(ctx: StateContext<UseCasesStateModel>, action: UseCasesActions.SetNotificationAsShown) {
        const currentNotifications = [...ctx.getState().useCasesNotifications];
        const found = currentNotifications.find(n => n.id === action.notification.id);

        if (!found) {
            return;
        }

        found.toShow = false;

        ctx.setState(
            patch<UseCasesStateModel>({
                useCasesNotifications: currentNotifications
            })
        );
    }

    @Action(UseCasesActions.Reset)
    UseCasesReset(ctx: StateContext<UseCasesStateModel>) {
        ctx.setState({
            useCasesNotifications: [],
            useCasesState: []
        })
    }
}
