import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AccountState} from '../../core/state/account';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private store: Store) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // skip vimeo requests
    if (req.url.startsWith("https://api.vimeo.com")) {
      return next.handle(req);
    }

    // Get the auth token from the store.
    const token = this.store.selectSnapshot(AccountState.token);
    const isContentSet = !!req.headers.get('Content-Type');

    let cloned = req.clone();
    if (!isContentSet) {
      cloned = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (!token) {
      return next.handle(cloned);
    }

    // if (!cloned.url.startsWith(environment.webApiV1)) {
    //   return next.handle(cloned);
    // }

    const authReq = cloned.clone({
      headers: cloned.headers.set('Authorization', 'Bearer ' + token)
    });

    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
