import {UserModel} from "../models/user-model.interface";

export namespace UserActions {
  export class GetModelRequested {
    static readonly type = '[User] Get Model Requested';

    constructor(public username: string) {
    }
  }

  export class GetModelSucceded {
    static readonly type = '[User] Get Model Succeded';

    constructor(public userModel: UserModel) {
    }
  }

  export class GetModelFailed {
    static readonly type = '[User] Get Model Failed';

  }

  export class ClearModelSucceded {
    static readonly type = '[User] Clear Model Succeded';

    constructor() {
    }
  }

  export class RefreshCurrentData{
    static readonly type = '[User] Refresh Current Data';

    constructor() {
    }
  }
}
