import {Injectable} from '@angular/core';
import {db} from './DraftCustomDb';
import {IDtoDraft} from '../../api/dtos/uok';

@Injectable({providedIn: 'root'})
export class DraftStorageEngine {
  length: number = 0;

  async getItem(key: string) {
    return db.draftData.get(key);
  }

  async setItem(key: string, model: any, dto: IDtoDraft) {
    const tmp = await db.draftData.get(key);
    if (tmp) {
      await db.draftData.update(key, {model: JSON.stringify(model), dto: JSON.stringify(dto)});
    } else {
      await db.draftData.add({model: JSON.stringify(model), dto: JSON.stringify(dto), key: key});
    }
  }

  async clearItem(key: string) {
    await db.draftData.update(key, {model: null, dto: null});
  }

  async removeItem(key: string) {
    await db.draftData.delete(key);
  }

  async clear() {
    await db.draftData.clear();
  }

  async getSetting(key: string) {
    return db.draftSettings.get(key);
  }

  async setSetting(key: string, value: any) {
    const tmp = await db.draftSettings.get(key);
    if (tmp) {
      await db.draftSettings.update(key, {data: value});
    } else {
      await db.draftSettings.add({data: value, key: key});
    }
  }
}
