import { IDtoSurveyTemplate, IDtoSurveyTemplateQuestion } from '../../api/dtos/surveys';
import {Mapper} from '../../core/abstractions/repositories';
import {
  SurveyTemplateModel,
  SurveyTemplateQuestionModel, SurveyTemplateSectionModel,
} from '../../core/state/survey';
import { IDtoSurveyTemplateSection } from '../../api/dtos/surveys/dto-survey-template-section.interface';

export class SurveyTemplateMapper implements Mapper<IDtoSurveyTemplate, SurveyTemplateModel> {
  mapFrom(param: IDtoSurveyTemplate): SurveyTemplateModel {
    const res: SurveyTemplateModel = {
      certificateId: param.CertificateId,
      createdByUserId: param.CreatedByUserId,
      cultureName: param.CultureName,
      dbId: param.DbId,
      numberOfRetries: param.NumberOfRetries,
      operatorId: param.OperatorId,
      sections: [],
      state: param.State,
      summary: param.Summary,
      systemName: param.SystemName,
      title: param.Title,
      type: param.Type,
      validFrom: param.ValidFrom,
      validTo: param.ValidTo
    };

    param.Sections.forEach(s => {
      const section: SurveyTemplateSectionModel ={
        dbId: s.DbId,
        ordinal: s.Ordinal,
        caption: s.Caption,
        summary: s.Summary,
        questions: []
      }

      s.Questions.forEach(q =>{
        const question: SurveyTemplateQuestionModel = {
          availableAnswers: [],
          caption: q.Caption,
          dbId: q.DbId,
          ordinal: q.Ordinal,
          summary: q.Summary,
          systemName: q.SystemName
        }

        q.AvailableAnswers.forEach(a => {
          question.availableAnswers.push({
            caption: a.Caption,
            dbId: a.DbId,
            showNotApplicable: a.ShowNotApplicable,
            summary: a.Summary
          })
        })

        section.questions.push(question);
      });

      res.sections.push(section);
    })

    return res;
  }

  mapTo(param: SurveyTemplateModel): IDtoSurveyTemplate {
    const res: IDtoSurveyTemplate = {
      CertificateId: param.certificateId,
      CreatedByUserId: param.createdByUserId,
      CultureName: param.cultureName,
      DbId: param.dbId,
      NumberOfRetries: param.numberOfRetries,
      OperatorId: param.operatorId,
      Sections: [],
      State: param.state,
      Summary: param.summary,
      SystemName: param.systemName,
      Title: param.title,
      Type: param.type,
      ValidFrom: param.validFrom,
      ValidTo: param.validTo
    }

    param.sections.forEach(s => {
      const section: IDtoSurveyTemplateSection ={
        DbId: s.dbId,
        Caption: s.caption,
        Summary: s.summary,
        Ordinal: s.ordinal,
        Questions: []
      }

      s.questions.forEach(q =>{
        const question: IDtoSurveyTemplateQuestion = {
          AvailableAnswers: [],
          Caption: q.caption,
          DbId: q.dbId,
          Ordinal: q.ordinal,
          Summary: q.summary,
          SystemName: q.systemName
        }

        q.availableAnswers.forEach(a => {
          question.AvailableAnswers.push({
            Caption: a.caption,
            DbId: a.dbId,
            ShowNotApplicable: a.showNotApplicable,
            Summary: a.summary
          })
        })

        section.Questions.push(question);
      });

      res.Sections.push(section);
    })

    return res;
  }

}
